import React from 'react'
import { connect } from 'react-redux'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'
import { updateCompanySettingsReset } from '../../actions/CompanyActions'

class ResetCompanySettings extends React.Component {

  componentDidMount() {
    const { props } = this
    const { dispatch } = props
    setTimeout(() => {
      dispatch(updateCompanySettingsReset())
    },5)
  }

  componentDidUpdate() {
  }

  renderBody = (props,state) => <SiteLayout>
    <HelmetTitle title={'Reset Company Setttings'} />
    <div style={{ backgroundColor: 'white', padding: 10, paddingBottom: 20, borderColor: 'black', borderWidth: 1, borderStyle: 'solid' }} >
      Company settings have been reset
    </div>
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {
    return {}
  }
)(ResetCompanySettings)
