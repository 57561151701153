import React from 'react'
import { Link } from 'react-router-dom'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, SubmitWrapper, FormInnerContentDiv, FormTextParagraph } from './styled-elements'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { RoundedFormButton } from '../../styles/styled-elements'
import queryString from 'query-string'

export default class CompanyTransfershipInvitationForm extends React.Component {

  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      acceptance: "accept",
      invitationAccepted: false,
      invitationDeclined: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.props!==prevProps) {
      const { apiAuth } = this.props
      const prevPropsAuth = prevProps.apiAuth
      const user = apiAuth ? apiAuth.user : null
      if (!user) return true
      const company = user ? user.company : null
      const permissions = (user.permissions && Object.keys(user.permissions).length) ? user.permissions : null
      const invitedPermissions = (user.invited_permissions && Object.keys(user.invited_permissions).length) ? user.invited_permissions : null

      const companyInvitationCreatedAt = permissions ?
        permissions.company_invitation_created_at :
          invitedPermissions ?
          invitedPermissions.company_invitation_created_at :
          null

      const prevPropsUser = prevPropsAuth ? prevPropsAuth.user : null
      const prevInvitedPermissions = prevPropsUser ? prevPropsUser.invited_permissions : {}
      const stateHash = {}

      if (companyInvitationCreatedAt && prevInvitedPermissions) {
        if (permissions && prevInvitedPermissions && permissions.company_invitation_accepted_at && !prevInvitedPermissions.company_invitation_accepted_at) {
          stateHash['invitationAccepted'] = true
        } else if (invitedPermissions && prevInvitedPermissions && (invitedPermissions.company_invitation_declined_at!==prevInvitedPermissions.company_invitation_declined_at)) {
          stateHash['invitationDeclined'] = true
        }
      }

      if (Object.keys(stateHash).length) {
        this.setState(stateHash, () => {
          // console.log("XXX this.state",this.state)
        })
      }
    }
  }

  setAcceptanceValue = (newValue) => {
    this.setState({ acceptance: newValue }, () => {
      // console.log("XXX this.state",this.state)
    })
  }

  validate = (callback) => {
    callback.call(this)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { acceptance } = this.state
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const params = { acceptance }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  getMainContent = (props,state) => {

    const { apiAuth } = props
    const { user } = apiAuth
    const company = user ? user.company : null
    const invitedPermissions = user ? user.invited_permissions : null
    const permissions = user ? user.permissions : null
    const companyInvitationCreatedAt = invitedPermissions ? invitedPermissions.company_invitation_created_at : null
    const invitedPermissionsCompanyNameLabel = invitedPermissions ? ( (invitedPermissions && invitedPermissions.company_name) ? invitedPermissions.company_name : company ? company.name : null ) : null
    //console.log("XXX invitedPermissionsCompanyNameLabel",invitedPermissionsCompanyNameLabel)
    //console.log("company.name",company ? company.name : null)

    if (!company) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You are not logged in.</FormTextParagraph>
        <FormTextParagraph><Link to={'/login'}>Login Page</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (state.invitationAccepted) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have accepted the invitation.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (state.invitationDeclined) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have declined the invitation.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (permissions && permissions.company_invitation_created_at && permissions.company_invitation_accepted_at) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have already accepted an invitation to this company account.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (!companyInvitationCreatedAt) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You do not have a new invitation to join a new company.</FormTextParagraph>
        <FormTextParagraph>You may have already accepted an invitation.</FormTextParagraph>
        <FormTextParagraph>Please check that you are logged into the correct account, and check with the account owner that the invitation has been sent.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    return <FormInnerContentDiv>

      <FormTextParagraph>You have been invited join the COtL Mesonet profile for {invitedPermissionsCompanyNameLabel}.</FormTextParagraph>
      <FormTextParagraph>Please indicate whether you accept the invitation and click Submit.</FormTextParagraph>
      <Form onSubmit={this.handleSubmit}>
        {this.state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <RadioGroup
            aria-labelledby="access-plan-radio-buttons-heading"
            defaultValue=""
            name="acceptance"
            onChange={(event, newValue) => {
              this.setAcceptanceValue(newValue)
            }}
            value={state.acceptance}
            style={{ padding: 10 }}
          >
            <FieldDiv>
              <FormControlLabel value="accept" control={<Radio />} label="Accept Invitation" />
            </FieldDiv>
            <FieldDiv>
              <FormControlLabel value="decline" control={<Radio />} label="Decline Invitation" />
            </FieldDiv>
          </RadioGroup>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled left>Submit</RoundedFormButton>
        </SubmitWrapper>
      </Form>

    </FormInnerContentDiv>

  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { user } = apiAuth
    const company = user ? user.company : null
    const invitedPermissions = user ? user.invited_permissions : null
    const invitedPermissionsCompanyNameLabel = invitedPermissions ? `for ${invitedPermissions.company_name}` : ''
    return <LoginContainer>
      <LoginInner>
        <LoginHeading>Ownership Invitation {invitedPermissionsCompanyNameLabel}</LoginHeading>
        {this.getMainContent(props,state)}
      </LoginInner>
    </LoginContainer>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
