import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import CloseCircle from '../icons/CloseCircle'
import LoadingIndicator from './LoadingIndicator'
import ContainerHeading from '../layout/ContainerHeading'
import HelmetTitle from '../../components/layout/HelmetTitle'
import Moment from 'moment-timezone'
import styledTheme from '../../styles/theme'
import Environment from '../../utils/environment'

import { DataViewModalContainer, ContainerHeadingActionIcon, GraphContentWrapper, GraphContainer } from './styled-elements'

export default class GrowingSeasonGraph extends React.Component {

  constructor(props) {
    super(props)
    const layoutWidth=this.getLayoutWdth()
    const layoutHeight=this.getLayoutHeight()
    const isSmallScreen=(layoutWidth < styledTheme.breakpoints.lg)
    const mobileLandscapeView=(isSmallScreen && (layoutWidth > layoutHeight))
    this.state = {
      layoutWidth,
      layoutHeight,
      isSmallScreen,
      mobileLandscapeView,
    }
    this.graphContainerDiv = React.createRef()
    this.highChartsChart = React.createRef()
  }

  getLayoutWdth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.innerWidth
  }

  getLayoutHeight = () => {
    if (typeof window === 'undefined') return (styledTheme.breakpoints.lg*9/16)
    return window.innerHeight
  }

  formatSeries = (seriesArray) => seriesArray.map(series => {
    if (series.id.match(/^wind_direction/)) {
      return {
        ...series,
        turboThreshold: 0,
        tooltip: {
          pointFormatter: function() {
            if (this.degrees===null) return null
            return `<span style="color:${this.color}">\u25CF </span>${series.name}: <b>${this.cardinal} (${this.degrees}\u00B0)</b><br/>`
          }
        }
      }
    }
    else if (series.id.match(/^ann_.*sigma/)) {
      return {
        ...series,
        tooltip: {
          pointFormatter: function() {
            return `<span style="color:${this.color}">\u25CF </span>${series.name}: <b>${this.series.yAxis.categories[this.y]}</b><br/>`
          },
        }
      }
    }
    return series
  })

  formatYAxis = (yAxesArray) => yAxesArray.map(axis => {
    if (axis.id==="y_axis_km_h") {
      return {
        ...axis,
        labels: {
          formatter: function() {
            if (parseInt(this.value) >= 0) return this.value;
            return null;
          }
        }
      }
    }
    return axis
  })

  processOptions = (props,state) => {
    const mobileDisplayOptions = {}
    const options = props.options
    const ret =  {
      ...options,
      ...mobileDisplayOptions,
      series: this.formatSeries(options.series.slice()),
      yAxis: this.formatYAxis(options.yAxis.slice()),
      time: {
        getTimezoneOffset: function (timestamp) {
          return -Moment.tz(timestamp, props.growingSeason.growing_location_timezone_name).utcOffset()
        }
      }
    }
    return ret
  }

  last3Heights = []
  last3Widths = []

  resizeHandler = (e) => {

    this.last3Heights = []
    this.last3Widths = []

    const resizeFunction = () => {
      if (e.target.innerWidth===this.state.layoutWidth) return
      const layoutWidth=this.getLayoutWdth()
      const layoutHeight=this.getLayoutHeight()
      const isSmallScreen=(layoutWidth < styledTheme.breakpoints.lg)
      const mobileLandscapeView=(isSmallScreen && (layoutWidth > layoutHeight))
      this.setState({
        chartContainerHeight: this.graphContainerDiv.current.offsetHeight,
        chartContainerWidth: this.graphContainerDiv.current.offsetWidth,
        layoutWidth,
        layoutHeight,
        isSmallScreen,
        mobileLandscapeView,
      })
    }

    // To handle animated screen re-sizing and delayed updating of window.innerWidth in Mobile Chrome:
    // Run this function 3 times, and only when the height and width have been stable for 3 seconds,
    // stop calling resizeFunction

    const intervalId = setInterval(() => {
      this.last3Widths.push(e.target.innerWidth)
      this.last3Heights.push(e.target.innerHeight)
      this.last3Widths = this.last3Widths.slice(-3)
      this.last3Heights = this.last3Heights.slice(-3)
      if ((this.last3Widths.length < 3) || (this.last3Heights.length < 3)) {
        resizeFunction()
      } else {
        const widthTotal = this.last3Widths.reduce((n,total) => total+n,0)
        const heightTotal = this.last3Heights.reduce((n,total) => total+n,0)
        if (((widthTotal/3)===this.last3Widths[2]) && ((heightTotal/3)===this.last3Heights[2])) {
          clearInterval(intervalId)
        } else {
          resizeFunction()
        }
      }
    },500)
  }

  addDataPoint = (seriesId,dataPoint) => {
    const chart = this.highChartsChart.current.chart
    const series = chart.get(seriesId)
    series.addPoint(dataPoint)
    const xAxis = series.xAxis
    const yAxis = series.yAxis
    xAxis.setExtremes(Math.min(xAxis.min,dataPoint[0]),Math.max(xAxis.max,dataPoint[0]))
    yAxis.setExtremes(Math.min(yAxis.min,dataPoint[1]),Math.max(yAxis.max,dataPoint[1]))
  }

  componentDidMount = () => {
    if (this.highChartsChart.current) {
      const container = this.highChartsChart.current.container.current;
      container.style.height = "100%";
      container.style.width = "100%";
      this.highChartsChart.current.chart.reflow();
      this.updateChartContainerDimensions()
    }
    this.removeHighchartsCredit.call(this)
    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate = () => {
    this.updateChartContainerDimensions()
    if (this.highChartsChart.current && this.highChartsChart.current.chart) {
      this.highChartsChart.current.chart.setSize(
        this.state.chartContainerWidth,
        this.state.chartContainerHeight,
      )
      this.highChartsChart.current.chart.reflow()
    }
    this.removeHighchartsCredit.call(this)
  }

  updateChartContainerDimensions() {
    if (
      (this.graphContainerDiv) &&
      (this.graphContainerDiv.current) &&
      (
        (this.state.chartContainerHeight!==this.graphContainerDiv.current.offsetHeight) ||
        (this.state.chartContainerWidth!==this.graphContainerDiv.current.offsetWidth)
      )
    ) {
        this.setState({
          chartContainerHeight: this.graphContainerDiv.current.offsetHeight,
          chartContainerWidth: this.graphContainerDiv.current.offsetWidth,
        })
    }
  }

  removeHighchartsCredit = () => {
    const highchartsCreditElArray = document.querySelectorAll('.highcharts-credits')
    if (highchartsCreditElArray.length) {
      const highchartsCredit = highchartsCreditElArray[0]
      highchartsCredit.parentNode.removeChild(highchartsCredit)
    }
  }

  renderBody = (props,state) => {

    const { growingSeason, dataView, options, fetching } = props
    const title = growingSeason.name && `${growingSeason.name}: ${dataView.name}`

    const ContainerHeadingBlock = <ContainerHeading
      title={title}
      actionIcons={
        <ContainerHeadingActionIcon onClick={this.props.closeDataViewHandler}><CloseCircle /></ContainerHeadingActionIcon>
      }
    />

    if (!Object.keys(options).length || !options.chart) {
      return <DataViewModalContainer>
        <LoadingIndicator show={fetching} />
        <HelmetTitle title={title} />
        {ContainerHeadingBlock}
      </DataViewModalContainer>
    }

    const processedOptions = () => {
      if (props.options.type==='simple_column_chart') {
        return this.processSimpleColumnChartOptions(props,state)
      } else {
        return this.processOptions(props,state)
      }
    }

    const constructorType = options.constructorType || 'stockChart'

    const optionsProcessed = processedOptions()

    const HighchartsObj = <GraphContentWrapper>
        <GraphContainer ref={this.graphContainerDiv}>
          <HighchartsReact
            ref={this.highChartsChart}
            highcharts={Highcharts}
            constructorType={constructorType}
            options={options}
            allowChartUpdate={false}
          />
        </GraphContainer>
      </GraphContentWrapper>

    return <DataViewModalContainer>
      <LoadingIndicator show={fetching} />
      <HelmetTitle title={title} />
      {ContainerHeadingBlock}
      {HighchartsObj}
    </DataViewModalContainer>
  }

  render = () => this.renderBody(this.props,this.state)

}

