import React from 'react'
import { connect } from 'react-redux'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'
import { setPreviewMode } from '../../actions/AuthActions'

class UnsetPreviewMode extends React.Component {

  componentDidMount() {
    const { props } = this
    const { dispatch } = props
    dispatch(setPreviewMode())
    localStorage.setItem('previewMode', true)
  }

  componentDidUpdate() {
  }

  renderBody = (props,state) => <SiteLayout>
    <HelmetTitle title={'Set Preview Mode'} />
    <div style={{ backgroundColor: 'white', padding: 10, paddingBottom: 20, borderColor: 'black', borderWidth: 1, borderStyle: 'solid' }} >
      Preview mode set
    </div>
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {}
)(UnsetPreviewMode)
