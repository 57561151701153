import axios from 'axios'
import { dataToFormData } from '../utils/apiUtils'

export function updatePaymentMethod(paramsObj) {
  const sendParams = { payment_details: { ...paramsObj } }
  sendParams['update_type'] = 'billing_payment_method'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`,sendParams),
    })
  }
}

export function updateCompanyDetails(formValues) {
  const formData = { company_details: { ...formValues } }
  formData['update_type'] = 'company_details'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function updateBillingProducts(formValues) {
  const formData = { company_details: { ...formValues } }
  formData['update_type'] = 'billing_products'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function updateUserSurvey(formValues) {
  const formData = { user_survey_response: { ...formValues } }
  formData['update_type'] = 'user_survey'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function updateBillingDiscountCodeUse(formValues) {
  const formData = { billing_discount_code_use_details: { ...formValues } }
  formData['update_type'] = 'billing_discount_code_use'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function updateCompanySettingsReset(formValues) {
  const formData = { }
  formData['update_type'] = 'company_settings_reset'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function sendNewUserInvitation(formValues) {
  const formData = { invite_user_to_company_details: { ...formValues } }
  formData['update_type'] = 'invite_user_to_company'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function updateUserPermissions(formValues) {
  const formData = { user_permissions: { ...formValues } }
  formData['update_type'] = 'update_user_permissions'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function inviteUserToBeOwner(formValues) {
  const formData = { invite_user_to_be_owner_details: { ...formValues } }
  formData['update_type'] = 'invite_user_to_be_owner'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}

export function removeUser(formValues) {
  const formData = { remove_user_details: { ...formValues } }
  formData['update_type'] = 'remove_user'
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPANY_UPDATE',
      payload: axios.put(`/members/company_settings`, formData),
    })
  }
}
