import axios from 'axios'

export function setDataViewSummary(dataViewSummary) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_SUMMARY_SET',
      meta: {
        dataViewSummary: dataViewSummary
      }
    })
  }
}
export function fetchDataViewHash(siteParam,dataViewParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_FETCH',
      payload: axios(`/members/sites/${siteParam}/data_views/${dataViewParam}/data_view_hash`)
    })
  }
}
export function fetchMemberGrowingSeasonDataViewHash(growingSeasonParam,dataViewParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_FETCH',
      payload: axios(`/members/growing_seasons/${growingSeasonParam}/data_views/${dataViewParam}/data_view_hash`)
    })
  }
}
export function clearDataViewHash() {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_CLEAR',
      meta: {
        data: null
      }
    })
  }
}
