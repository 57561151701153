import React from 'react'
import { connect } from 'react-redux'

class SaveLastUrl extends React.Component {

  componentDidMount = () => {
    const { host, href, pathname } = window.location
    window.thisLocation = { host, href, pathname }
  }

  componentDidUpdate = () => {
    window.lastLocation = { ...window.thisLocation }
    const { host, href, pathname } = window.location
    window.thisLocation = { host, href, pathname }
  }

  render = () => null

}

export default connect(
  (store, props) => { return {
    router: store.router,
  }}
)(SaveLastUrl)

