import React from 'react'
import { connect } from 'react-redux'
import { submitCompanyTransferInvitationResponse } from '../../actions/AuthActions'
import CompanyTransferInvitationForm from '../../components/auth/CompanyTransferInvitationForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'

class CompanyTransferInvitation extends React.Component {

  handleSubmit = ({ acceptance }) => {

    const { apiAuth } = this.props
    const user = apiAuth ? apiAuth.user : null
    if (!user) return true
    const invitedPermissions = user.invited_permissions

    this.props.dispatch(submitCompanyTransferInvitationResponse({
      acceptance: acceptance,
      company_slug: invitedPermissions.company_slug,
    }))
  }

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Invitation To Join New Company'} />
    <CompanyTransferInvitationForm
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
      submitHandler={this.handleSubmit}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
  }),
)(CompanyTransferInvitation)
