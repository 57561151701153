import React from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton } from '../../../styles/styled-elements'

const FormElement = ({ formInputChangeHandler, setSelectedStateValue, handleSubmit, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const classes = useStyles()

  return <FormContainer>
    <FormInner>
      <Form onSubmit={handleSubmit}>
        {state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Email"
              name="email"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'email',
              }}
              value={state.email || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.name}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.name}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Phone Number"
              name="phone"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'phone',
              }}
              value={state.phone || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.phone}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.phone}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Update</RoundedFormButton>
        </SubmitWrapper>
      </Form>
    </FormInner>
  </FormContainer>
}

export default class UserDetailsForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      uid: props.uid,
      email: props.email,
      phone: props.phone,
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }


  componentDidMount(prevProps) {
    this.populateForm()
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const {uid, email, phone, origValues } = this.state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      if (email!==props.email && email!==origValues.email) hasChanged = true
      if (phone!==props.phone && phone!==origValues.phone) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if (
          email &&
          phone
      ) {
        canSubmit = true
      }
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props!==prevProps) {
      if (
          (props.email!==prevProps.email) ||
          (props.phone!==prevProps.phone)
      ) {

        stateHash['phone'] = props.phone
        stateHash['email'] = props.email
        const origValuesHash = { ...stateHash }
        stateHash['origValues'] = origValuesHash
        stateHash['errors'] = props.errors
        stateHash['hasChanged'] = false
        stateHash['canSubmit'] = false
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  populateForm = () => {
    const { props } = this
    const stateHash = {}

    stateHash['email'] = props.email
    stateHash['phone'] = props.phone
    const origValuesHash = { ...stateHash }
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['hasChanged'] = false
    stateHash['canSubmit'] = false
    this.setState(stateHash)
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  validate = (callback) => {
    const errors = {}
    const { email, phone} = this.state

    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { uid, email, phone } = this.state
    const params = { uid,email, phone}
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value })
  }

  renderBody = (props,state) => <FormElement
    formInputChangeHandler={this.formInputChangeHandler}
    setSelectedStateValue={this.setSelectedStateValue}
    handleSubmit={this.handleSubmit}
    state={state}
  />

  render() {
    return this.renderBody(this.props,this.state)
  }
}
