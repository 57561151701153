import React from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Checkbox } from '@material-ui/core'
import { ModalScreenOverlay, OptionsPanelContainer, OptionsPanelCloseIcon } from './styled-elements'

export default class OptionsPanel extends React.Component {

  constructor(props) {
    super(props)
  }

  callCloseActionIfClickOutsidePopup = (event,closeOptionsPanelHandler) => {
    if (event.target === event.currentTarget) closeOptionsPanelHandler.call()
  }

  renderBody = (props,state) => {

    const {
      closeOptionsPanelHandler, mapShowAllAwsNames, mapShowAllAwsNamesChangeHandler,
      mapShowLclbStations, mapShowLclbStationsChangeHandler
    } = props

    return <ModalScreenOverlay onClick={e => this.callCloseActionIfClickOutsidePopup(e,closeOptionsPanelHandler)}>
      <OptionsPanelContainer >
        <OptionsPanelCloseIcon onClick={closeOptionsPanelHandler} />
        <FormControl component="fieldset">
          <FormLabel component="legend">Map Style</FormLabel>
          <RadioGroup aria-label="map-style" name="map-style-1" value={props.mapTypeId} onChange={props.mapTypeIdChangHandler}>
            <FormControlLabel value="roadmap" control={<Radio  color="primary" />} label="Roadmap" />
            <FormControlLabel value="satellite" control={<Radio color="primary" />} label="Satellite" />
            <FormControlLabel value="terrain" control={<Radio  color="primary" />} label="Terrain" />
          </RadioGroup>
          <div style={{height: '20px'}} />
          <FormLabel component="legend">General</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapShowAllAwsNames}
                onChange={mapShowAllAwsNamesChangeHandler}
                name="map-show-all-aws-names"
                color="primary"
              />
            }
            label="Show AWS Names"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mapShowLclbStations}
                onChange={mapShowLclbStationsChangeHandler}
                name="map-show-lclb-stations"
                color="primary"
              />
            }
            label="Show LCLB Stations"
          />
        </FormControl>
     </OptionsPanelContainer>
    </ModalScreenOverlay>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
