import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { fetchGrowingLocations } from '../../../actions/GrowingLocationActions'
import { fetchGrowingSeasons } from '../../../actions/GrowingSeasonActions'
import { fetchMemberGrowingSeasonDataViewHash, clearDataViewHash }  from '../../../actions/DataViewActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import GrowingSeason from '../../../components/my/growingSeasons/GrowingSeason'

class MyGrowingSeason extends React.Component {

  componentDidMount = () => {
    const { dispatch, match } = this.props
    dispatch(fetchGrowingSeasons())
    dispatch(fetchGrowingLocations())

    const growingSeasonSlug = match.params.growing_season_slug
    const dataViewSlug = match.params.data_view_slug

    if (dataViewSlug) {
      dispatch(fetchMemberGrowingSeasonDataViewHash(growingSeasonSlug,dataViewSlug))
    }

  }

  componentDidUpdate = (prevProps,prevState) => {

    const { dispatch, match } = this.props
    const growingSeasonSlug = match.params.growing_season_slug
    const dataViewSlug = match.params.data_view_slug
    const prevDataViewSlug = prevProps.match.params.data_view_slug
    if (dataViewSlug && (dataViewSlug!==prevDataViewSlug)) {
      dispatch(fetchMemberGrowingSeasonDataViewHash(growingSeasonSlug,dataViewSlug))
    }

  }

  routeToDataView = (growingSeasonSlug,dataViewSlug) => {
    this.props.dispatch(push(`/my/growing_seasons/${growingSeasonSlug}/data_views/${dataViewSlug}`))
  }

  getLoadingScreenState() {
    return false
  }

  closeDataViewHandler = () => {
    if (window.lastLocation && (window.lastLocation.host===window.location.host)) {
      this.props.history.goBack()
    } else {
      const { dispatch, match } = this.props
      const growingSeasonSlug = match.params.growing_season_slug
      dispatch(push(`/my/growing_seasons/${growingSeasonSlug}`))
    }
    this.props.dispatch(clearDataViewHash())
  }

  renderBody = (props,state) => {
    return <div>
      <LoadingScreen show={this.getLoadingScreenState()}/>
      <HelmetTitle title={`My Growing Season - ${props.growingSeason.name}`} />
      <GrowingSeason
        growingSeason={props.growingSeason}
        growingLocations={props.growingLocations}
        dataViews={props.dataViews}
        dataView={props.dataView}
        dataViewHash={props.dataViewHash}
        rowClickHandler={this.routeToDataView}
        closeDataViewHandler={this.closeDataViewHandler}
      />
    </div>
  }

  render = () => <SiteLayout>
    { this.renderBody(this.props,this.state) }
  </SiteLayout>

}

export default connect(
  (store, props) => {
    const growingSeason = store.growingSeasons.list.find(({slug}) => slug===props.match.params.growing_season_slug)
    const dataViews = (growingSeason && growingSeason.data_views) || []
    const dataView = dataViews && dataViews.find(({slug}) => slug===props.match.params.data_view_slug)
    const dataViewHash = store.dataViews.dataViewHash
    return {
      apiAuth: store.apiAuth,
      sites: store.sites,
      router: store.router,
      growingSeasons: store.growingSeasons,
      growingLocations: store.growingLocations,
      growingSeason: growingSeason,
      dataViews: dataViews,
      dataView: dataView,
      dataViewHash: dataViewHash,
    }
  },
)(MyGrowingSeason)
