import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fetchPublicSites, fetchMemberSites } from '../../actions/SiteActions'
import { setDataViewSummary, fetchDataViewHash, clearDataViewHash } from '../../actions/DataViewActions'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'
import ContainerHeading from '../../components/layout/ContainerHeading'
import LoadingScreen from '../../components/LoadingScreen'
import Dashboard from '../../components/dataViews/Dashboard'
import Graph from '../../components/dataViews/Graph'
import DataTable from '../../components/dataViews/DataTable'
import ArrowRightBoldCircle from '../../components/icons/ArrowRightBoldCircle'
import DashboardIcon from '../../components/icons/DashboardIcon'
import ChartLine from '../../components/icons/ChartLine'
import Table from '../../components/icons/Table'
import UpgradeRequiredModal from '../../components/UpgradeRequiredModal'
import { H1, GridContainer, GridSection, Column, ListRow, ColumnStyle, ColumnResponsiveInnerContainer, DataViewIconContainer, ActionColumnStyleRightAligned, ActionIconContainer, GraphDurationIndicatorSpan } from './styled-elements'
import { isSiteEligibleForAllTimeGraph } from '../../utils/userUtils'

const GraphDurationIndicator = ({ dataView, user, site }) => {

  if (dataView.data_view_type_category!=='graph') return null

  const siteEligibleForAllTimeGraph = isSiteEligibleForAllTimeGraph(user,site)

  if (['Rainfall Totals - Yearly'].includes(dataView.name)) {
    if (siteEligibleForAllTimeGraph) {
      return <GraphDurationIndicatorSpan allHistory>All Time Graph</GraphDurationIndicatorSpan>
    } else {
      return <GraphDurationIndicatorSpan>3-Year Graph</GraphDurationIndicatorSpan>
    }
  }

  if (['Rainfall Totals - Monthly'].includes(dataView.name)) {
    if (siteEligibleForAllTimeGraph) {
      return <GraphDurationIndicatorSpan allHistory>All Time Graph</GraphDurationIndicatorSpan>
    } else {
      return <GraphDurationIndicatorSpan>3-Month Graph</GraphDurationIndicatorSpan>
    }
  }

  if (['Rainfall Totals - Daily','Evapotranspiration'].includes(dataView.name)) {
    if (siteEligibleForAllTimeGraph) {
      return <GraphDurationIndicatorSpan allHistory>All Time Graph</GraphDurationIndicatorSpan>
    } else {
      return <GraphDurationIndicatorSpan>7-Day Graph</GraphDurationIndicatorSpan>
    }
  }

  if (!siteEligibleForAllTimeGraph) {
    return <GraphDurationIndicatorSpan>48-Hour Graph</GraphDurationIndicatorSpan>
  }

  if (siteEligibleForAllTimeGraph) {
    return <GraphDurationIndicatorSpan allHistory>All Time Graph</GraphDurationIndicatorSpan>
  }

}


class Site extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      graphOptions: {},
      showUpgradeRequiredModal: false,
    }
    this.graphComponent = React.createRef()
  }

  componentDidMount() {
    const { dispatch, match, isSignedIn } = this.props
    if (isSignedIn) {
      dispatch(fetchMemberSites())
      const siteSlug = match.params.site_slug
      const dataViewSlug = match.params.data_view_slug
      if (dataViewSlug) {
        dispatch(fetchDataViewHash(siteSlug,dataViewSlug))
      }
    } else {
      dispatch(fetchPublicSites())
    }
  }

  componentDidUpdate(prevProps,prevState,snapshot) {
    const { dispatch, match, isSignedIn } = this.props

    if (isSignedIn) {
      const siteSlug = match.params.site_slug
      const dataViewSlug = match.params.data_view_slug
      const prevDataViewSlug = prevProps.match.params.data_view_slug
      if (dataViewSlug && (dataViewSlug!==prevDataViewSlug)) dispatch(fetchDataViewHash(siteSlug,dataViewSlug))

      const prevSite = prevProps.site
      const currentSite = this.props.site
      const currentDataViews = this.props.dataViews
      if ((!prevSite || !Object.keys(prevSite).length) && (currentSite && Object.keys(currentSite).length && currentDataViews)) {
        const dataViewSummary = currentSite.data_views.find(o => o.slug===dataViewSlug)
        dispatch(setDataViewSummary(dataViewSummary))
      }
    }

  }

  routeToView = (siteSlug,dataViewSlug) => {
    this.props.dispatch(push(`/sites/${siteSlug}/data_views/${dataViewSlug}`))
  }

  showUpgradeRequiredModal = () => {
    this.setState({ showUpgradeRequiredModal: true })
  }

  closeUpgradeRequiredModal = () => {
    this.setState({ showUpgradeRequiredModal: false })
  }

  getDataViewIcon = (dv) => {
    return <DataViewIconContainer>
      {(dv.data_view_type_category==='dashboard') && <DashboardIcon />}
      {(dv.data_view_type_category==='graph') && <ChartLine />}
      {(dv.data_view_type_category==='data_table') && <Table />}
    </DataViewIconContainer>
  }

  renderActiveDataView = (dv,index,siteSlug) => <ListRow divisions={12} key={index} onClick={this.routeToView.bind(this,siteSlug,dv.slug)}>
    <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>{this.getDataViewIcon(dv)} {dv.name} {<GraphDurationIndicator dataView={dv} user={this.props.user} site={this.props.site}/>}</ColumnResponsiveInnerContainer></Column>
    <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer onClick={this.routeToView.bind(this,dv.slug)}><ArrowRightBoldCircle right /></ActionIconContainer></Column>
  </ListRow>


  renderInactiveDataView = (dv,index,siteSlug) => <ListRow divisions={12} key={index} inactive={1}>
    <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer inactive={1}>{this.getDataViewIcon(dv)} {dv.name} {<GraphDurationIndicator dataView={dv} user={this.props.user} site={this.props.site}/>}</ColumnResponsiveInnerContainer></Column>
    <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer inactive={1}></ActionIconContainer></Column>
  </ListRow>

  renderDataViewsRows(dataViews) {
    const siteSlug = this.props.site.slug
    return dataViews.sort((a,b) => a.sort_order-b.sort_order).map((dv,index) => {
      if (typeof dv.available === 'undefined' || dv.available===true) {
        return this.renderActiveDataView(dv,index,siteSlug)
      } else {
        return this.renderInactiveDataView(dv,index,siteSlug)
      }
    })
  }

  renderSiteDataViews(site,dataViews) {
    if (dataViews) {
      const BackLinkDiv = styled.div`
        display: flex;
        font-size: 14px;
        margin: 10px 0 30px 0;
      `
      return <div>
        <BackLinkDiv>
          <Link to={`/`}>&#171; Back to Sites</Link>
        </BackLinkDiv>
        <H1>{site.name}</H1>
        <GridContainer>
          <ContainerHeading title={`View Data`} />
          <GridSection>
            {this.renderDataViewsRows(dataViews)}
          </GridSection>
        </GridContainer>
      </div>
    }
  }

  renderShowDataView(props,state) {
    const site = props.site
    const dataView = props.dataView
    const { dispatch } = props
    if (!site || !site.id) return null
    if (dataView) {
      switch (dataView.data_view_type_category) {
        case 'dashboard':
          if (dataView.data_view_type_key==="dashboard") return <Dashboard
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            data={props.dataViewHash}
            awsType={site.aws_type}
            billingItemAccessPlan={props.billingItemAccessPlan}
            routeToDataViewHandler={this.routeToView}
            showUpgradeRequiredModalHandler={this.showUpgradeRequiredModal}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
          break
        case 'graph':
          const options = props.dataViewHash || {}
          const windDirectionSeries = props.windDirectionSeries || {}
          return <Graph
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            options={options}
            billingItemAccessPlan={props.billingItemAccessPlan}
            windDirectionSeries={windDirectionSeries}
            dataViewSummary={props.dataViews}
            ref={this.graphComponent}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
        case 'data_table':
          if (dataView.data_view_type_key==="table_all_sensors") return <DataTable closeDataViewHandler={this.dataViewCloseDataViewHandler} dispatch={dispatch} site={site} dataView={dataView} data={props.dataViewHash} />
          break
        default:
          return null
      }
    }
  }

  getLoadingScreenState() {
    return this.props.fetching
  }

  dataViewCloseDataViewHandler = () => {
    if (window.lastLocation && window.lastLocation.host && (window.lastLocation.host===window.location.host)) {
      this.props.history.goBack()
    } else {
      const siteSlug = this.props.site.slug
      this.props.dispatch(push(`/sites/${siteSlug}`))
    }
    this.props.dispatch(clearDataViewHash())
  }

  renderBody = (props,state) => {
    const { isSignedIn } = props
    if (!isSignedIn) {
      return <div>
        <div style={{ fontSize: '1.6em', fontWeight: 700 }}>Log in to view sites</div>
        <p style={{ fontSize: '1.2em', fontWeight: 500 }}>To view more data from this AWS, <Link to={'/login'}>Log in</Link> or <Link to={'/signup'}>Sign Up</Link>.</p>
      </div>
    }
    return <SiteLayout>
      <HelmetTitle title={`${props.site && props.site.name} – Data Views`} />
      <div>
        <LoadingScreen show={this.getLoadingScreenState()}/>
        { props.site && this.renderSiteDataViews(props.site,props.dataViews) }
        { props.match.params.data_view_slug && props.dataView && this.renderShowDataView(props,state) }
      </div>
      <UpgradeRequiredModal
        show={state.showUpgradeRequiredModal}
        overlayClickHandler={this.closeUpgradeRequiredModal}
        closeBtnClickHandler={this.closeUpgradeRequiredModal}
      />
    </SiteLayout>
  }

  render = () => this.renderBody(this.props,this.state)
}

export default connect(
  (store, props) => {
    const isSignedIn = store.apiAuth.isSignedIn
    const site = store.sites.sites.find(({slug}) => slug===props.match.params.site_slug)
    const excludeDataViewTypes = ["site_summary"]
    const dataViews = (site && site.data_views && site.data_views.filter(({data_view_type_category,data_view_type_key}) => !excludeDataViewTypes.includes(data_view_type_key))) || []
    const dataView = dataViews && dataViews.find(({slug}) => slug===props.match.params.data_view_slug)
    const dataViewHash = store.dataViews.dataViewHash
    const windDirectionSeries = dataViewHash && dataViewHash.series && dataViewHash.series.slice().filter(({id}) => id.match(/^wind_direction/))
    const sitesFetching = store.sites.fetching
    const dataViewsFetching = store.dataViews.fetching
    const dataViewHashFetching = store.dataViews.dataViewHashFetching
    const dataViewHashFetched = store.dataViews.dataViewHashFetched
    const billingItemAccessPlan = (store.apiAuth.user && store.apiAuth.user.company) ? store.apiAuth.user.company.billing_item_access_plan : null
    return ({
      isSignedIn: isSignedIn,
      sites: store.sites.sites,
      site: site,
      dataViews: dataViews,
      dataView: dataView,
      dataViewHash: dataViewHash,
      windDirectionSeries: windDirectionSeries,
      sitesFetching: sitesFetching,
      dataViewsFetching: dataViewsFetching,
      dataViewHashFetching: dataViewHashFetching,
      dataViewHashFetched: dataViewHashFetched,
      user: store.apiAuth?.user,
      billingItemAccessPlan,
      router: store.router,
    })
  },
)(Site)
