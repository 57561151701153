import Cookies from 'js-cookie'

const initialState = {
  user: JSON.parse(localStorage.getItem('redux_sync_AuthUser')),
  loading: false,
  isSignedIn: !!Cookies.get('access-token'),
  previewMode: !!localStorage.getItem('previewMode'),
  validating: false,
  validated: false,
  errors: null,
  resetPasswordSent: false,
  resetPasswordSending: false,
  newPasswordSent: false,
  newPasswordSending: false,
  newPasswordRejected: false
}

let user
let errors
let stateHash

export default function (state = initialState, action) {

  switch (action.type) {

    case 'USER_SIGN_IN_PENDING':
      return {
        ...state,
        errors: null,
        loading: true,
      }

    case 'USER_SIGN_IN_REJECTED':
      return {
        ...state,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
        loading: false,
      }

    case 'USER_SIGN_IN_FULFILLED':
      user = action.payload.data.data
      if (user) {
        localStorage.setItem('redux_sync_AuthUser',JSON.stringify(user))
      }
      return {
        ...state,
        user: user,
        errors: null,
        loading: false,
        isSignedIn: true,
      }

    case 'USER_SIGN_UP_PENDING':
      return {
        ...state,
        errors: null,
        loading: true,
      }

    case 'USER_SIGN_UP_FULFILLED':
      return {
        ...state,
        errors: null,
        loading: false,
      }

    case 'USER_SIGN_UP_REJECTED':
      errors = []
      if (action.payload.response.data.errors) {
        if (action.payload.response.data.errors.company_name) {
          errors.push({ field_name: 'company_name', messages: action.payload.response.data.errors.company_name })
        }
        if (action.payload.response.data.errors.email) {
          errors.push({ field_name: 'email', messages: action.payload.response.data.errors.email })
        }
        if (action.payload.response.data.errors.phone) {
          errors.push({ field_name: 'phone', messages: action.payload.response.data.errors.phone })
        }
      }
      return {
        ...state,
        errors: errors,
        loading: false,
      }

    case 'USER_RESET_PASSWORD_PENDING':
      return {
        ...state,
        resetPasswordSent: false,
        resetPasswordSending: true,
      }

    case 'USER_RESET_PASSWORD_FULFILLED':
      return {
        ...state,
        resetPasswordSending: false,
        resetPasswordSent: true,
      }

    case 'USER_RESET_PASSWORD_REJECTED':
      return {
        ...state,
        resetPasswordSending: false,
      }

    case 'USER_NEW_PASSWORD_PENDING':
      return {
        ...state,
        newPasswordSent: false,
        newPasswordSending: true,
        newPasswordRejected: false,
      }

    case 'USER_NEW_PASSWORD_FULFILLED':
      return {
        ...state,
        newPasswordSending: false,
        newPasswordSent: true,
        newPasswordRejected: false,
      }

    case 'USER_NEW_PASSWORD_REJECTED':
      return {
        ...state,
        newPasswordSending: false,
        newPasswordSent: true,
        newPasswordRejected: true,
      }

    case 'COMPANY_UPDATE_PENDING':
      return {
        ...state,
        errors: null,
        loading: true,
      }

    case 'COMPANY_UPDATE_FULFILLED':
      user = action.payload.data.data
      if (user) {
        localStorage.setItem('redux_sync_AuthUser',JSON.stringify(user))
      }
      return {
        ...state,
        user: user,
        errors: null,
        loading: false,
        isSignedIn: true,
      }

    case 'COMPANY_UPDATE_REJECTED':
      errors = []
      if (action.payload.response.data.errors) {
        if (action.payload.response.data.errors.payment) {
          errors.push({ field_name: 'payment', messages: action.payload.response.data.errors.payment, decline_code: action.payload.response.data.errors.decline_code })
        }
      }
      return {
        ...state,
        errors: errors,
        loading: false,
      }

    case 'SIGN_OUT':
      localStorage.removeItem('redux_sync_AuthUser')
      return {
        ...state,
        user: null,
        isSignedIn: false,
        loading: true,
      }

    case 'SIGN_OUT_COMPLETE':
      return {
        ...state,
      }

    case  'USER_SIGN_OUT_PENDING':
      localStorage.removeItem('redux_sync_AuthUser')
      localStorage.removeItem('savedMapZoom')
      localStorage.removeItem('savedMapCenter')
      localStorage.removeItem('mapZoom')
      localStorage.removeItem('mapCenter')
      return {
        ...initialState,
        user: null,
        isSignedIn: false,
      }

    case 'USER_SIGN_OUT_REJECTED':
    case 'USER_SIGN_OUT_FULFILLED':
      return {
        ...initialState,
        user: null,
        isSignedIn: false,
      }

    case 'VALIDATE_TOKEN_PENDING':
      return {
        ...state,
        validating: true
      }

    case 'VALIDATE_TOKEN_REJECTED':
      return {
        ...state,
        validating: false,
        validated: false,
        isSignedIn: false,
        user: false,
      }

    case 'VALIDATE_TOKEN_FULFILLED':
      stateHash = {
        ...state,
        validating: false,
        validated: true,
        isSignedIn: true,
      }
      user = action.payload.data.data
      if (user) {
        localStorage.setItem('redux_sync_AuthUser',JSON.stringify(user))
        stateHash['user'] = user
      }
      return stateHash

    case 'USER_UPDATE_PENDING':
      return {
        ...state,
        updating: true,
        loading: true,
      }

    case 'USER_UPDATE_REJECTED':
      errors = []
      if (action.payload.response.data.errors) {
        if (action.payload.response.data.errors.company_name) {
          errors.push({ field_name: 'company_name', messages: action.payload.response.data.errors.company_name })
        }
      }
      return {
        ...state,
        errors: errors,
        updating: false,
        loading: false,
      }

    case 'USER_UPDATE_FULFILLED':
      stateHash = {
        ...state,
        updating: false,
        loading: false,
      }
      user = action.payload.data.data
      if (user) {
        localStorage.setItem('redux_sync_AuthUser',JSON.stringify(user))
        stateHash['user'] = user
      }
      return stateHash

    case 'SET_PREVIEW_MODE_ON':
      return {
        ...state,
        previewMode: true,
      }

    case 'SET_PREVIEW_MODE_OFF':
      return {
        ...state,
        previewMode: false,
      }

    default:
      return state

  }
}
