import React from 'react'

import { ColorBarContainer, ColorBarNamesColumn, ColorBarColorsColumn, ColorBarNameCell, ColorBarColorCell, ColorBarColorCellInner } from './styled-elements'

export default class SigmaWLegend extends React.Component {
    renderBody = ({ metricDisplayStatuses }) => <ColorBarContainer>
        <ColorBarNamesColumn>
            {metricDisplayStatuses.map(({label},index) => <ColorBarNameCell key={index}>{label}</ColorBarNameCell>)}
        </ColorBarNamesColumn>
        <ColorBarColorsColumn>
            {metricDisplayStatuses.map(({background_color},index) => <ColorBarColorCell key={index}><ColorBarColorCellInner bgColor={background_color}/></ColorBarColorCell>)}
        </ColorBarColorsColumn>
    </ColorBarContainer>

    render() {
        return this.renderBody(this.props)
    }
}
