import React from 'react'

const HexagonMarker = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 184.751 184.751">
    <g fill="none">
      <g className="map-marker-shape">
        <path d="M0,92.375l46.188-80h92.378l46.185,80l-46.185,80H46.188L0,92.375z"/>
      </g>
    </g>
  </svg>
)

export default HexagonMarker
