export function companyHasHomeLocation(company) {
  if (!company) return false;
  if (!company.billing_product_feature_set_key) return true;

  return ['farm_bundle','premium_bundle','all_access','admin'].includes(company.billing_product_feature_set_key);
}

export function companyIsAdmin(company) {
  if (!company) return false;
  if (!company.billing_product_feature_set_key) return false;

  return ['admin'].includes(company.billing_product_feature_set_key);
}

export function companyHasMenu(company) {
  if (!company) return false;
  if (!company.billing_product_feature_set_key) return true;

  return ['farm_bundle','premium_bundle','all_access','admin'].includes(company.billing_product_feature_set_key);
}

export function companyHasGrowingSeasons(company) {
  if (!company) return false;
  if (!company.billing_product_feature_set_key) return true;

  return ['premium_bundle','all_access','admin'].includes(company.billing_product_feature_set_key);
}

export function companyBillingRequired(company) {
  if (!company) return false;
  if (!company.billing_item_access_plan) return true;

  return !['all_access_1', 'admin_1'].includes(company.billing_item_access_plan.billing_product_key)
}
