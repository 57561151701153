import axios from 'axios'

export function fetchGrowingLocations() {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_LOCATIONS_FETCH',
      payload: axios(`/members/growing_locations`)
    })
  }
}

export function updateGrowingLocation(id,updateParams) {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_LOCATION_UPDATE',
      payload: axios.put(`/members/growing_locations/${id}`,updateParams)
    })
  }
}
