import React from 'react'
import { Link } from 'react-router-dom'
import { ModalScreenOverlay } from '../styles/styled-elements'

const UpgradeRequiredModalBoxContainer  = ({ visible, closeBtnClickHandler }) => {

  if (!visible) return null

  const styles = {
    outer: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#666',
      borderRadius: 3,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      border: '2px solid black'
    },
    heading: {
      fontSize: '16px',
      marginBottom: '6px',
      fontWeight: '700',
    },
    content: {
      display: 'inline-flex',
      fontSize: '14px',
    },
    buttonsContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
    },
    button: {
      border: '2px solid #2F6BE1',
      borderRadius: '3px',
      padding: '4px 8px',
      fontSize: '12px',
      color: '#2F6BE1',
      marginLeft: '10px',
      cursor: 'pointer',
    },
  }

  const ret = <div style={{ width: 320, zIndex: 200, bottom: '10px', left: '10px' }}>
    <div style={styles.outer}>
      <div style={styles.heading}>Upgrade Required</div>
      <div style={styles.content}>
        <span>To view this content you must upgrade your billing plan.</span>
      </div>
      <div style={styles.content}>
        <span>To do this, please visit the <Link to={'/my/account/plan_and_extras'}>Plan and Extras page</Link>.</span>
      </div>
      <div style={styles.buttonsContainer}>
        <div style={styles.button} onClick={closeBtnClickHandler} key={2}>
          Close
        </div>
      </div>
    </div>
  </div>

  return ret

}

const UpgradeRequiredModal = ({show,overlayClickHandler,closeBtnClickHandler}) => (<ModalScreenOverlay onClick={overlayClickHandler} visible={show}>
    <UpgradeRequiredModalBoxContainer
      visible={show}
      closeBtnClickHandler={closeBtnClickHandler}
    />
  </ModalScreenOverlay>
)

export default UpgradeRequiredModal
