import React from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown/with-html'

import SiteLayout from '../../containers/layout/SiteLayout'
import { InnerContentContainer } from '../../styles/styled-elements'

import { fetchFlatContentPages } from '../../actions/FlatContentPageActions'

class FlatContent extends React.Component {

  constructor(props) {
    super(props)
    const { path } = props.match
    this.state = {
      contentId: path.replace(/^\//,'')
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchFlatContentPages())
  }

  getContent() {
    if (!this.props.flatContentPage) return null
    const markdown = this.props.flatContentPage.body
    return <ReactMarkdown
      source={markdown}
      escapeHtml={false}
    />
  }

  renderBody = (props,state) => <SiteLayout>
    <InnerContentContainer>
      {this.getContent()}
    </InnerContentContainer>
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {
    return ({
      flatContentPage: store.flatContentPages.list.find(({slug}) => slug===props.slug)
    })
  },
)(FlatContent)
