import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validateToken } from '../../../actions/AuthActions'
import { updateCompanyDetails } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import CompanyDetailsForm from '../../../components/my/account/CompanyDetailsForm'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'

class CompanyDetails extends React.Component {

  componentDidMount(prevProps) {
    this.props.dispatch(validateToken())
  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  handleSubmit = ({ name, abn, address1, address2, locality, postalCode, stateProvince, country }) => {
    window.scrollTo(0,window.innerHeight)
    this.props.dispatch(updateCompanyDetails({
      name: (name || '').trim(),
      abn: (abn || '').trim(),
      address1: (address1 || '').trim(),
      address2: (address2 || '').trim(),
      locality: (locality || '').trim(),
      postalcode: (postalCode || '').trim(),
      state_province: (stateProvince || '').trim(),
      country: (country || '').trim(),
    }))
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`Company Details`} />
        <WhitePanel>
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasCompanyDetails'} position={'top'} />
          <CompanyDetailsForm
            name={company.name}
            abn={company.abn}
            address1={company.address1}
            address2={company.address2}
            locality={company.locality}
            postalCode={company.postalcode}
            stateProvince={company.state_province_abbreviated_name}
            stateProvinceId={company.state_province_id}
            country={company.country_name}
            countryId={company.country_id}
            errors={apiAuth.errors || []}
            submitHandler={this.handleSubmit}
            updateLoading={this.updateLoading}
          />
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasCompanyDetails'}  position={'bottom'} />
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'Company Details'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(CompanyDetails)
