import styled, { keyframes } from 'styled-components'
import { H1, TopLevelActionsButtonContainer, GridContainer, ContainerHeading, GridSection, Row, Column, ActionLink } from '../../styles/styled-elements'
import styledTheme from '../../styles/theme'

export { H1, TopLevelActionsButtonContainer, GridContainer, ContainerHeading, GridSection, Row, Column, ActionLink, styledTheme }

export const SitesListControlContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
`
export const ListHeaderRow = styled(Row)`
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
  font-weight: 500;
  @media (max-width: ${styledTheme.breakpoints.md-1}px) {
    display: none;
  };
`
export const ListRow = styled(Row)`
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
  cursor: pointer;
  &:nth-child(even) {
    background-color: #EDEDED;
  }
  &:last-child {
    border-bottom: none;
  }
  ${({standardPointer}) => {
    return (standardPointer) ? 'cursor: default;' : null
  }}
`
export const ColumnStyle = {
  padding: '10px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '0.875rem',
}
export const SmallScreenSiteDataColumnStyle = {
  ...ColumnStyle,
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}
export const SmallScreenSiteDataSiteName = styled.div`
  font-weight: 500;
  font-size: 1.2em;
`
export const SmallScreenSiteDataDetail = styled.div`
  font-size: 0.9em;
`
export const ActionHeaderColumnStyle = {
  ...ColumnStyle,
  justifyContent: 'flex-end'
}
export const ActionColumnStyle = {
  ...ColumnStyle,
  justifyContent: 'flex-end',
}
export const ActionColumnStyleRightAligned = {
  ...ColumnStyle,
  padding: '0',
  paddingRight: '10px',
  justifyContent: 'flex-end'
}
export const ActionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  svg {
    fill: ${styledTheme.colors.action_link};
    height: 30px;
    width: 30px;
  }
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    height: 24px;
    width: 24px;
  };
`
export const ActionIcon = styled.div`
  display: inline-flex;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
`

export const ActionButton = styled.span`
  color: ${styledTheme.colors.action_link};
  margin: 0 3px;
  &:hover {
    cursor: pointer;
  }
`

export const ColumnResponsiveInnerContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${styledTheme.breakpoints.md-1}px) {
    min-height: 40px;
    font-size: 1rem;
    font-weight: 500;
  };
  ${({faded}) => {
    if (faded) {
      return 'color: #999;'
    }
  }}
`
export const DataViewIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  svg {
    fill: ${styledTheme.colors.data_view_icon_grey};
    height: 26px;
    width: 26px;
  }
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    height: 20px;
    width: 20px;
  };
`
export const UpdatingIndicatorSpan = styled.span`
  font-weight: 500;
  color: #FFF;
  background-color: #AAA;
  display: inline-flex;
  ${({isMobileView}) => {
    if (isMobileView) {
      return `
        font-size: 0.5rem;
        border-radius: 0.3em;
        padding: 0.3em 0.4em 0.4em 0.5em;
        margin-left: 0.5em;
      `
    } else {
      return `
        font-size: 0.6rem;
        border-radius: 0.4em;
        padding: 0.4em 0.5em 0.5em 0.6em;
        margin-left: 1em;
      `
    }
  }}
`
const ellipsis = keyframes`
  to {
    width: 100%;
  }
`
export const UpdatingIndicatorEllipsisContainer = styled.div`
  display: inline-flex;
  opacity: 1;
  font-size: 1em;
  width: 1.2em;
  min-width: 1em;
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\\2026';
    animation: ${ellipsis} steps(4,end) 2s infinite;
    width: 0px;
  }
`
export const PanelContainer = styled.div`
  background-color: #FFF;
  border: 1px solid #979797;
`

export const WhitePanel = styled.div`
  background-color: #FFF;
  margin: 10px;
`
export const FormContainer = styled.div`
  display: block;
`
export const FormInner = styled.div`
  display: block;
`
export const FormHeading = styled.h1`
  font-size: 20px;
`
export const Form = styled.form`
  width: 100%;
  font-size: 1.125rem;
  max-width: 400px;
`
export const FieldsWrapper = styled.div`
  margin-bottom: 20px;
  ${({show}) => {
    if (typeof show==='undefined') return null;
    if (show===false) {
      return `display: none;`
    } else if (show===true)
      return `display: block;`
    }
  }}
`
export const FieldDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
export const PlanDetailsDiv = styled.div`
  display: block;
  margin-left: 34px;
  font-size: 0.8rem;
`
export const SubmitWrapper = styled.div`
  text-align: right;
  padding: 20px 0;
  ${({left}) => {
      return (left) ? 'text-align: left;' : null
    }
  }
  ${({padsmall}) => {
      return (padsmall) ? 'padding-top: 4px;' : null
    }
  }
`
export const LinkContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  font-size: 1rem;
  text-align: center;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
  ${({left}) => left ? `text-align: left` : null}
`
export const CompletedMessageContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: left;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
`
export const RejectedMessageContainer = styled(CompletedMessageContainer)`
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.2);
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 10px;
    margin: 0 30px;
  }
`
export const CheckListItemCell = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`
export const CheckListLabelCell = styled.div`
  width: 220px;
  display: inline-flex;
  font-weight: 500;
`
export const CheckListStatusCell = styled.div`
  width: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: green;
  }
`
export const UserInvitationSuccessMessage = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: rgba(7, 222, 0, 0.5)
`
