import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validateToken } from '../../../actions/AuthActions'
import { updatePaymentMethod } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, Column, ListRow, ColumnStyle, ColumnResponsiveInnerContainer, ActionColumnStyleRightAligned, ActionIconContainer, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import PaymentDetailsForm from '../../../components/my/account/PaymentDetailsForm'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'

import Environment from '../../../utils/environment'
import { loadStripe } from '@stripe/stripe-js'

class PaymentDetails extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      topLevelErrors: [],
      loading: false,
    }
  }

  componentDidMount(prevProps) {
    this.props.dispatch(validateToken())
  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  onCreatePaymentMethod = (localParams,paymentMethod) => {
    window.scrollTo(0,window.innerHeight)
    const { dispatch } = this.props
    dispatch(updatePaymentMethod({
      local_details: {
        name_on_card: ( localParams['nameOnCard'] || '' ).trim(),
        address_same_as_company_address: localParams['addressSameAsCompanyAddress'],
        address_line1: ( localParams['addressLine1'] || '' ).trim(),
        address_line2: ( localParams['addressLine2'] || '' ).trim(),
        address_city: ( localParams['addressLocality'] || '' ).trim(),
        address_state_province: ( localParams['addressStateProvince'] || '' ).trim(),
        address_postal_code: ( localParams['addressPostalCode'] || '' ).trim(),
        address_country: ('Australia').trim(),
      },
      payment_method: { ...paymentMethod }
    }))
  }

  onCreatePaymentMethodError = (error) => {
    this.setState({ loading: false })
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value },() => {
    })
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const company = user && user.company
    const stripePromise = loadStripe(Environment.STRIPE_KEY)
    const activePaymentMethod = company.active_payment_method || {}
    const hasSavedPaymentMethod = company.active_payment_method !== null
    const addressSameAsCompanyAddress = company.active_payment_method ? activePaymentMethod['address_same_as_company_address'] : true

    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`Payment Details`} />
        <WhitePanel>
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasPaymentMethod'} position={'top'} />
          <PaymentDetailsForm
            hasSavedPaymentMethod={hasSavedPaymentMethod}
            paymentMethodType={activePaymentMethod['payment_method_type']}
            cardBrand={activePaymentMethod['card_brand']}
            cardLast4={activePaymentMethod['card_last4']}
            cardExpMonth={activePaymentMethod['card_exp_month']}
            cardExpYear={activePaymentMethod['card_exp_year']}
            paymentMethodStatus={activePaymentMethod['status']}
            lastChargedAt={activePaymentMethod['last_charged_at']}
            lastDeclinedAt={activePaymentMethod['last_declined_at']}
            lastErrorCode={activePaymentMethod['last_error_code']}
            lastDeclineCode={activePaymentMethod['last_decline_code']}
            lastErrorCodeLabel={activePaymentMethod['last_error_code_label']}
            lastDeclineCodeLabel={activePaymentMethod['last_decline_code_label']}
            addressSameAsCompanyAddress={addressSameAsCompanyAddress}
            nameOnCard={activePaymentMethod['name_on_card']}
            addressLine1={activePaymentMethod['address_line1']}
            addressLine2={activePaymentMethod['address_line2']}
            addressLocality={activePaymentMethod['address_city']}
            addressStateProvince={activePaymentMethod['address_state_province']}
            addressPostalCode={activePaymentMethod['address_postal_code']}
            addressCountry={activePaymentMethod['address_country']}
            createPaymentMethodHandler={this.onCreatePaymentMethod}
            createPaymentMethodErrorHandler={this.onCreatePaymentMethodError}
            stripePromise={stripePromise}
            updateLoading={this.updateLoading}
            errors={apiAuth.errors || []}
            submitHandler={this.handleSubmit}
          />
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasPaymentMethod'} position={'bottom'} />
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout>
    <HelmetTitle title={'Payment Details'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.state.loading || this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(PaymentDetails)
