import React from 'react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import Environment from '../utils/environment'
import { validateToken } from '../actions/AuthActions'

class Analytics extends React.Component {

  componentDidMount = () => {
    const { title, location } = window
    const { host, href, pathname, search } = location
    const url = `${pathname}${search}`
    this.props.dispatch(validateToken())
    this.initializeGoogleAnalytics(this.props.apiAuth)
  }

  componentDidUpdate = (prevProps,prevActions) => {
    const { title, location } = window
    const { host, href, pathname, search } = location
    const url = `${pathname}${search}`
    if (prevProps.apiAuth.isSignedIn!==this.props.apiAuth.isSignedIn) {
      this.initializeGoogleAnalytics(this.props.apiAuth)
    }
  }

  initializeGoogleAnalytics = (apiAuthProps) => {
    if (apiAuthProps.isSignedIn && apiAuthProps.user) {
      ReactGA.initialize(Environment.GOOGLE_ANALYTICS_ID, {
        gaOptions: {
          isSignedIn: true,
          userId: apiAuthProps.user.id,
        }
      })
    } else {
      ReactGA.initialize(Environment.GOOGLE_ANALYTICS_ID, {
        gaOptions: {
          isSignedIn: false,
        }
      })
    }
  }

  helmetChangeHandler = () => <Helmet
    onChangeClientState = {(newState) => {
      const title = newState['title']
      if (title && (title.indexOf('undefined')<0)) {
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
    }}
  />

  render = () => this.helmetChangeHandler()

}

export default connect(
  (store, props) => { return {
    apiAuth: store.apiAuth,
    router: store.router,
  }}
)(Analytics)
