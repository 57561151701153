import React from 'react'
import { connect } from 'react-redux'
import NewPasswordForm from '../../components/auth/NewPasswordForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import { newPassword, enablePasswordReset } from '../../actions/AuthActions'
import HelmetTitle from '../../components/layout/HelmetTitle'
import queryString from 'query-string'

class PasswordReset extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      submitFulfilled: false,
      submitRejected: false,
    }
  }

  componentDidMount() {
    const { props } = this
    const { dispatch, match, isSignedIn, location} = props
    const resetPasswordToken = queryString.parse(location.search).token
    dispatch(enablePasswordReset(resetPasswordToken))
  }

  componentDidUpdate(prevProps,prevState) {
    const { props } = this
    const updateHash = {}
    if (props.newPasswordSent!==prevProps.newPasswordSent && props.newPasswordSent) {
      updateHash['submitFulfilled'] = true
    }
    if (props.newPasswordRejected!==prevProps.newPasswordRejected && props.newPasswordRejected) {
      updateHash['submitRejected'] = true
    }
    if (Object.keys(updateHash).length) this.setState(updateHash)
  }

  handleSubmit = ({ password, password_confirmation }) => {
    const reset_password_token = queryString.parse(this.props.location.search).token
    this.props.dispatch(newPassword({
      password,
      password_confirmation,
      reset_password_token,
    }))
  }

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'New Password'} />
    <NewPasswordForm
      submitFulfilled={state.submitFulfilled}
      submitRejected={state.submitRejected}
      apiAuth={props.apiAuth}
      submitHandler={this.handleSubmit}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
    newPasswordSending: store.apiAuth.newPasswordSending,
    newPasswordSent: store.apiAuth.newPasswordSent,
    newPasswordRejected: store.apiAuth.newPasswordRejected,
  }),
)(PasswordReset)
