import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import createRootReducer from './createRootReducer'

export const history = createBrowserHistory()

const routing = routerMiddleware(history)
const logger = (process.env.NODE_ENV === 'development') ? require('redux-logger').default : false

const middleware = [
  thunk,
  promise,
  routing,
  logger,
].filter(m => typeof m === 'function')

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(...middleware),
    ),
  )

  return store
}
