import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ReactGA from 'react-ga'
import InfoAlertSplashScreen from '../../components/infoAlerts/InfoAlertSplashScreen'
import { updateSeenItems } from '../../actions/InfoAlertActions'

class NewInfoAlertSplash extends React.Component {

  componentDidMount() {
  	if (this.props.unseenInfoSplashAlerts.length) {
  	  this.gaTrackInfoSplashAlertShown([...this.props.unseenInfoSplashAlerts])
  	}
  }

  componentDidUpdate(prevProps) {
  	const newIds = this.props.unseenInfoSplashAlerts.map(({id}) => id)
  	const oldIds = prevProps.unseenInfoSplashAlerts.map(({id}) => id)
  	if (JSON.stringify(newIds)!==JSON.stringify(oldIds)) {
  	  this.gaTrackInfoSplashAlertShown([...this.props.unseenInfoSplashAlerts])
  	}
  }

  gaTrackInfoSplashAlertShown = (unseenInfoSplashAlerts) => {
    unseenInfoSplashAlerts.forEach(({id,title,published_at_str,slug}) => {
      const route = `${published_at_str}/${slug}`
      ReactGA.event({
        category: 'Site Action',
        action: 'Info Splash Alert – Shown Item',
        label: route,
      })
    })
  }

  closeAlertWindowHandler = () => {
  	const { dispatch, unseenInfoSplashAlerts } = this.props
  	dispatch(updateSeenItems(unseenInfoSplashAlerts.map(({id_content_updated_at_str}) => id_content_updated_at_str)))
    ReactGA.event({
      category: 'Site Action',
      action: 'Info Splash Alert – Closed',
    })
  }

  routeToShowPageAndUpdateSeenItems = (route) => {
  	const { dispatch, unseenInfoSplashAlerts } = this.props
  	dispatch(push(route))
  	dispatch(updateSeenItems(unseenInfoSplashAlerts.map(({id_content_updated_at_str}) => id_content_updated_at_str)))
    ReactGA.event({
      category: 'Site Action',
      action: 'Info Splash Alert – Read More',
      label: route,
    })
  }

  renderBody = (props,state) => {
  	if (!props.unseenInfoSplashAlerts.length) return null
  	return <InfoAlertSplashScreen
      show={(props.unseenInfoSplashAlerts.length > 0)}
      alerts={props.unseenInfoSplashAlerts}
      closeAlertWindowHandler={this.closeAlertWindowHandler}
      routeToShowPageAndUpdateSeenItems={this.routeToShowPageAndUpdateSeenItems}
    />
  }

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {
  	const seenItemIds = JSON.parse(localStorage.getItem('infoSplashAlertsSeenItems')) || []
  	const unseenInfoSplashAlerts = store.infoAlerts.list.filter(({id_content_updated_at_str,splash_alert}) => ((seenItemIds.indexOf(id_content_updated_at_str)===-1) && (splash_alert===true))) || []
    return ({
      unseenInfoSplashAlerts: unseenInfoSplashAlerts
    })
  },
)(NewInfoAlertSplash)
