import React from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton } from '../../../styles/styled-elements'

const FormElement = ({ formInputChangeHandler, setSelectedStateValue, handleSubmit, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const classes = useStyles()

  return <FormContainer>
    <FormInner>
      <Form onSubmit={handleSubmit}>
        {state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper show={(!state.addressSameAsPrimary)}>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Company/Organisation Name"
              name="name"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'name',
              }}
              helperText="If none, enter your full name"
              value={state.name || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.name}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.name}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="ABN"
              name="abn"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'abn',
              }}
              helperText="Optional but recommended for invoicing purposes"
              value={state.abn || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.abn}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.abn}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Address Line 1"
              name="address1"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'address1',
              }}
              value={state.address1 || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.address1}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.address1}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Address Line 2"
              name="address2"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'address2',
              }}
              helperText="Optional"
              value={state.address2 || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.address2}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.address2}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="City/Town/Locality"
              name="locality"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'locality',
              }}
              value={state.locality || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.locality}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.locality}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              required
              name="stateProvince"
              options={[
                'ACT',
                'NSW',
                'NT',
                'QLD',
                'SA',
                'VIC',
                'TAS',
                'WA',
              ]}
              onChange={(event, newValue) => {
                setSelectedStateValue(newValue)
              }}
              value={state.stateProvince}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="State"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 200 }}
            />
            <FormHelperText error>{state.errors && state.errors.stateProvince}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Postcode"
              name="postalCode"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'postalCode',
              }}
              value={state.postalCode || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.postalCode}
              style={{ width: '100%', marginTop: 20, width: 200 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.postalCode}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Update</RoundedFormButton>
        </SubmitWrapper>
      </Form>
    </FormInner>
  </FormContainer>
}

export default class CompanyDetailsForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      abn: '',
      address1: '',
      address2: '',
      locality: '',
      stateProvince: '',
      postalCode: '',
      origValues: {
        name: '',
        abn: '',
        address1: '',
        address2: '',
        locality: '',
        stateProvince: '',
        postalCode: '',
      },
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }


  componentDidMount(prevProps) {
    this.populateForm()
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const { name, abn, address1, address2, locality, postalCode, stateProvince, origValues } = this.state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      if (name!==props.name && name!==origValues.name) hasChanged = true
      if (abn!==props.abn && abn!==origValues.abn) hasChanged = true
      if (address1!==props.address1 && address1!==origValues.address1) hasChanged = true
      if (address2!==props.address2 && address2!==origValues.address2) hasChanged = true
      if (locality!==props.locality && locality!==origValues.locality) hasChanged = true
      if (postalCode!==props.postalCode && postalCode!==origValues.postalCode) hasChanged = true
      if (stateProvince!==props.stateProvince && stateProvince!==origValues.stateProvince) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if (
        name &&
        address1 &&
        locality &&
        postalCode &&
        stateProvince
      ) {
        canSubmit = true
      }
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props!==prevProps) {
      if (
        (props.name!==prevProps.name) ||
        (props.abn!==prevProps.abn) ||
        (props.address1!==prevProps.address1) ||
        (props.address2!==prevProps.address2) ||
        (props.locality!==prevProps.locality) ||
        (props.postalCode!==prevProps.postalCode) ||
        (props.stateProvince!==prevProps.stateProvince)
      ) {
        stateHash['name'] = props.name
        stateHash['abn'] = props.abn
        stateHash['address1'] = props.address1
        stateHash['address2'] = props.address2
        stateHash['locality'] = props.locality
        stateHash['stateProvince'] = props.stateProvince || ''
        stateHash['postalCode'] = props.postalCode
        stateHash['country'] = props.country
        const origValuesHash = { ...stateHash }
        stateHash['origValues'] = origValuesHash
        stateHash['errors'] = props.errors
        stateHash['hasChanged'] = false
        stateHash['canSubmit'] = false
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  populateForm = () => {
    const { props } = this
    const stateHash = {}
    stateHash['name'] = props.name
    stateHash['abn'] = props.abn
    stateHash['address1'] = props.address1
    stateHash['address2'] = props.address2
    stateHash['locality'] = props.locality
    stateHash['stateProvince'] = props.stateProvince || ''
    stateHash['postalCode'] = props.postalCode
    stateHash['country'] = props.country
    const origValuesHash = { ...stateHash }
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['hasChanged'] = false
    stateHash['canSubmit'] = false
    this.setState(stateHash)
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  setSelectedStateValue = (v) => {
    this.setState({
      stateProvince: v ? v : '',
      hasChanged: true,
    })
  }

  validate = (callback) => {
    const errors = {}
    const { name, abn, address1, address2, locality, postalCode, stateProvince } = this.state
    if (!name) {
      errors.name = 'Company/Organisation Name is required'
    } else if ((name).length < 3) {
      errors.name = 'Company/Organisation Name is too short'
    }
    let newAbn = abn ? abn.replace(/\ /g,"") : ''
    if (newAbn.length && (newAbn.length < 11)) {
      errors.abn = 'ABN is too short'
    }
    if (address1.length < 5) {
      errors.address1 = 'Address is too short'
    }
    if (locality.length < 2) {
      errors.locality = 'Locality is too short'
    }
    if (postalCode.trim().length < 4) {
      errors.postalCode = 'Postcode is too short'
    }
    if (Object.keys(errors).length) {
      this.setState({
        abn: newAbn,
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { name, abn, address1, address2, locality, postalCode, stateProvince } = this.state
    const params = { name, abn, address1, address2, locality, postalCode, stateProvince }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value })
  }

  renderBody = (props,state) => <FormElement
    formInputChangeHandler={this.formInputChangeHandler}
    setSelectedStateValue={this.setSelectedStateValue}
    handleSubmit={this.handleSubmit}
    state={state}
  />

  render() {
    return this.renderBody(this.props,this.state)
  }
}
