import React from 'react'
import { connect } from 'react-redux'
import PasswordResetForm from '../../components/auth/PasswordResetForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import { signOut } from '../../actions/AuthActions'
import HelmetTitle from '../../components/layout/HelmetTitle'
import LoadingScreen from '../../components/LoadingScreen'

class PasswordReset extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      submitFulfilled: false,
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps,prevState) {
    const { props } = this
    const { router } = props
    const prevRouter = prevProps.router
    const updateHash = {}
    if (props.resetPasswordSent!==prevProps.resetPasswordSent && props.resetPasswordSent) {
      updateHash['submitFulfilled'] = true
    }
    if (router.action==="PUSH" && router.location.pathname==='/password_reset' && router.location.key!==prevRouter.location.key) {
      updateHash['submitFulfilled'] = false
    }
    if (Object.keys(updateHash).length) this.setState(updateHash)
  }

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Reset Password'} />
    <PasswordResetForm
      submitFulfilled={state.submitFulfilled}
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
    />
    <LoadingScreen show={props.resetPasswordSending} />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
    resetPasswordSending: store.apiAuth.resetPasswordSending,
    resetPasswordSent: store.apiAuth.resetPasswordSent,
  }),
)(PasswordReset)
