import React from 'react'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import { formatDateUpdated, formatValueDisplay, convertDegreesToIconAngle } from '../../utils/dateAndNumberFormatUtils'

import {
  ModalScreenOverlay,
  MobileStatusContainer,
  SiteStatusPopupHeaderContainer,
  SiteStatusPopupCloseIcon,
  SiteStatusPopupHeaderH1Row,
  SiteStatusPopupHeaderH1Cell,
  SiteStatusPopupH1,
  SiteStatusPopupHeaderDashboardLinkCell,
  SiteStatusPopupHeaderDashboardLink,
  SiteStatusPopupDateUpdated,
  SiteStatusPopupBodyContainer,
  SiteStatusPopupMetricCell,
  SiteStatusPopupMetricCellInner,
  SiteStatusPopupMetricLabel,
  SiteStatusPopupMetricUnit,
  SiteStatusPopupMetricValue,
  SiteStatusPopupWindContainer,
  SiteStatusPopupWindIcon,
  SiteStatusPopupWindLabel,
  SiteStatusPopupMetricIndicatorBoxRow,
  SiteStatusPopupMetricIndicatorBox,
  SiteStatusPopupAdContainer,
  SiteStatusPopupLink,
  SiteStatusPopupAd,
  SiteStatusPopupAdHeaderSponsoredMsg,
  SiteStatusPopupAdHeadlineTxt,
  SiteStatusPopupAdBodyTxt,
  SiteStatusPopupAdReadMore,
  SiteStatusPopupHeaderHeadingContent
} from './styled-elements'
import { isDataFresh } from "../../utils/mapUtils";

const WindIconAndDirectionLabel  = ({windDirectionValue,windDirectionLabel}) => {
  return <SiteStatusPopupWindContainer>
    <SiteStatusPopupWindIcon angle={convertDegreesToIconAngle(windDirectionValue)}/>
    <SiteStatusPopupWindLabel>
      {windDirectionLabel}
    </SiteStatusPopupWindLabel>
  </SiteStatusPopupWindContainer>
}

export default class SiteStatusPopup extends React.Component {

  constructor(props) {
    super(props)
    this.Overlay = React.createRef()
    this.PopupContainer = React.createRef()
  }

  getBoundingClientRect = () => {
    return this.PopupContainer.current.getBoundingClientRect()
  }

  componentDidUpdate(prevProps) {
  }

  browseToDashBoard(clickItem) {
    const { name, props, slug, routeToDataViewHandler } = this.props
    ReactGA.event({
      category: 'Site Action',
      action: `View Dashboard from Popup ${clickItem}`,
      label: name,
    })
    routeToDataViewHandler.call(this,slug,'dashboard')
  }

  callCloseActionIfNotMainContainer = (event,closeStatusPopupHandler) => {
    if (event.target === event.currentTarget) closeStatusPopupHandler.call()
  }

  renderBody = (props,state) => {

    const { name, isSignedIn, company, awsType, isMesonet, closeStatusPopupHandler,
      lastUpdated, timezone, siteSummaryDataHash } = props

    const dataIsFresh = isDataFresh(awsType, lastUpdated, 3600000 * 3.5);

    return <ModalScreenOverlay ref={this.Overlay} onClick={e => this.callCloseActionIfNotMainContainer(e,closeStatusPopupHandler)}>
      <MobileStatusContainer ref={this.PopupContainer}>
        <SiteStatusPopupHeaderContainer>
          <SiteStatusPopupCloseIcon
            onClick={closeStatusPopupHandler}
          />
          <SiteStatusPopupHeaderHeadingContent>
            <SiteStatusPopupHeaderH1Cell>
              <SiteStatusPopupH1 onClick={this.browseToDashBoard.bind(this,'Heading')}>
                {name}
              </SiteStatusPopupH1>
            </SiteStatusPopupHeaderH1Cell>
            {isSignedIn && <SiteStatusPopupHeaderDashboardLinkCell>
              <SiteStatusPopupHeaderDashboardLink onClick={this.browseToDashBoard.bind(this,'Button')}>Data history</SiteStatusPopupHeaderDashboardLink>
            </SiteStatusPopupHeaderDashboardLinkCell>}
          </SiteStatusPopupHeaderHeadingContent>
          <SiteStatusPopupDateUpdated>
            {formatDateUpdated(lastUpdated,timezone)}
          </SiteStatusPopupDateUpdated>
        </SiteStatusPopupHeaderContainer>
        {(1===2) &&
          <SiteStatusPopupAdContainer top>
            <SiteStatusPopupLink href="http://taindata.com" target="_blank">
              <SiteStatusPopupAd top>
                <SiteStatusPopupAdHeaderSponsoredMsg>Sponsored message</SiteStatusPopupAdHeaderSponsoredMsg>
                <SiteStatusPopupAdHeadlineTxt>Soil moisture monitoring, designed for the needs of South Australian growers</SiteStatusPopupAdHeadlineTxt>
                <SiteStatusPopupAdBodyTxt>The TainData Tierra soil moisture data logger has been developed in partnership with leading consultants and growers in South Australia.</SiteStatusPopupAdBodyTxt>
                <SiteStatusPopupAdReadMore>Learn more...</SiteStatusPopupAdReadMore>
              </SiteStatusPopupAd>
            </SiteStatusPopupLink>
          </SiteStatusPopupAdContainer>
        }
        <SiteStatusPopupBodyContainer>
          {siteSummaryDataHash && siteSummaryDataHash.data_array && siteSummaryDataHash.data_array.sort((o) => o.sort_order ).map(({label, value, unit, value_str, value_snapshot, wind_direction_value, wind_direction_label, styles, included_in_feature_set, unsupported},index) => {
            if ((!isSignedIn) && (value_str==='S')) return undefined
            if ((awsType==='bom') && (value_str==='B')) return undefined
            if ((awsType==='hilltop') && (value_str==='H')) return undefined
            if (unsupported) return undefined
            let faded = false
            let showColorBar = true
            let valueElementStyles = String(value_snapshot || value).length > 4 ? { fontSize: "2.4em" } : {};
            const includedInFeatureSet = included_in_feature_set
            if (isSignedIn && !includedInFeatureSet) {
              faded=true
              showColorBar=false
            }
            return <SiteStatusPopupMetricCell key={index}>
              <SiteStatusPopupMetricCellInner faded={faded}>
                <SiteStatusPopupMetricLabel>{ label }</SiteStatusPopupMetricLabel>
                <SiteStatusPopupMetricUnit>{ includedInFeatureSet && unit }</SiteStatusPopupMetricUnit>
                <SiteStatusPopupMetricValue style={{...valueElementStyles}}>
                  { (includedInFeatureSet && dataIsFresh) ? (value_snapshot || value) :
                    includedInFeatureSet ? "–" : <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={props.showUpgradeRequiredModalHandler.bind(this)}>U</span>
                   }
                  { dataIsFresh && Number.isInteger(value_snapshot || value) && Number.isInteger(wind_direction_value) && <WindIconAndDirectionLabel windDirectionValue={wind_direction_value} windDirectionLabel={wind_direction_label} /> }
                </SiteStatusPopupMetricValue>
                <SiteStatusPopupMetricIndicatorBoxRow>{showColorBar && <SiteStatusPopupMetricIndicatorBox backgroundColor={dataIsFresh ? styles.background_color : "#FFF"}/>}</SiteStatusPopupMetricIndicatorBoxRow>
              </SiteStatusPopupMetricCellInner>
            </SiteStatusPopupMetricCell>
          }).filter((item) => (typeof item!=='undefined'))}
        </SiteStatusPopupBodyContainer>
        {(!isSignedIn) && <div style={{ padding: '0.8em 0.4em', margin: '0.4em' }}>
          <div style={{ fontSize: '1.6em', fontWeight: 700 }}>Log in for more</div>
            <p style={{ fontSize: '1.2em', fontWeight: 500 }}>To view more data from this AWS, <Link to={'/login'}>Log in</Link> or <Link to={'/signup'}>Sign Up</Link>.</p>
          </div>
        }
        {(1===2) &&
        <SiteStatusPopupAdContainer bottom>
          <SiteStatusPopupLink href="http://taindata.com" target="_blank">
            <SiteStatusPopupAd bottom>
              <SiteStatusPopupAdHeaderSponsoredMsg>Sponsored message</SiteStatusPopupAdHeaderSponsoredMsg>
              <SiteStatusPopupAdHeadlineTxt>Soil moisture monitoring, designed for the needs of South Australian growers</SiteStatusPopupAdHeadlineTxt>
              <SiteStatusPopupAdBodyTxt>The TainData Tierra soil moisture data logger has been developed in partnership with leading consultants and growers in South Australia.</SiteStatusPopupAdBodyTxt>
              <SiteStatusPopupAdReadMore>Learn more...</SiteStatusPopupAdReadMore>
            </SiteStatusPopupAd>
          </SiteStatusPopupLink>
          </SiteStatusPopupAdContainer>
        }
      </MobileStatusContainer>
    </ModalScreenOverlay>

  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
