import Moment from 'moment-timezone'

export function formatDateUpdated(dateUpdated,timezone) {

  const unknownDateStr = "Updated date unknown"
  const aYearAgo = Moment(new Date()).subtract('1','years').format('x')

  if (typeof dateUpdated!=='number') return unknownDateStr
  if (dateUpdated===0) return unknownDateStr
  if (dateUpdated < aYearAgo) return unknownDateStr

  const dateUpdatedMoment = Moment.tz(dateUpdated,timezone)
  const nowMoment = Moment.tz(new Date(),timezone)
  const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
  const diffDays = diffDuration ? diffDuration.asDays() : 0
  const diffHours = diffDuration ? diffDuration.asHours() : 0
  const diffMins = diffDuration ? diffDuration.asMinutes() : 0

  let updatedAtRelativeStr = ''

  if (diffHours < 2) {
    if (diffMins < 1) {
      updatedAtRelativeStr = `less than a minute ago`
    } else if (diffMins < 2) {
      updatedAtRelativeStr = `a minute ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
    }
  } else if (diffHours < 48) {
    updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
  } else {
    updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
  }

  let updatedAtTimeStr = `${dateUpdatedMoment.format("HH:mm")} on ${dateUpdatedMoment.format("D MMM YYYY")}`

  if (diffHours < 240) {
    updatedAtTimeStr += ` (${updatedAtRelativeStr})`
  }

  return `Updated at ${updatedAtTimeStr}`

}

export function formatValueDisplay({value,unit,decimalPlaces}) {
  const powerMultiplier = Math.pow(10,decimalPlaces || 0)
  const formattedValue = value ? Math.round(value*powerMultiplier)/powerMultiplier : ''
  const formattedUnit = (value && unit) ? unit : ''
  return `${formattedValue}${formattedUnit}`
}

export function convertDegreesToIconAngle(degrees) {
  const ret = (typeof degrees==='number') ?
    (degrees<180) ?
      degrees+180 :
      degrees-180 :
    null
  return ret
}
