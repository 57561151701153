const initialState = {
  dataView: {},
  dataViewSummary: {},
  dataViewHash: {},
  dataViews: [],
  addManualInputFormVisible: false,
  fetching: false,
  fetched: false,
  dataViewHashFetching: false,
  dataViewHashFetched: false,
  error: null,
  saved: false,
  saving: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "DATA_VIEW_SUMMARY_SET":
      return {
        ...state,
        fetching: false,
        fetched: true,
        dataViewSummary: action.meta.dataViewSummary
      }
    case "DATA_VIEW_HASH_FETCH_REJECTED":
      return {
        ...state,
        dataViewHashFetching: false,
        dataViewHashFetched: false,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
      }
    case "DATA_VIEW_HASH_FETCH_PENDING":
      return {
        ...state,
        dataViewHashFetching: true,
        dataViewHashFetched: false,
        dataViewHash: null
      }
    case "DATA_VIEW_HASH_FETCH_FULFILLED":
      return {
        ...state,
        dataViewHashFetching: false,
        dataViewHashFetched: true,
        dataViewHash: action.payload.data
      }
    case "DATA_VIEW_HASH_CLEAR":
      return {
        ...state,
        dataViewHashFetching: false,
        dataViewHashFetched: false,
        dataViewHash: null,
      }
    case "DATA_VIEW_ADD_MANUAL_INPUT_FORM_VISIBLE":
      return {
        ...state,
        addManualInputFormVisible: action.meta.visible
      }
    case  'USER_SIGN_OUT_PENDING':
      return {
        ...initialState
      }
    case 'USER_SIGN_OUT_FULFILLED':
      return {
        ...initialState
      }
    default:
      return state
  }
}
