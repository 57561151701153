import React from 'react'
import { Link } from 'react-router-dom'
import { InfoAlertSplashScreenOverlay, MenuCloseIconContainer, InfoAlertsSplashContainer, InfoAlertsSplashList, InfoAlertsSplashListItem, InfoAlertsSplashListItemHeading } from './styled-elements'
import CloseCircle from '../icons/CloseCircle'

const InfoAlertSplashScreen = ({ show, alerts, closeAlertWindowHandler, routeToShowPageAndUpdateSeenItems }) => {
  return <InfoAlertSplashScreenOverlay show={show} onClick={closeAlertWindowHandler.bind(this)}>
    <InfoAlertsSplashContainer onClick={(e) => { e.stopPropagation()}}>
      <MenuCloseIconContainer onClick={closeAlertWindowHandler.bind(this)}><CloseCircle /></MenuCloseIconContainer>
      <InfoAlertsSplashList>
        {alerts.map(({ title, teaser, published_at_str, slug },index) => {
        const showPageRoute = `/info_alerts/${published_at_str}/${slug}`
        return <InfoAlertsSplashListItem key={index} onClick={routeToShowPageAndUpdateSeenItems.bind(this,showPageRoute)}>
            <InfoAlertsSplashListItemHeading>{title}</InfoAlertsSplashListItemHeading>
            <div>{teaser}</div>
            <div><Link to={showPageRoute} onClick={closeAlertWindowHandler.bind(this)}>Read more...</Link></div>
          </InfoAlertsSplashListItem>
        }
        )}
      </InfoAlertsSplashList>
    </InfoAlertsSplashContainer>
  </InfoAlertSplashScreenOverlay>
}

export default InfoAlertSplashScreen
