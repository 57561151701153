import React from 'react'

const InfoCircleIcon = () => (<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="info-circle" fill="#000000" fillRule="nonzero">
      <path d="M12,0 C5.371875,0 0,5.371875 0,12 C0,18.628125 5.371875,24 12,24 C18.628125,24 24,18.628125 24,12 C24,5.371875 18.628125,0 12,0 Z M12,6 C12.8282812,6 13.5,6.67171875 13.5,7.5 C13.5,8.32828125 12.8282812,9 12,9 C11.1717188,9 10.5,8.3296875 10.5,7.5 C10.5,6.6703125 11.1703125,6 12,6 Z M13.875,18 L10.125,18 C9.50625,18 9,17.4984375 9,16.875 C9,16.2515625 9.50390625,15.75 10.125,15.75 L10.875,15.75 L10.875,12.75 L10.5,12.75 C9.87890625,12.75 9.375,12.2460938 9.375,11.625 C9.375,11.0039062 9.88125,10.5 10.5,10.5 L12,10.5 C12.6210938,10.5 13.125,11.0039062 13.125,11.625 L13.125,15.75 L13.875,15.75 C14.4960938,15.75 15,16.2539062 15,16.875 C15,17.4960938 14.4984375,18 13.875,18 Z" id="Shape"></path>
    </g>
  </g>
</svg>)

export default InfoCircleIcon
