import React from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton } from '../../../styles/styled-elements'

const FormElement = ({ formInputChangeHandler, formCheckboxChangeHandler, setSelectedStateValue, handleSubmit, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const classes = useStyles()

  return <FormContainer>
    <FormInner>
      <Form onSubmit={handleSubmit}>
        {state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Email Address"
              name="email"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'none',
              }}
              value={state.email || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.email}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.email}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              variant="standard"
              label="Re-type Email Address"
              name="retypeEmail"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'none',
              }}
              value={state.retypeEmail || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.retypeEmail}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.retypeEmail}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <FieldsWrapper style={{ marginTop: '40px' }}>
          <FormLabel>Permissions</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionHomeLocationGrowingSeasons}
                name="permissionHomeLocationGrowingSeasons"
                onChange={formCheckboxChangeHandler}
              />}
              label="Home Location and Growing Seasons"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionAccountBilling}
                name="permissionAccountBilling"
                onChange={formCheckboxChangeHandler}
              />}
              label="Account and Billing"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionUserManagement}
                name="permissionUserManagement"
                onChange={formCheckboxChangeHandler}
              />}
              label="User Management"
            />
          </FormGroup>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Send Invitation</RoundedFormButton>
        </SubmitWrapper>
      </Form>
    </FormInner>
  </FormContainer>
}


export default class UserManagementInviteNewForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      retypeEmail: '',
      permissionHomeLocationGrowingSeasons: true,
      permissionAccountBilling: true,
      permissionUserManagement: true,
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidMount(prevProps) {
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const { email, retypeEmail, permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement } = this.state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      if (email!==prevState.email) hasChanged = true
      if (retypeEmail!==prevState.retypeEmail) hasChanged = true
      if (permissionHomeLocationGrowingSeasons!==prevState.permissionHomeLocationGrowingSeasons) hasChanged = true
      if (permissionAccountBilling!==prevState.permissionAccountBilling) hasChanged = true
      if (permissionUserManagement!==prevState.permissionUserManagement) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if (
        email &&
        retypeEmail
      ) {
        canSubmit = true
      }
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  formCheckboxChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      hasChanged: true,
    })
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  validate = (callback) => {
    const errors = {}
    const { email, retypeEmail } = this.state
    if (!email) {
      errors.email = 'Email address is required'
    } else if (!(email || '').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.email = 'Must be a valid email address'
    }
    if (email!==retypeEmail) {
      errors.retypeEmail = 'Email addresses must match'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { email, retypeEmail, permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement } = this.state
    const params = { email, permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value })
  }

  renderBody = (props,state) => <FormElement
    formInputChangeHandler={this.formInputChangeHandler}
    formCheckboxChangeHandler={this.formCheckboxChangeHandler}
    setSelectedStateValue={this.setSelectedStateValue}
    handleSubmit={this.handleSubmit}
    state={state}
  />

  render() {
    return this.renderBody(this.props,this.state)
  }
}
