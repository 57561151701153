if (process.env.NODE_ENV === 'development') {
  require('dotenv').config()
}

const Environment = {
  API_URL: process.env.REACT_APP_API_URL,
  MEMBER_API_URL: process.env.REACT_APP_MEMBER_API_URL,
  DYNAMIC_ASSET_HOST: process.env.REACT_APP_DYNAMIC_ASSET_HOST,
  DYNAMIC_ASSET_HOST_POOL_TEMPLATE: process.env.REACT_APP_DYNAMIC_ASSET_HOST_POOL_TEMPLATE,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  SEGMENT: process.env.REACT_APP_SEGMENT_ID,
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
};

module.exports = Environment
