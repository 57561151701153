import React from 'react'
import { Helmet } from 'react-helmet'

const HelmetTitle = ({ title }) => {
  return <Helmet>
    <title>{`${title}`}</title>
    <meta property="og:type" content={`${title}`} />
  </Helmet>
}

export default HelmetTitle
