import axios from 'axios'

export function fetchInfoAlerts() {
  return (dispatch, getState) => {
    dispatch({
      type: 'INFO_ALERTS_FETCH',
      payload: axios(`/info_alerts`)
    })
  }
}

export function updateSeenItems(newSeenItems) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INFO_ALERTS_UPDATE_SEEN_ITEMS',
      meta: { newSeenItems },
    })
  }
}

export function updateReadItems(newReadItems) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INFO_ALERTS_UPDATE_READ_ITEMS',
      meta: { newReadItems },
    })
  }
}
