
export function compareArrays(arrayA,arrayB) {
  let same = true
  arrayA.forEach((arrayAItem) => {
    if (!arrayB.includes(arrayAItem)) same = false
  })
  arrayB.forEach((arrayBItem) => {
    if (!arrayA.includes(arrayBItem)) same = false
  })
  return same
}
