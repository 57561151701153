import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: flex-end;
  //border: 1px solid black;
  font-size: 14px;
  margin: ${({position}) => {
    if (position==='top') return `20px 0 10px 0`;
    if (position==='bottom') return `0px 0 20px 0`;
  }};
`

export default class AccountSetupNextStepLink extends React.Component {

  getAccountSignupProgress = () => {

    const { user } = this.props.apiAuth
    const company = user && user.company

    if (!company) return { completed: false }

    const accountSignupProgressSteps = [{
      itemKey: 'hasCompanyDetails',
      completed: company.has_company_details,
      link: '/my/account/company_details',
      label: 'Complete Your Company Details',
    },{
      itemKey: 'hasBillingPlan',
      completed: company.has_billing_plan,
      link: '/my/account/plan_and_extras',
      label: 'Select a Billing Plan',
    },{
      itemKey: 'hasPaymentMethod',
      completed: company.has_payment_method,
      link: '/my/account/payment_details',
      label: 'Enter Your Payment Details',
    },{
      itemKey: 'hasUserSurveyResponse',
      completed: company.has_user_survey_response,
      link: '/my/account/user_survey',
      label: 'Complete User Survey',
    },{
      itemKey: 'hasBillingDiscountCodeUse',
      completed: company.has_billing_discount_code_use,
      link: '/my/account/start_trial_or_billing',
      label: 'Start Trial or Billing',
    }]

    return accountSignupProgressSteps

  }

  renderBody = (props,state) => {

    const { apiAuth } = this.props

    const { user } = apiAuth
    const company = user && user.company
    const hasBillingDiscountCodeUse = company.has_billing_discount_code_use

    if (hasBillingDiscountCodeUse) return null


    const accountSignupProgressArray = this.getAccountSignupProgress()
    const nextItem = accountSignupProgressArray.find(({ completed }) => completed===false)

    if (!nextItem) return null

    if (props.currentStepItemKey===nextItem.itemKey) return null

    return <ContainerDiv position={props.position}>
      Next Step: &nbsp; <Link to={nextItem.link}>{nextItem.label} &#187;</Link>
    </ContainerDiv>

  }

  render() {
    return this.renderBody(this.props,this.state)
  }

}
