import styled from 'styled-components'
import closeIcon from '../../assets/icons/svg/baseline-close-24px.svg'
import styledTheme from '../../styles/theme'
import Environment from '../../utils/environment'
import { getDynamicAssetHostPoolAddress } from '../../utils/assetUtils'

export const MarkerWrapper = styled.div`
  transform: translateX(-50%) translateY(-50%);
  width: ${({nominated}) => nominated ? '50px' : '40px'};
  height: ${({nominated}) => nominated ? '50px' : '40px'};
  position: absolute;
  z-index: ${({zIndexLayer}) => { return zIndexLayer }};
  &:hover {
    z-index: 150;
  }
`
export const MarkerDivNominatedOuter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: ${styledTheme.colors.marker_enabled_border};
`
export const DraggableStarWrapper = styled.div`
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  position: absolute;
  //border: 2px solid pink;
  z-index: ${({zIndexLayer}) => zIndexLayer};
  &:hover {
    z-index: ${({zIndexLayer}) => zIndexLayer};
  }
`
export const MarkerDiv = styled.div`
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  ${({bgImage,angle,nominated}) => {
    if (typeof angle==='number') return `\
    &:before { \
      content: ""; \
      position: absolute; \
      width: ${nominated ? '70px' : '60px'}; \
      height: ${nominated ? '70px' : '60px'}; \
      border-radius: ${nominated ? '70px' : '60px'}; \
      z-index: -1; \
      background-image: url(${bgImage}); \
      background-size: 14px; \
      background-position: center top; \
      background-repeat: no-repeat; \
      transform: rotate(${angle}deg); \
    } \
  `
  }}
`
export const SvgMarkerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  svg {
    position: absolute;
    z-index: -1;
    width: 40px;
    height: 40px;
    g {
      use {
        stroke: ${({angle,noWindFadedOutlineColor}) => angle ? 'black' : noWindFadedOutlineColor};
        stroke-width: 4;
        fill: ${({bgColor}) => bgColor ? bgColor : '#FFF'};
      }
    }
  }
  ${({bgImage,angle}) => {
    if (typeof angle==='number') return `\
    &:before { \
      content: ""; \
      position: absolute; \
      width: 60px; \
      height: 60px; \
      border-radius: 60px; \
      z-index: -1; \
      background-image: url(${bgImage}); \
      background-size: 14px; \
      background-position: center top; \
      background-repeat: no-repeat; \
      transform: rotate(${angle}deg); \
    } \
  `
  }}
`

export const SvgMarkerDivSmall = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  svg {
    position: absolute;
    overflow: visible;
    z-index: -1;
    width: 35px;
    height: 35px;
    .map-marker-shape {
      stroke: ${({angle,noWindFadedOutlineColor}) => angle ? 'black' : noWindFadedOutlineColor};
      stroke-width: 6;
      fill: ${({bgColor}) => bgColor ? bgColor : '#FFF'};
    }
  }
  ${({bgImage,angle}) => {
  if (typeof angle==='number') return `\
    &:before { \
      content: ""; \
      position: absolute; \
      width: 52px; \
      height: 52px; \
      border-radius: 60px; \
      z-index: -1; \
      background-image: url(${bgImage}); \
      background-size: 12px; \
      background-position: center top; \
      background-repeat: no-repeat; \
      transform: rotate(${angle}deg); \
    } \
  `
}}
`

export const DraggableStarMarkerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  svg {
    position: absolute;
    z-index: -1; \
    width: 60px;
    height: 60px;
  }
`
export const ModalScreenOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  align-items: center;
  justify-content: center;
`
export const MobileStatusContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #EDEDED;
  border: 3px solid #666;
  border-radius: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  max-width: 400px;
  max-height: 900px;
  font-size: 11px;
  overflow-y: hidden;
`
export const SiteStatusPopupCloseIcon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 12px;
  top: 12px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  background-image: url(${closeIcon});
  background-size: cover;
  cursor: pointer;
`
export const SiteStatusPopupHeaderContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: #FFF;
  border-bottom: 1px solid #ADC0BE;
`
export const SiteStatusPopupHeaderHeadingContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`
export const SiteStatusPopupHeaderH1Cell = styled.div`
  display: inline-flex;
  align-items: flex-end;
`
export const SiteStatusPopupH1 = styled.h1`
  margin: 10px 0 6px 0;
  cursor: pointer;
`
export const SiteStatusPopupHeaderDashboardLinkCell = styled.div`
  display: inline-flex;
  align-items: flex-end;
`
export const SiteStatusPopupHeaderDashboardLink = styled.span`
  color: ${styledTheme.colors.action_link};
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
`
export const SiteStatusPopupDateUpdated = styled.h3`
  margin-top: 0;
`
export const SiteStatusPopupBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
  padding: 4px;
  overflow-y: scroll;
`
export const SiteStatusPopupMetricCell = styled.div`
`
export const SiteStatusPopupMetricCellInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.4em;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ADC0BE;
  background-color: #FFF;
  padding: 0.8em 0.4em;
  height: 140px;
  ${({faded}) => {
    if (faded) {
      return `background-color: unset; \
        color: #999;`
    }
  }}
`
export const SiteStatusPopupMetricLabel = styled.div`
  text-align: center;
  height: 30px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const SiteStatusPopupMetricUnit = styled.div`
  text-align: center;
  height: 1.2em;
  color: #666;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 600;
`
export const SiteStatusPopupMetricValue = styled.div`
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 3em;
  font-weight: 700;
  flex: 1;
`
export const SiteStatusPopupWindContainer = styled.div`
  margin-left: 0.6em;
  margin-top: 0.2em;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
`
export const SiteStatusPopupWindIcon = styled.div`
  height: 18px;
  width: 18px;
  background-image: url(${getDynamicAssetHostPoolAddress()}/icons/up-arrow-circular-button.png?rotation=${(props) => props.angle });
  background-repeat: no-repeat;
  background-size: cover;
`
export const SiteStatusPopupWindLabel = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 0.3em;
  font-size: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
`
export const SiteStatusPopupMetricIndicatorBoxRow = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
`
export const SiteStatusPopupMetricIndicatorBox = styled.div`
  width: 50px;
  height: 8px;
  background-color: ${({backgroundColor}) => backgroundColor};
  border: 1px solid #EDEDED;
`
export const SiteNameHoverPopupContainer = styled.div`
  position: absolute;
  border: 1px solid black;
  background-color: white;
  font-size: 12px;
  transform: translateX(-50%) translateY(-46px);
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  z-index: 180;
  box-shadow: 2px 2px 2px 0px #333;
`
export const LegendContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: ${({isIOS}) => isIOS ? `20px` : `60px`};
  right: 10px;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  max-width: 300px;
  padding: 10px;
  font-size: 12px;
`
export const OptionsPanelContainer = styled.div`
  position: relative;
  background-color: #FFF;
  padding: 10px;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 20px;
`
export const OptionsPanelCloseIcon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 12px;
  top: 12px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  background-image: url(${closeIcon});
  background-size: cover;
  cursor: pointer;
`

export const GrowingSeasonSettingsPanelContainer = styled.div`
  position: relative;
  background-color: #FFF;
  padding: 10px;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  max-height: 70%;
`

export const GrowingSeasonSettingsPanelContainerInner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: scroll;
`

export const GrowingSeasonSettingsPanelCloseIcon = styled(OptionsPanelCloseIcon)`
  position: absolute;
`

export const SiteStatusPopupAdContainer = styled.div`
  padding: 4px;
  ${({top, bottom}) => {
    if (top) {
      return 'padding-bottom: 0;'
    }
    if (bottom) {
      return 'padding-top: 0;'
    }
  }}
`

export const SiteStatusPopupLink = styled.a`
  text-decoration: none;
`

export const SiteStatusPopupAd = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.4em;
  border-radius: 3px;
  border: 1px solid #ADC0BE;
  background-color: #FFF;
  padding: 0.8em 0.4em;
  width: calc(100%-0.4em);
  ${({top, bottom}) => {
    if (top) {
      return 'margin-bottom: 0;'
    }
    if (bottom) {
      return 'margin-top: 0;'
    }
  }}
`

export const SiteStatusPopupAdHeaderSponsoredMsg = styled.div`
  font-size: 0.7rem;
  color: #666;
  margin-bottom: 0.2rem;
`

export const SiteStatusPopupAdHeadlineTxt = styled.div`
  font-size: 1rem;
  color: #349eeb;
  margin-bottom: 0.4rem;
  text-decoration: underline;
`

export const SiteStatusPopupAdBodyTxt = styled.div`
  font-size: 0.8rem;
  color: #333;
  margin-bottom: 0.4rem;
`

export const SiteStatusPopupAdReadMore = styled.div`
  font-size: 0.8rem;
  color: #349eeb;
  text-decoration: underline;
`
