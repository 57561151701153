import React from 'react'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import { SitesListControlContainer, GridContainer, GridSection, Column, ListHeaderRow, ListRow, ColumnStyle, SmallScreenSiteDataColumnStyle,
  SmallScreenSiteDataSiteName, SmallScreenSiteDataDetail, ActionHeaderColumnStyle, ActionColumnStyle,
  ActionColumnStyleRightAligned, ActionIconContainer, UpdatingIndicatorSpan, UpdatingIndicatorEllipsisContainer,ActionButton } from '../styled-elements'
import styledTheme from '../../../styles/theme'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import Moment from 'moment-timezone'

const UpdatingIndicator = ({isMobileView}) => <UpdatingIndicatorSpan isMobileView={isMobileView}>
    Updating
    <UpdatingIndicatorEllipsisContainer />
  </UpdatingIndicatorSpan>

export default class GrowingSeasonsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      layoutWidth: this.getLayoutWdth(),
    }
  }

  getLayoutWdth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.innerWidth
  }

  resizeHandler = (e) => {
    if (e.target.innerWidth===this.state.layoutWidth) return
    this.setState({ layoutWidth: this.getLayoutWdth() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate() {
  }

  formatDateUpdated = (lastUpdated,timezone) => {
    const dateUpdatedMoment = Moment.tz(lastUpdated,timezone)
    const nowMoment = Moment.tz(new Date(),timezone)
    const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
    const diffDays = diffDuration ? diffDuration.asDays() : 0
    const diffHours = diffDuration ? diffDuration.asHours() : 0
    const diffMins = diffDuration ? diffDuration.asMinutes() : 0

    let updatedAtRelativeStr = ''

    if (diffHours < 2) {
      if (diffMins < 1) {
        updatedAtRelativeStr = `less than a minute ago`
      } else if (diffMins < 2) {
        updatedAtRelativeStr = `a minute ago`
      } else {
        updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
      }
    } else if (diffHours < 48) {
      updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
    }

    let updatedAtTimeStr = dateUpdatedMoment.format("HH:mm")
    if ((nowMoment.format("DD MMM YYYY")!==dateUpdatedMoment.format("DD MMM YYYY")) && (diffHours >= 24)) {
      updatedAtTimeStr += ` on ${dateUpdatedMoment.format("DD MMM")}`
      if (nowMoment.format("YYYY")!==dateUpdatedMoment.format("YYYY")) {
        updatedAtTimeStr += ` on ${dateUpdatedMoment.format("YYYY")}`
      }
    }

    if (diffHours < 240) {
      updatedAtTimeStr += ` (${updatedAtRelativeStr})`
    }

    return `${updatedAtTimeStr}`
  }

  isMobileView = () => {
    const screenWidth = window.innerWidth
    return (screenWidth < styledTheme.breakpoints.md)
  }

  renderSitesHeaderRow = () => <ListHeaderRow key={0}>
    <Column xs={5} style={ColumnStyle}>Site Name</Column>
    <Column xs={5} style={ColumnStyle}>Data Last Updated</Column>
    <Column xs={2} style={ActionHeaderColumnStyle}>View</Column>
  </ListHeaderRow>

  renderSmallScreenSiteRows = (growingSeasons,growingLocations) => growingSeasons.map((growingSeason,index) => <ListRow key={index+1}>
    <Column xs={8} style={SmallScreenSiteDataColumnStyle} onClick={this.props.rowClickHandler.bind(this,growingSeason)}>
        <SmallScreenSiteDataSiteName>{growingSeason.name}</SmallScreenSiteDataSiteName>
        <SmallScreenSiteDataDetail>{growingSeason.last_updated_at ? `${this.formatDateUpdated(growingSeason.last_updated_at, growingSeason.growing_location_timezone_name)}` : 'No data retrieved yet'} {growingSeason.data_processing ? <UpdatingIndicator isMobileView={true} /> : ''}</SmallScreenSiteDataDetail>
    </Column>
    <Column xs={4} style={ActionColumnStyleRightAligned}>
      <ActionButton onClick={this.props.deleteClickHandler.bind(this,growingSeason)}>Delete</ActionButton>
      <ActionButton onClick={this.props.editClickHandler.bind(this,growingSeason,growingLocations)}>Edit</ActionButton>
      <ActionIconContainer onClick={this.props.rowClickHandler.bind(this,growingSeason)}><ArrowRightBoldCircle /></ActionIconContainer>
    </Column>
  </ListRow>)

  renderLargeScreenSiteRows = (growingSeasons,growingLocations) => growingSeasons.map((growingSeason,index) => <ListRow key={index+1}>
    <Column xs={5} style={ColumnStyle} onClick={this.props.rowClickHandler.bind(this,growingSeason)}>{growingSeason.name}</Column>
    <Column xs={5} style={ColumnStyle} onClick={this.props.rowClickHandler.bind(this,growingSeason)}>{growingSeason.last_updated_at ? this.formatDateUpdated(growingSeason.last_updated_at, growingSeason.growing_location_timezone_name) : 'No data retrieved yet'} {growingSeason.data_processing ? <UpdatingIndicator isMobileView={false} /> : ''}</Column>
    <Column xs={2} style={ActionColumnStyle}>
      <ActionButton onClick={this.props.deleteClickHandler.bind(this,growingSeason)}>Delete</ActionButton>
      <ActionButton onClick={this.props.editClickHandler.bind(this,growingSeason,growingLocations)}>Edit</ActionButton>
      <ActionIconContainer onClick={this.props.rowClickHandler.bind(this,growingSeason)}><ArrowRightBoldCircle /></ActionIconContainer>
    </Column>
  </ListRow>)

  renderGrowingSeasonsList(growingSeasons,growingLocations,sortParam) {
    const isMobileView = this.isMobileView()
    if (growingSeasons) {
      return <GridContainer>
        <ContainerHeading title={`My Growing Seasons`} />
        <GridSection>
          { isMobileView && this.renderSmallScreenSiteRows(growingSeasons,growingLocations) }
          { !isMobileView && this.renderSitesHeaderRow() }
          { !isMobileView && this.renderLargeScreenSiteRows(growingSeasons,growingLocations) }
        </GridSection>
      </GridContainer>
    }
  }

  renderBody = (props,state) => <div>
    {this.renderGrowingSeasonsList(props.growingSeasons.list,props.growingLocations.list)}
  </div>

  render = () => this.renderBody(this.props,this.state)

}
