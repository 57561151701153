import React from 'react'
import { connect } from 'react-redux'
import { signOut, acceptInvitationAndCompleteSignup } from '../../actions/AuthActions'
import CompleteSignupForm from '../../components/auth/CompleteSignupForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'

class CompleteSignup extends React.Component {

  handleSubmit = ({ invitationToken, firstName, surname, phone, email, password }) => {
    window.scrollTo(0,window.innerHeight)
    localStorage.setItem('uid', email)
    this.props.dispatch(acceptInvitationAndCompleteSignup({
      invitation_token: invitationToken,
      first_name: firstName,
      surname: surname,
      phone: phone,
      email: email,
      password: password,
    }))
  }

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Complete Signup'} />
    <CompleteSignupForm
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
      submitHandler={this.handleSubmit}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
  }),
)(CompleteSignup)
