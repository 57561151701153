import React from 'react'
import { Link } from 'react-router-dom'
import { signIn } from '../../actions/AuthActions'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, LinkContainer, SubmitWrapper } from './styled-elements'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '../../styles/styled-elements'
import parse from 'html-react-parser'

export default class LoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: localStorage.getItem('uid') || '',
      password: null,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiAuth.errors !== prevProps.apiAuth.errors) {
      if (this.props.apiAuth.errors != null && this.props.apiAuth.errors.length > 0) {
        this.props.apiAuth.errors[0] = this.props.apiAuth.errors[0].replace("Sign Up", "<a href='./signup'>Sign Up</a>")
        this.setState({
          formErrors: this.props.apiAuth.errors[0]
        })
      } else {
        this.setState({
          formErrors: ""
        })
      }
    }
  }

  validate = (callback) => {
    const errors = {}
    const { email, password } = this.state
    if (!email) {
      errors.email = 'Email address is required'
    }
    if (!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.email = 'Must be a valid email address'
    }
    if (!password) {
      errors.password = 'Password is required'
    }
    if (password.length < 6) {
      errors.password = 'Password is too short'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { email, password } = this.state
    this.validate(() => { this.props.dispatch(signIn({email, password})) })
  }

  renderBody = (props,state) => <LoginContainer>
    <LoginInner>
      <LoginHeading>Log Into COtL Mesonet</LoginHeading>
      <Form onSubmit={this.handleSubmit}>
        {this.state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{parse(this.state.formErrors)}</FormHelperText>
        }
        <FieldsWrapper>
          <FieldDiv>
            <TextField
              label="Email"
              name="email"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'none',
                autoComplete: 'email',
              }}
              value={this.state.email || ''}
              onChange={(e) => this.setState({email: e.target.value.trim()})}
              error={state.errors && state.errors.email}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.email}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Password"
              name="password"
              type="password"
              inputProps={{
                autoComplete: 'current-password',
              }}
              value={this.state.password || ''}
              onChange={(e) => this.setState({password: e.target.value})}
              error={state.errors && state.errors.password}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.password}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <Button primary rounded>Log in</Button>
        </SubmitWrapper>
      </Form>
      <LinkContainer>
        <Link to={'/signup'}>Sign Up</Link> | <Link to={'/password_reset'}>Reset Password</Link> | <Link to={'/'}>Home</Link>
      </LinkContainer>
    </LoginInner>
  </LoginContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
