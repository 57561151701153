import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton, CheckListItemCell, CheckListLabelCell, CheckListStatusCell } from '../../../components/my/styled-elements'
import CheckCircle from '../../../components/icons/CheckCircle'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'

class AccountSetupChecklist extends React.Component {

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  renderAccountSetupChecklist(user) {
    const { company } = user
    if (!company) return null
    const hasCompanyDetails = company.has_company_details
    const hasBillingPlan = company.has_billing_plan
    const hasPaymentMethod = company.has_payment_method
    const hasUserSurveyResponse = company.has_user_survey_response
    const hasBillingDiscountCodeUse = company.has_billing_discount_code_use
    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`Account Setup Checklist`} />
        <WhitePanel>
          <CheckListItemCell>
            <CheckListLabelCell><Link to={'/my/account/company_details'} style={{ color: 'black' }}>Enter Account Details</Link></CheckListLabelCell>
            <CheckListStatusCell>{hasCompanyDetails ? <Link to={'/my/account/company_details'} style={{ height: '100%', width: '100%', alignItems: 'center' }}><div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CheckCircle /></div></Link> : <Link to={'/my/account/company_details'}>Complete now...</Link>}</CheckListStatusCell>
          </CheckListItemCell>
          <CheckListItemCell>
            <CheckListLabelCell><Link to={'/my/account/plan_and_extras'} style={{ color: 'black' }}>Select a Billing Plan</Link></CheckListLabelCell>
            <CheckListStatusCell>{hasBillingPlan ? <Link to={'/my/account/plan_and_extras'} style={{ height: '100%', width: '100%', alignItems: 'center' }}><div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CheckCircle /></div></Link> : <Link to={'/my/account/plan_and_extras'}>Complete now...</Link>}</CheckListStatusCell>
          </CheckListItemCell>
          <CheckListItemCell>
            <CheckListLabelCell><Link to={'/my/account/payment_details'} style={{ color: 'black' }}>Enter Payment Details</Link></CheckListLabelCell>
            <CheckListStatusCell>{hasPaymentMethod ? <Link to={'/my/account/payment_details'} style={{ height: '100%', width: '100%', alignItems: 'center' }}><div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CheckCircle /></div></Link> : <Link to={'/my/account/payment_details'}>Complete now...</Link>}</CheckListStatusCell>
          </CheckListItemCell>
          <CheckListItemCell>
            <CheckListLabelCell><Link to={'/my/account/user_survey'} style={{ color: 'black' }}>Complete User Survey (optional)</Link></CheckListLabelCell>
            <CheckListStatusCell>{hasUserSurveyResponse ? <Link to={'/my/account/user_survey'} style={{ height: '100%', width: '100%', alignItems: 'center' }}><div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CheckCircle /></div></Link> : <Link to={'/my/account/user_survey'}>Complete now...</Link>}</CheckListStatusCell>
          </CheckListItemCell>
          <CheckListItemCell>
            <CheckListLabelCell><Link to={'/my/account/start_trial_or_billing'} style={{ color: 'black' }}>Start Trial or Billing</Link></CheckListLabelCell>
            <CheckListStatusCell>{hasBillingDiscountCodeUse ? <Link to={'/my/account/start_trial_or_billing'} style={{ height: '100%', width: '100%', alignItems: 'center' }}><div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CheckCircle /></div></Link> : <Link to={'/my/account/start_trial_or_billing'}>Complete now...</Link>}</CheckListStatusCell>
          </CheckListItemCell>
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    return <div>
      { this.renderAccountSetupChecklist(props.apiAuth.user) }
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'Account Setup Checklist'} />
    { this.renderBody(this.props,this.state) }
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(AccountSetupChecklist)
