import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown/with-html'

import SiteLayout from '../../containers/layout/SiteLayout'
import { InnerContentContainer } from '../../styles/styled-elements'
import { InfoAlertsShowTopHeading, InfoAlertsH1 } from './styled-elements'
import LoadingScreen from '../../components/LoadingScreen'
import HelmetTitle from '../../components/layout/HelmetTitle'

import { fetchInfoAlerts, updateReadItems } from '../../actions/InfoAlertActions'

class InfoAlertsIndex extends React.Component {

  constructor(props) {
    super(props)
    const { path } = props.match
    this.state = {
      contentId: path.replace(/^\//,'')
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(fetchInfoAlerts())
  }

  getContent() {
    const { infoAlert, fetching, dispatch } = this.props
    if (!infoAlert) return <LoadingScreen show={fetching} />
    dispatch(updateReadItems([infoAlert.id_content_updated_at_str]))
    const { title, body } = infoAlert
    return <div>
      {/* <InfoAlertsShowTopHeading><Link to={'/info_alerts'}>News &amp; Alerts</Link></InfoAlertsShowTopHeading> */}
      <HelmetTitle title={`${title} – News & Alerts`} />
      <LoadingScreen show={fetching} />
      <InfoAlertsShowTopHeading><Link to={'/info_alerts'}>&#171; News &amp; Alerts</Link></InfoAlertsShowTopHeading>
      <InfoAlertsH1>{title}</InfoAlertsH1>
      <ReactMarkdown
        source={body}
        escapeHtml={false}
      />
    </div>
  }

  renderBody = (props,state) => <SiteLayout>
    <InnerContentContainer>
      {this.getContent()}
    </InnerContentContainer>
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {
    const { match } = props
    const { params } = match
    return ({
      infoAlert: store.infoAlerts.list.find(({id,published_at_str,slug}) => (published_at_str===params.date_str) && (slug===params.slug)),
      fetching: store.infoAlerts.fetching,
    })
  },
)(InfoAlertsIndex)
