import styled, { keyframes } from 'styled-components'
import { H1, GridContainer, ContainerHeading, GridSection, Row, Column, ActionLink } from '../../styles/styled-elements'
import styledTheme from '../../styles/theme'

export { H1, GridContainer, ContainerHeading, GridSection, Row, Column, ActionLink, styledTheme }

export const SitesListControlContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
`
export const ListHeaderRow = styled(Row)`
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
  font-weight: 500;
  @media (max-width: ${styledTheme.breakpoints.md-1}px) {
    display: none;
  };
`
export const ListRow = styled(Row)`
  border-bottom: 1px solid ${styledTheme.colors.grid_row_divider};
  cursor: pointer;
  &:nth-child(even) {
    background-color: #EDEDED;
  }
  &:last-child {
    border-bottom: none;
  }
  ${({inactive}) => {
    if (inactive) {
      return `
        cursor: default;
        color: #AAAAAA;
      `
      }
    }
  }
`

export const ColumnStyle = {
  padding: '10px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '0.875rem',
}
export const SmallScreenSiteDataColumnStyle = {
  ...ColumnStyle,
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}
export const SmallScreenSiteDataSiteName = styled.div`
  font-weight: 500;
  font-size: 1.2em;
`
export const SmallScreenSiteDataDetail = styled.div`
  font-size: 0.9em;
`
export const ActionHeaderColumnStyle = {
  ...ColumnStyle,
  justifyContent: 'flex-end'
}
export const ActionColumnStyle = {
  ...ColumnStyle,
  justifyContent: 'flex-end',
}
export const ActionColumnStyleRightAligned = {
  ...ColumnStyle,
  padding: '0',
  paddingRight: '10px',
  justifyContent: 'flex-end'
}
export const ActionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  svg {
    fill: ${styledTheme.colors.action_link};
    height: 30px;
    width: 30px;
  }
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    height: 24px;
    width: 24px;
  };
`
export const ActionIcon = styled.div`
  display: inline-flex;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
`
export const ColumnResponsiveInnerContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${styledTheme.breakpoints.md-1}px) {
    min-height: 40px;
    font-size: 1rem;
    font-weight: 500;
  };
`
export const DataViewIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  svg {
    fill: ${styledTheme.colors.data_view_icon_grey};
    height: 26px;
    width: 26px;
  }
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    height: 20px;
    width: 20px;
  };
`
export const UpdatingIndicatorSpan = styled.span`
  font-weight: 500;
  color: #FFF;
  background-color: #AAA;
  display: inline-flex;
  ${({isMobileView}) => {
    if (isMobileView) {
      return `
        font-size: 0.5rem;
        border-radius: 0.3em;
        padding: 0.3em 0.4em 0.4em 0.5em;
        margin-left: 0.5em;
      `
    } else {
      return `
        font-size: 0.6rem;
        border-radius: 0.4em;
        padding: 0.4em 0.5em 0.5em 0.6em;
        margin-left: 1em;
      `
    }
  }}
`
const ellipsis = keyframes`
  to {
    width: 100%;
  }
`
export const UpdatingIndicatorEllipsisContainer = styled.div`
  display: inline-flex;
  opacity: 1;
  font-size: 1em;
  width: 1.2em;
  min-width: 1em;
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\\2026';
    animation: ${ellipsis} steps(4,end) 2s infinite;
    width: 0px;
  }
`
export const NominatedSiteIndicatorSpan = styled.span`
  padding: 2px 4px;
  background-color: #FFAA2E;
  color: #333;  text-decoration: none;
  cursor: pointer;
  font-size: 0.8em;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 20px;
`
export const GraphDurationIndicatorSpan = styled.span`
  padding: 2px 4px;
  background-color: ${styledTheme.colors.button_background};
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8em;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 20px;
  ${({allHistory}) => {
    if (allHistory) {
      return `background-color: #FFAA2E;\n
        color: #333;`
    }
  }}
`
