import Environment from './environment'

export function getDynamicAssetHostPoolAddress() {

  const dynamicAssetHostPoolTemplate = Environment.DYNAMIC_ASSET_HOST_POOL_TEMPLATE
  if (!dynamicAssetHostPoolTemplate) return Environment.DYNAMIC_ASSET_HOST;
  const match = dynamicAssetHostPoolTemplate.match(/(?:\[(\d)-(\d)\])/)
  if (!match) return Environment.DYNAMIC_ASSET_HOST;
  const rangeStart = parseInt(match[1])
  const rangeEnd = parseInt(match[2])
  if (!(rangeStart > 0) || !(rangeEnd > 0)) return Environment.DYNAMIC_ASSET_HOST;
  const randNumInRange = Math.floor(Math.random() * ( rangeEnd - rangeStart + 1)) + rangeStart
  const assetAddress = dynamicAssetHostPoolTemplate.replace(/\[\d-\d\]/,randNumInRange.toString())
  return assetAddress

}

