const prevSiteLocationsHashKey = "siteLocationsHash";
const curSiteLocationsHashKey = "siteLocationsHashV2";

if(localStorage.getItem(prevSiteLocationsHashKey)!==null){
  localStorage.removeItem(prevSiteLocationsHashKey)
}

try {
  JSON.parse(localStorage.getItem(curSiteLocationsHashKey))
} catch (e) {
  localStorage.removeItem(curSiteLocationsHashKey)
}

const initialState = {
  site: {},
  siteSummary: {},
  sites: [],
  siteLocationsHash: JSON.parse(localStorage.getItem(curSiteLocationsHashKey)) || {},
  fetching: false,
  fetched: false,
  errors: null,
  saved: false,
  saving: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "PUBLIC_SITES_FETCH_PENDING":
    case "MEMBERS_SITES_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: null,
      }
    case "PUBLIC_SITES_FETCH_REJECTED":
    case "MEMBERS_SITES_FETCH_REJECTED":
      return {
        ...state,
        fetching: false,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
      }
    case "PUBLIC_SITES_FETCH_FULFILLED":
    case "MEMBERS_SITES_FETCH_FULFILLED":
      let siteLocationsHash = state.siteLocationsHash
      const siteLocationsDifferent = ((Object.keys(siteLocationsHash).length !== action.payload.data.length) ||
        (Object.keys(siteLocationsHash).length && typeof Object.keys(siteLocationsHash)[0]==='number' ) ||
        (Object.keys(siteLocationsHash).length && typeof Object.keys(siteLocationsHash)[0]==='string' && Object.keys(siteLocationsHash)[0].length < 4 )
      )
      const newSiteLocationsHash = {}
      let newSiteLocationsHashPresent = false
      if (siteLocationsDifferent) {
        newSiteLocationsHashPresent = true
        action.payload.data.forEach((site) => {
          newSiteLocationsHash[site.slug] = site.location
        })
        localStorage.setItem(curSiteLocationsHashKey,JSON.stringify(newSiteLocationsHash))
        siteLocationsHash = newSiteLocationsHash
      }
      const sites = action.payload.data.slice()
      sites.forEach((site) => {
        site.location = siteLocationsHash[site.slug]
      })
      return {
        ...state,
        fetching: false,
        fetched: true,
        sites: sites,
        errors: null,
      }
    case "SITE_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        site: null
      }
    case "SITE_FETCH_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        site: action.payload.data
      }
    case  'USER_SIGN_OUT_PENDING':
      return {
        ...initialState
      }
    case 'USER_SIGN_OUT_FULFILLED':
      return {
        ...initialState
      }
    default:
      return state
  }
}


