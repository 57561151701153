import React from 'react'
import { ContainerHeading, ContainerHeadingTextDiv, ContainerHeadingActionIconsDiv } from '../../styles/styled-elements'

const DataViewModalContainerHeading = ({ title, actionIcons }) => {
  return <ContainerHeading>
    <ContainerHeadingTextDiv>
      {title}
    </ContainerHeadingTextDiv>
    <ContainerHeadingActionIconsDiv>
      {actionIcons}
    </ContainerHeadingActionIconsDiv>
  </ContainerHeading>
}

export default DataViewModalContainerHeading
