import React from 'react'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { DateTime } from "luxon"
import styledTheme from '../../styles/theme'

import Menu from './Menu.js'
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'

import { ScreenWidthContainer } from '../../styles/styled-elements'
import { companyBillingRequired } from "../../utils/companyUtils";
import { showNotSignedInBanner } from "../../utils/userUtils";

const formatTrialExpiry = (freeTrialExpiresMs) => {

  const dateTime = DateTime.fromMillis(freeTrialExpiresMs-1).setZone("Australia/Adelaide")
  const dayStr = dateTime.toFormat('d MMM yyyy')
  const timeStr = dateTime.toFormat('hh:mm a')

  return `${dayStr} at ${timeStr}`

}

const formatBillingStart = (billingStartMs) => {

  const dateTime = DateTime.fromMillis(billingStartMs).setZone("Australia/Adelaide")
  const dayStr = dateTime.toFormat('d MMM yyyy')
  const timeStr = dateTime.toFormat('hh:mm a')

  return `${dayStr} at ${timeStr}`

}

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${window.innerHeight}px;
  width: 100%;
`
const MainContent = styled.div`
  background-color: rgba(221,225,231,0.5);
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 0px) {
    padding: ${({noPadding}) => noPadding ? '0' : '10'}px;
    padding-top: ${({noPadding}) => noPadding ? '0' : '16'}px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: ${({noPadding}) => noPadding ? '0' : '16'}px;
    padding-top: ${({noPadding}) => noPadding ? '0' : '20'}px;
  };
`

const getAccountSignupProgress = (apiAuth) => {

  const { user } = apiAuth
  const company = user && user.company

  if (!company) return { completed: false }

  const accountSignupProgressSteps = [{
    itemKey: 'hasCompanyDetails',
    completed: company.has_company_details,
  },{
    itemKey: 'hasBillingPlan',
    completed: company.has_billing_plan,
  },{
    itemKey: 'hasPaymentMethod',
    completed: company.has_payment_method,
  },{
    itemKey: 'hasUserSurveyResponse',
    completed: company.has_user_survey_response,
  },{
    itemKey: 'hasBillingDiscountCodeUse',
    completed: company.has_billing_discount_code_use,
  }]

  const completedStepCount = accountSignupProgressSteps.filter(({completed}) => completed===true).length
  const totalStepCount = accountSignupProgressSteps.length

  return {
    progressHash: accountSignupProgressSteps,
    totalStepCount,
    completedStepCount,
    complete: (completedStepCount===totalStepCount) || company.has_billing_discount_code_use,
  }

}

const RedirectToNewCompanyIfRemoved = ({apiAuth,dispatch}) => {
  if (!apiAuth || !apiAuth.isSignedIn) return null
  if (window.location.pathname.match(/create_new_company_account/)) return null
  const { user } = apiAuth
  if (!user) return null
  const { permissions } = user
  if (!permissions) return null
  if (!permissions.removal_created_at) return null
  setTimeout(() => {
    dispatch(push('/create_new_company_account'))
  },1000)
  return null
}

const InfoBanner = ({apiAuth}) => {

  const { user } = apiAuth
  const company = user && user.company
  const billingItemAccessPlan = company?.billing_item_access_plan
  const billingPlanProductName = billingItemAccessPlan ? billingItemAccessPlan.billing_product_name : null
  const billingPlanProductKey = billingItemAccessPlan ? billingItemAccessPlan.billing_product_key : null
  const hasBillingDiscountCodeUse = company?.has_billing_discount_code_use
  const billingDiscountCodeStatus = company?.billing_discount_code_status
  const billingDiscountCodeUserAcceptance = company?.billing_discount_code_user_acceptance
  const billingPlanFreeUntil = company?.billing_plan_free_until
  const billingPlanBillingNextBillableAt = company?.billing_plan_billing_next_billable_at
  const billingPlanBillingFirstBilledAt = company?.billing_plan_billing_first_billed_at
  const freeTrialDeclined = (hasBillingDiscountCodeUse && (billingDiscountCodeStatus === 'declined'))
  const billingStarted = billingPlanBillingFirstBilledAt ? (new Date(billingPlanBillingFirstBilledAt) < new Date()) : false
  const freeTrialInUse = (hasBillingDiscountCodeUse && (billingDiscountCodeStatus === 'in_use') && billingPlanFreeUntil && (new Date(billingPlanFreeUntil) > new Date()))
  const chargeAmountNumber = company?.total_yearly_recurring_ex_tax
  const chargeAmountStr = chargeAmountNumber ? `AUD $${chargeAmountNumber.toFixed(2)}` : ''
  const accountSignupProgress = getAccountSignupProgress(apiAuth)
  const userPermissions = (user?.permissions || {})
  const permissionAccountBilling = !!(userPermissions.owner || userPermissions.account_billing)
  const invoicesPaymentUnsuccessfulCount = (company?.invoices_payment_unsuccessful || []).length
  const paymentMethodDeclining = (company?.active_payment_method || {}).status==='declining'
  const noBillingRequired = !companyBillingRequired(company);

  const BarDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px;
    background-color: ${({bgColor}) => bgColor};
    font-weight: 500;
    font-size: 0.9rem;
    color: ${({textColor}) => textColor};
    text-shadow: 2px 2px 2px rgba(170,170,170,0.33);
    text-align: center;
    cursor: pointer;
  `

  const StyledLink = styled(Link)`
    text-decoration: none;
  `

  let infoText = ''
  let infoBannerLink = ''
  let infoBannerColor = ''
  let infoBannerTextColor = ''

  if (!apiAuth.isSignedIn && showNotSignedInBanner()) {
    infoText = `You are not logged in. Log in or Sign up now.`
    infoBannerLink = '/login'
    infoBannerColor = '#EBC034'
    infoBannerTextColor = '#666'
  }
  else if (noBillingRequired || !permissionAccountBilling) {
    return null;
  }
  else if (invoicesPaymentUnsuccessfulCount && paymentMethodDeclining) {
    const pluralisedInvoiceLabel = `invoice${(invoicesPaymentUnsuccessfulCount!==1) ? 's' : ''}`
    infoText = `Your credit card payment has been declined and you have ${invoicesPaymentUnsuccessfulCount} ${pluralisedInvoiceLabel} outstanding. You can click/tap here to enter a new credit card and pay the outstanding ${pluralisedInvoiceLabel}.`
    infoBannerLink = '/my/account/payment_details'
    infoBannerColor = '#FF2E38'
    infoBannerTextColor = '#FFF'
  } else if (billingStarted) {
    return null
  } else if (freeTrialInUse && billingPlanBillingNextBillableAt) {
    infoText = `You are currently trialling the ${billingPlanProductName} plan. Your billing commences at ${formatBillingStart(billingPlanBillingNextBillableAt)}. At that time you will be invoiced and charged ${chargeAmountStr}, unless you change your billing plan and extras before then.`
    infoBannerLink = '/my/account/plan_and_extras'
    infoBannerColor = '#EBC034'
    infoBannerTextColor = '#666'
  } else if (freeTrialDeclined && billingPlanBillingNextBillableAt) {
    infoText = `You have selected the ${billingPlanProductName} plan. Your billing commences at ${formatBillingStart(billingPlanBillingNextBillableAt)}. If you want to accept the two week free trial offer, click/tap here.`
    infoBannerLink = '/my/account/start_trial_or_billing'
    infoBannerColor = '#EBC034'
    infoBannerTextColor = '#666'
  } else if (billingItemAccessPlan && (billingPlanProductKey === 'free_access_1')) {
    infoText = `You have chosen the Free Access plan, which includes visibility of only Temperature at 1.2m and Wind Speed at 10m. You can click/tap here to upgrade to a paid plan for more data visibility at any time.`
    infoBannerLink = '/my/account/plan_and_extras'
    infoBannerColor = '#EBC034'
    infoBannerTextColor = '#666'
  } else if (!accountSignupProgress['complete']) {
    const { totalStepCount, completedStepCount } = accountSignupProgress
    infoText = `You have completed ${completedStepCount} of ${totalStepCount} steps in the account setup process. Please complete all steps to commence your two-week free trial. Limestone Coast and McLaren Vale sites will remain free until sometime in 2024.`
    infoBannerLink = '/my/account/account_setup_checklist'
    infoBannerColor = '#FF2E38'
    infoBannerTextColor = '#FFF'
  }

  return <StyledLink to={infoBannerLink}><BarDiv bgColor={infoBannerColor} textColor={infoBannerTextColor}>
    {infoText}
  </BarDiv></StyledLink>

}

const SiteContainer = ({ children, noPadding, dispatch, apiAuth, menuOpen, appSettings }) => {
  return <ScreenWidthContainer>
    <RedirectToNewCompanyIfRemoved
      apiAuth={apiAuth}
      dispatch={dispatch}
    />
    <Menu apiAuth={apiAuth} menuOpen={menuOpen} dispatch={dispatch} />
    <SiteWrapper>
      <SiteHeader
        apiAuth={apiAuth}
        dispatch={dispatch}
        appSettings={appSettings}
      />
      <InfoBanner
        apiAuth={apiAuth}
      />
      <MainContent noPadding={noPadding}>
        {children}
      </MainContent>
    </SiteWrapper>
  </ScreenWidthContainer>
}

export default SiteContainer
