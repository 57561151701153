import React from 'react'
import { Link } from 'react-router-dom'
import { FormInner, FormContainer, Form, FieldsWrapper, FieldDiv, UserFormH1, SubmitWrapper, FormInnerContentDiv, FormTextParagraph } from './styled-elements'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { RoundedFormButton } from '../../styles/styled-elements'
import { makeStyles } from '@material-ui/core/styles'
import queryString from 'query-string'

const FormElement = ({ formInputChangeHandler, handleSubmit, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
    })
  })

  const classes = useStyles()

  return <Form onSubmit={handleSubmit}>
    {state.formErrors &&
      <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
    }
    <FieldsWrapper show={(!state.addressSameAsPrimary)}>
      <FieldDiv>
        <TextField
          required
          variant="standard"
          label="Company/Organisation Name"
          name="companyName"
          inputProps={{
            autoCorrect: 'off',
            autoCapitalize: 'words',
            autoComplete: 'name',
          }}
          helperText="If none, enter your full name"
          value={state.companyName || ''}
          onChange={formInputChangeHandler}
          error={state.errors && state.errors.companyName}
          style={{ width: '100%', marginTop: 20 }}
          InputProps={{ className: classes.textFieldInput }}
          InputLabelProps={{ className: classes.textFieldInputLabel }}
        />
        <FormHelperText error>{state.errors && state.errors.companyName}</FormHelperText>
      </FieldDiv>
    </FieldsWrapper>
    <SubmitWrapper>
      <RoundedFormButton enabled={(state.canSubmit)}>Submit</RoundedFormButton>
    </SubmitWrapper>
  </Form>
}

export default class UserCreateNewCompanyAccountForm extends React.Component {

  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      companyName: '',
      newCompanyCreationComplete: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps,prevState) {

    const stateHash = {}

    const authErrors = this.props.apiAuth.errors
    if (Array.isArray(authErrors) && authErrors!==prevProps.apiAuth.errors) {
      const errorsHash = this.generateErrorsHash(authErrors)
      stateHash['errors'] = errorsHash
    }

    const { props, state } = this
    const { companyName } = this.state
    let canSubmit = false
    if (companyName) canSubmit = true
    if (canSubmit!==prevState.canSubmit) {
      stateHash['canSubmit'] = canSubmit
    }

    if (this.props!==prevProps) {
      const { apiAuth } = this.props
      const prevPropsAuth = prevProps.apiAuth
      const user = apiAuth ? apiAuth.user : null
      if (!user) return true
      const prevPropsUser = prevPropsAuth ? prevPropsAuth.user : null
      const permissions = user.permissions || {}
      const prevPermissions = prevPropsUser ? (prevPropsUser.permissions || {}) : {}
      if (!permissions.removal_created_at && prevPermissions.removal_created_at) {
        stateHash['newCompanyCreationComplete'] = true
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash,() => {
      })
    }
  }

  generateErrorsHash = authErrors => {

    const errorsHash = {}
    authErrors.forEach((field) => {
      let fieldMessage = ''
      let fieldName = ''
      let fieldLabel = ''
      switch (field.field_name) {
        case 'company_name':
          fieldName = 'companyName'
          fieldLabel = 'Company/Organisation Name'
          break
      }
      field.messages.forEach((message) => {
        if (message==='has already been taken') {
          fieldMessage += `${fieldLabel} is already in use. Please use a different ${fieldLabel}.`
        } else {
          fieldMessage += `${fieldLabel} ${message}.`
        }
      })
      if (fieldMessage) {
        errorsHash[fieldName] = fieldMessage
      }
    })
    return errorsHash
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  validate = (callback) => {
    const errors = {}
    const { companyName } = this.state
    if (!companyName) {
      errors.companyName = 'Company/Organisation Name is required'
    } else if ((companyName).length < 3) {
      errors.companyName = 'Company/Organisation Name is too short'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { companyName } = this.state
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const params = { companyName }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  getMainContent = (props,state) => {

    const { apiAuth } = props
    const { user } = apiAuth
    const company = user ? user.company : null

    if (!company) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You are not logged in.</FormTextParagraph>
        <FormTextParagraph><Link to={'/login'}>Login Page</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (state.newCompanyCreationComplete) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have successfully created a new company/organisation: {company.name}.</FormTextParagraph>
        <FormTextParagraph>You are the owner of this account.</FormTextParagraph>
        <FormTextParagraph>You should now proceed to the Company Details page to continue the Account Setup process.</FormTextParagraph>
        <FormTextParagraph><Link to={'/my/account/company_details'}>Company Settings</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    const removalCreatedAt = user ? ( user.permissions ? user.permissions.removal_created_at : null ) : null

    if (removalCreatedAt) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have been removed from the company profile for {company.name}.</FormTextParagraph>
        <FormTextParagraph>To continue using COtL Mesonet, you need to create a new company account.</FormTextParagraph>
        <FormTextParagraph>To do this, please enter a new company name and press "Submit".</FormTextParagraph>
        <FormElement
          state={state}
          formInputChangeHandler={this.formInputChangeHandler}
          handleSubmit={this.handleSubmit}
        />
      </FormInnerContentDiv>
    }

    return <FormInnerContentDiv>
      <FormTextParagraph>You don't need to use this feature right now.</FormTextParagraph>
      <FormTextParagraph><Link to={'/'}>Return to home page</Link></FormTextParagraph>
    </FormInnerContentDiv>

  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { user } = apiAuth
    const company = user ? user.company : null
    return <FormContainer>
      <FormInner>
        <UserFormH1>Create a new company account</UserFormH1>
        {this.getMainContent(props,state)}
      </FormInner>
    </FormContainer>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
