import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { validateToken } from '../../../actions/AuthActions'
import { updateBillingDiscountCodeUse } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'
import { RoundedFormButton, RoundedOutlineButton } from '../../../styles/styled-elements'
import { DateTime } from "luxon"

const FreeTrialFormElement = ({ chargeAmountExTaxStr, chargeAmountIncTaxStr, submitHandler, setDisplayStateHandler }) => {

  const formSubmitHandler = (e) => {
    e.preventDefault()
    submitHandler.call(this,true)
  }

  const cancel = () => {
    setDisplayStateHandler.call(this,'showStartFreeTrialConfirmForm',false)
  }

  return <div>
    <h3>Option 1: Start Free Trial</h3>
    <p>Click the button below to confirm you want to your two-week free trial. </p>
    <p>You can change your billing plan and nominated AWSs during that time.</p>
    <p>When your trial ends, <strong>you will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST)</strong>, unless you change your billing plan and extras before then.</p>
    <form onSubmit={formSubmitHandler}>
      <RoundedOutlineButton enabled onClick={cancel.bind(this)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
      <RoundedFormButton enabled name="Start" type="submit">Start Free Trial</RoundedFormButton>
    </form>
  </div>
}

const FreeTrialOptionInfoElement = ({ chargeAmountExTaxStr, chargeAmountIncTaxStr, showConfirmFormHandler }) => <div>
  <h3>Option 1: Start Free Trial</h3>
  <p>Click the button below to start your two-week free trial.</p>
  <p>You can change your billing plan and nominated AWSs during that time. You will be invoiced and charged for your selected plan and nominated AWSs when your trial period ends.</p>
  <p>When your trial ends, <strong>you will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST)</strong>, unless you change your billing plan and extras before then.</p>
  <RoundedFormButton enabled onClick={showConfirmFormHandler.bind(this,'showStartFreeTrialConfirmForm',true)}>Start Free Trial</RoundedFormButton>
</div>

const FreeTrialOptionStepsNotCompletedElement = () => <div style={{ marginTop: 30 }}>
  <h3>Option 1: Start Free Trial</h3>
  <p>You have not completed the steps required to be eligible for the free trial.</p>
  <p>Please visit the <Link to={"/my/account/account_setup_checklist"}>Account Setup Checklist</Link> to complete the required steps.</p>
</div>

const StartBillingFormElement = ({ chargeAmountExTaxStr, chargeAmountIncTaxStr, submitHandler, setDisplayStateHandler }) => {

  const formSubmitHandler = (e) => {
    e.preventDefault()
    submitHandler.call(this,false)
  }

  const cancel = () => {
    setDisplayStateHandler.call(this,'showStartBillingImmediatelyConfirmForm',false)
  }

  return <div>
    <h3>Option 2: Start Billing Immediately</h3>
    <p>Click the button below to start billing immediately.</p>
    <p><strong>You will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST)</strong>.</p>
    <form onSubmit={formSubmitHandler}>
      <RoundedOutlineButton enabled onClick={cancel.bind(this)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
      <RoundedFormButton enabled name="Start" type="submit">Start Billing and Pay {chargeAmountIncTaxStr} Now</RoundedFormButton>
    </form>
  </div>

}

const StartBillingOptionInfoElement = ({ chargeAmountExTaxStr, chargeAmountIncTaxStr, showConfirmFormHandler }) => <div style={{ marginTop: 60 }}>
  <h3>Option 2: Start Billing Immediately</h3>
  <p>Click the button below to start billing immediately.</p>
  <p><strong>You will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST)</strong> in the next few days.</p>
  <RoundedFormButton enabled onClick={showConfirmFormHandler.bind(this,'showStartBillingImmediatelyConfirmForm',true)}>Start Billing Immediately</RoundedFormButton>
</div>


const StartBillingOptionStepsNotCompletedElement = () => <div style={{ marginTop: 60 }}>
  <h3>Option 2: Start Billing Immediately</h3>
  <p>You have not completed the steps required to be ready to start billing.</p>
  <p>Please visit the <Link to={"/my/account/account_setup_checklist"}>Account Setup Checklist</Link> to complete the required steps.</p>
</div>

const formatTrialExpiry = (freeTrialExpiresMs) => {

  const dateTime = DateTime.fromMillis(freeTrialExpiresMs-1).setZone("Australia/Adelaide")
  const dayStr = dateTime.toFormat('d MMM yyyy')
  const timeStr = dateTime.toFormat('hh:mm a')

  return `${dayStr} at ${timeStr}`

}

const formatBillingStart = (billingStartMs) => {

  const dateTime = DateTime.fromMillis(billingStartMs).setZone("Australia/Adelaide")
  const dayStr = dateTime.toFormat('d MMM yyyy')
  const timeStr = dateTime.toFormat('hh:mm a')

  return `${dayStr} at ${timeStr}`

}

const formatChangeBlanBefore = (billingStartMs) => {

  const dateTime = DateTime.fromMillis(billingStartMs-1).setZone("Australia/Adelaide")
  const dayStr = dateTime.toFormat('d MMM yyyy')
  const timeStr = dateTime.toFormat('hh:mm a')

  return `${timeStr} on ${dayStr}`

}

const AcceptedFreeTrialInfoElement = ({hasBillingDiscountCodeUse, billingDiscountCodeStatus, billingPlanFreeUntil, chargeAmountExTaxStr, chargeAmountIncTaxStr, setDisplayStateHandler }) => <div>
  <h3>Your free trial is in progress</h3>
  <p>Your trial expires on {formatTrialExpiry(billingPlanFreeUntil)}.</p>
  <p>You can change plans as often as you like during the trial period.</p>
  <p>When your trial ends, you will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST), unless you change your billing plan and extras before then.</p>
  <p><strong>If you don't want to be charged, please visit the <Link to={'/my/account/plan_and_extras'}>Plan and Extras</Link> page and change to the Free Access plan before {formatChangeBlanBefore(billingPlanFreeUntil)}.</strong></p>
  <p style={{ marginBottom: 30 }}>Many thanks for completing the Mesonet account setup process!</p>
  <h3>Changed your mind?</h3>
  <p>If you would like to end your trial and start billing immediately, click the button below.</p>
  <RoundedFormButton enabled onClick={setDisplayStateHandler.bind(this,'showStartBillingImmediatelyConfirmForm',true)}>Start Billing Immediately</RoundedFormButton>
</div>

const DeclinedFreeTrialInfoElement = ({hasBillingDiscountCodeUse, billingDiscountCodeStatus, billingPlanBillingNextBillableAt, chargeAmountExTaxStr, chargeAmountIncTaxStr, setDisplayStateHandler }) => <div>
  <h3>Your billing has started</h3>
  <p>You have declined the free trial.</p>
  <p>Your billing has started.</p>
  <p><strong>You will be invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST) shortly.</strong></p>
  <p style={{ marginBottom: 30 }}>Many thanks for completing the Mesonet account setup process!</p>
</div>

const CompletedFreeTrialInfoElement = ({hasBillingDiscountCodeUse, billingDiscountCodeStatus, billingPlanBillingNextBillableAt, chargeAmountExTaxStr, chargeAmountIncTaxStr, setDisplayStateHandler }) => <div>
  <h3>Your billing has started</h3>
  <p>You trial period has ended and your billing has started.</p>
  <p><strong>You have been invoiced and charged {chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST).</strong></p>
  <p style={{ marginBottom: 30 }}>Many thanks for completing the Mesonet account setup process!</p>
</div>

class StartTrialOrBilling extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showStartFreeTrialConfirmForm: false,
      showStartBillingImmediatelyConfirmForm: false
    }
  }

  componentDidMount(prevProps) {
    this.props.dispatch(validateToken())
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps!==this.props) {
      const { user } = this.props.apiAuth
      const prevUser = prevProps.apiAuth.user
      const company = user && user.company
      const prevCompany = prevUser && prevUser.company
      if (company && prevCompany) {
      }
      if (company && prevCompany) {
        if (company.billing_discount_code_status!==prevCompany.billing_discount_code_status) {
          this.setState({
            showStartFreeTrialConfirmForm: false,
            showStartBillingImmediatelyConfirmForm: false
          })
        }
      }
    }
  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  setDisplayState = (displayKey,visible) => {
    if (!['showStartFreeTrialConfirmForm','showStartBillingImmediatelyConfirmForm'].includes(displayKey)) return true
    if (![true,false].includes(visible)) return true
    const stateHash = {}
    stateHash[displayKey] = visible
    this.setState(stateHash)
  }

  handleSubmit = (userAcceptance) => {
    const { dispatch } = this.props
    const params = { user_acceptance: userAcceptance }
    dispatch(updateBillingDiscountCodeUse(params))
  }

  getPanelContent = (state, company) => {

    const hasCompanyDetails = company.has_company_details
    const hasBillingPlan = company.has_billing_plan
    const hasPaymentMethod = company.has_payment_method
    const hasUserSurveyResponse = company.has_user_survey_response
    const hasBillingDiscountCodeUse = company.has_billing_discount_code_use
    const billingDiscountCodeStatus = company.billing_discount_code_status
    const billingDiscountCodeUserAcceptance = company.billing_discount_code_user_acceptance
    const billingPlanFreeUntil = company.billing_plan_free_until
    const billingPlanBillingNextBillableAt = company.billing_plan_billing_next_billable_at
    const chargeAmountExTaxNumber = company.total_yearly_recurring_ex_tax
    const chargeAmountExTaxStr = `AUD $${chargeAmountExTaxNumber.toFixed(2)}`
    const chargeAmountIncTaxNumber = company.total_yearly_recurring_inc_tax
    const chargeAmountIncTaxStr = `$${chargeAmountIncTaxNumber.toFixed(2)}`
    const prevStepsCompletedForFreeTrial = (hasCompanyDetails && hasBillingPlan && hasPaymentMethod && hasUserSurveyResponse)
    const prevStepsCompletedForStartBilling = (hasCompanyDetails && hasBillingPlan && hasPaymentMethod)

    if (state.showStartFreeTrialConfirmForm) {
      return <FreeTrialFormElement
        chargeAmountExTaxStr={chargeAmountExTaxStr}
        chargeAmountIncTaxStr={chargeAmountIncTaxStr}
        submitHandler={this.handleSubmit}
        setDisplayStateHandler={this.setDisplayState}
      />
    }

    if (state.showStartBillingImmediatelyConfirmForm) {
      return <StartBillingFormElement
        chargeAmountExTaxStr={chargeAmountExTaxStr}
        chargeAmountIncTaxStr={chargeAmountIncTaxStr}
        submitHandler={this.handleSubmit}
        setDisplayStateHandler={this.setDisplayState}
      />
    }

    if (hasBillingDiscountCodeUse) {
      if (billingDiscountCodeStatus==='in_use') {
        return <AcceptedFreeTrialInfoElement
          hasBillingDiscountCodeUse={hasBillingDiscountCodeUse}
          billingDiscountCodeStatus={billingDiscountCodeStatus}
          billingDiscountCodeUserAcceptance={billingDiscountCodeUserAcceptance}
          billingPlanFreeUntil={billingPlanFreeUntil}
          chargeAmountExTaxStr={chargeAmountExTaxStr}
          chargeAmountIncTaxStr={chargeAmountIncTaxStr}
          setDisplayStateHandler={this.setDisplayState}
        />
      }
      if (billingDiscountCodeStatus==='declined') {
        return <DeclinedFreeTrialInfoElement
          hasBillingDiscountCodeUse={hasBillingDiscountCodeUse}
          billingDiscountCodeStatus={billingDiscountCodeStatus}
          billingDiscountCodeUserAcceptance={billingDiscountCodeUserAcceptance}
          billingPlanBillingNextBillableAt={billingPlanBillingNextBillableAt}
          chargeAmountExTaxStr={chargeAmountExTaxStr}
          chargeAmountIncTaxStr={chargeAmountIncTaxStr}
          setDisplayStateHandler={this.setDisplayState}
        />
      }
      if (billingDiscountCodeStatus==='completed') {
        return <CompletedFreeTrialInfoElement
          hasBillingDiscountCodeUse={hasBillingDiscountCodeUse}
          billingDiscountCodeStatus={billingDiscountCodeStatus}
          billingDiscountCodeUserAcceptance={billingDiscountCodeUserAcceptance}
          billingPlanBillingNextBillableAt={billingPlanBillingNextBillableAt}
          chargeAmountExTaxStr={chargeAmountExTaxStr}
          chargeAmountIncTaxStr={chargeAmountIncTaxStr}
          setDisplayStateHandler={this.setDisplayState}
        />
      }
    }


    return <div>
      {(!prevStepsCompletedForFreeTrial) && <FreeTrialOptionStepsNotCompletedElement />}
      {(prevStepsCompletedForFreeTrial) && <FreeTrialOptionInfoElement
        chargeAmountExTaxStr={chargeAmountExTaxStr}
        chargeAmountIncTaxStr={chargeAmountIncTaxStr}
        showConfirmFormHandler={this.setDisplayState}
      />}
      {(!prevStepsCompletedForStartBilling) && <StartBillingOptionStepsNotCompletedElement />}
      {(prevStepsCompletedForStartBilling) && <StartBillingOptionInfoElement
        chargeAmountExTaxStr={chargeAmountExTaxStr}
        chargeAmountIncTaxStr={chargeAmountIncTaxStr}
        showConfirmFormHandler={this.setDisplayState}
      />}
    </div>

  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const company = user && user.company

    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`Start Trial or Billing`} />
        <WhitePanel style={{ paddingBottom: 40 }}>
          {this.getPanelContent(state,company)}
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'Start Trial or Billing'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => {
    return {
      apiAuth: store.apiAuth,
    }
  },
)(StartTrialOrBilling)
