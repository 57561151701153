import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validateToken } from '../../../actions/AuthActions'
import { updateCompanyDetails } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import CompanyDetailsForm from '../../../components/my/account/CompanyDetailsForm'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'
import UserDetailsForm from "../../../components/my/account/UserDetailsForm";
import {updateUserDetails} from "../../../actions/UserActions";
import { confirmAlert } from 'react-confirm-alert';

class UpdateUserDetails extends React.Component {

  componentDidMount(prevProps) {
    this.props.dispatch(validateToken())
  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  handleSubmit = ({ uid,email, phone }) => {
    window.scrollTo(0,window.innerHeight)
    let that = this
    this.props.dispatch(updateUserDetails({
      uid:uid,
      email: email,
      phone:phone
    },(data)=>{
      console.log(data);
      if(data.success){

        var is_email_changed = (uid!= email);

        confirmAlert({
          title: 'Updated Success',
          message: 'You have updated your detail successfully!\n'+ (is_email_changed ? "And you have to re-login with new email address.":""),
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                if(is_email_changed) this.props.dispatch(push('/login'))
              }
            }
          ]
        })
      }else{
        confirmAlert({
          title: 'Invalid ' + ( data.error.email ? 'Email Address':'') + ((data.error.email && data.error.phone)?' & ':'') +  ( data.error.phone ? 'Phone Number':'') ,
          message: 'The new '+( data.error.email ? 'email address':'') + ((data.error.email && data.error.phone)?' and ':'') +  ( data.error.phone ? 'phone number':'') +' has been already registered to a different user, please change to another one',
          buttons: [
            {
              label: 'Ok',
              onClick: () => {}
            }
          ]
        })
      }
    }))
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>

    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`User Details`} />
        <WhitePanel>
          <UserDetailsForm
              uid={user.uid}
            email={user.email}
            phone={user.phone}
            errors={apiAuth.errors || []}
            submitHandler={this.handleSubmit}
            updateLoading={this.updateLoading}
          />
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'User Details'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(UpdateUserDetails)
