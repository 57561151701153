
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import Moment from 'moment-timezone'
import ReactGA from 'react-ga'
import ReactMarkdown from 'react-markdown/with-html'

import SiteLayout from '../../containers/layout/SiteLayout'
import { InnerContentContainer } from '../../styles/styled-elements'
import { InfoAlertsH1, IndexItemContainer, IndexItemHeading, IndexItemDatePublished, IndexItemDateLabel,
  IndexItemTeaser, IndexItemReadMoreLink } from './styled-elements'
import LoadingScreen from '../../components/LoadingScreen'
import HelmetTitle from '../../components/layout/HelmetTitle'

import { fetchInfoAlerts } from '../../actions/InfoAlertActions'

class InfoAlertShowFull extends React.Component {

  constructor(props) {
    super(props)
    const { path } = props.match
    this.state = {
      contentId: path.replace(/^\//,'')
    }
  }

  routeToShowPage = (route) => {
    const { dispatch, unseenInfoSplashAlerts } = this.props
    dispatch(push(route))
    ReactGA.event({
      category: 'Site Action',
      action: 'News & Alerts Index Page – Read More',
      label: route,
    })
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(fetchInfoAlerts())
  }

  getInfoAlertItems = () => {
    const { infoAlerts } = this.props
    return infoAlerts.map(({ title, teaser, published_at, published_at_str, slug, unread },index) => {
      const showPageRoute = `/info_alerts/${published_at_str}/${slug}`
      const publishedAtFormattedStr = Moment(published_at).tz('Australia/Adelaide').format('D MMM YYYY HH:mm')
      return <IndexItemContainer key={index} onClick={this.routeToShowPage.bind(this,showPageRoute)} unread={unread}>
        <IndexItemHeading>{title}</IndexItemHeading>
        <IndexItemDatePublished>
          <IndexItemDateLabel>Published: </IndexItemDateLabel>{publishedAtFormattedStr}
        </IndexItemDatePublished>
        <IndexItemTeaser>
          <ReactMarkdown
            source={teaser}
            escapeHtml={false}
          />
        </IndexItemTeaser>
        <IndexItemReadMoreLink><Link to={showPageRoute}>Read More...</Link></IndexItemReadMoreLink>
      </IndexItemContainer>
    })
  }

  getContent() {
    return <div>
      <HelmetTitle title={`News & Alerts`} />
      <InfoAlertsH1>News & Alerts</InfoAlertsH1>
      {this.getInfoAlertItems()}
    </div>
  }

  renderBody = (props,state) => {
    const { fetching } = this.props
    return <SiteLayout>
      <LoadingScreen show={fetching} />
      <InnerContentContainer>
        {this.getContent()}
      </InnerContentContainer>
    </SiteLayout>
  }

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => {
    const { match } = props
    const { params } = match
    const publishedInfoAlerts = store.infoAlerts.list.filter(({published_at}) => Moment(published_at) <= Moment()).slice()
    const pinnedInfoAlerts = publishedInfoAlerts.filter(({pin_to_top}) => pin_to_top===true).sort((a,b) => a.pin_order-b.pin_order)
    const unPinnedInfoAlerts = publishedInfoAlerts.filter(({pin_to_top}) => pin_to_top===false).sort((a,b) => Moment(b.published_at).diff(Moment(a.published_at)))
    const readItemIds = JSON.parse(localStorage.getItem('infoSplashAlertsReadItems')) || []
    const formattedInfoAlerts = [...pinnedInfoAlerts, ...unPinnedInfoAlerts].map((infoAlert) => {
      return {
        ...infoAlert,
        unread: ((readItemIds.indexOf(infoAlert.id_content_updated_at_str)===-1) && (Moment().diff(Moment(infoAlert.published_at),'days')<60)),
      }
    })
    return ({
      infoAlerts: formattedInfoAlerts,
      fetching: store.infoAlerts.fetching,
    })
  },
)(InfoAlertShowFull)
