import React from 'react'

import { ColorBarContainer, ColorBarNamesColumn, ColorBarColorsColumn, ColorScaleValueCell, ColorGradientBar } from './styled-elements'
import { metricDisplayStatuses } from "../../../reducers";

export default class RainbowLegend extends React.Component {

  renderBody = ({ metricDisplayStatuses, unit }) => <ColorBarContainer>
    <ColorBarNamesColumn>
      <ColorScaleValueCell position={'top'}>
        {this.metricDisplayLabel("top")}
      </ColorScaleValueCell>
      <ColorScaleValueCell position={'middle'}>
        {this.metricDisplayLabel("middle")}
      </ColorScaleValueCell>
      <ColorScaleValueCell position={'bottom'}>
        {this.metricDisplayLabel("bottom")}
      </ColorScaleValueCell>
    </ColorBarNamesColumn>
    <ColorBarColorsColumn>
      <ColorGradientBar
        height={100}
        colors={this.metricDisplayGradientColors()}
      />
    </ColorBarColorsColumn>
  </ColorBarContainer>

  metricDisplayGradientColors() {
    const { metricDisplayStatuses } = this.props;
    let colors = [];

    if (metricDisplayStatuses.length === 0)
      return colors;

    let maxValue = metricDisplayStatuses[0].threshold_value;
    for (const mds of metricDisplayStatuses) {
      let pct = 100 - Math.round(100 * mds.threshold_value / maxValue);
      colors.push(mds.background_color + " " + pct + "%");
    }
    return colors;
  }

  metricDisplayStatusThreshold(position) {
    const { metricDisplayStatuses } = this.props;
    switch (position) {
      case 'top':
        return metricDisplayStatuses[0]?.threshold_value || 0;
      case 'bottom':
        return metricDisplayStatuses[metricDisplayStatuses.length - 1]?.threshold_value || 0;
      case 'middle':
        // find the metric display status closest to the mid value
        return (this.metricDisplayStatusThreshold("top") -
          this.metricDisplayStatusThreshold("bottom")) / 2;
      default:
        return null;
    }
  }

  metricDisplayLabel(position) {
    const { metricDisplayStatuses, unit } = this.props;
    const thresholdValue = this.metricDisplayStatusThreshold(position);
    if (thresholdValue) {
      return `${parseInt(thresholdValue)}${unit}`;
    } else {
      return "";
    }
  }

  render() {
    return this.renderBody(this.props);
  }
}
