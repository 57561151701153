import React from 'react'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import { UpdatingIndicatorSpan, UpdatingIndicatorEllipsisContainer, ListRow, Column, ColumnStyle, ColumnResponsiveInnerContainer,
 ActionColumnStyleRightAligned, ActionIconContainer, H1, GridContainer, GridSection, DataViewIconContainer } from '../styled-elements'
import styledTheme from '../../../styles/theme'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import ChartLine from '../../../components/icons/ChartLine'
import Moment from 'moment-timezone'
import GrowingSeasonGraph from '../../../components/dataViews/GrowingSeasonGraph'

const UpdatingIndicator = ({isMobileView}) => <UpdatingIndicatorSpan isMobileView={isMobileView}>
    Updating
    <UpdatingIndicatorEllipsisContainer />
  </UpdatingIndicatorSpan>

export default class GrowingSeason extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      layoutWidth: this.getLayoutWdth(),
    }
    this.graphComponent = React.createRef()
  }

  getLayoutWdth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.innerWidth
  }

  resizeHandler = (e) => {
    if (e.target.innerWidth===this.state.layoutWidth) return
    this.setState({ layoutWidth: this.getLayoutWdth() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate() {
  }

  formatDateUpdated = (lastUpdated,timezone) => {
    const dateUpdatedMoment = Moment.tz(lastUpdated,timezone)
    const nowMoment = Moment.tz(new Date(),timezone)
    const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
    const diffDays = diffDuration ? diffDuration.asDays() : 0
    const diffHours = diffDuration ? diffDuration.asHours() : 0
    const diffMins = diffDuration ? diffDuration.asMinutes() : 0

    let updatedAtRelativeStr = ''

    if (diffHours < 2) {
      if (diffMins < 1) {
        updatedAtRelativeStr = `less than a minute ago`
      } else if (diffMins < 2) {
        updatedAtRelativeStr = `a minute ago`
      } else {
        updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
      }
    } else if (diffHours < 48) {
      updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
    }

    let updatedAtTimeStr = dateUpdatedMoment.format("HH:mm")
    if ((nowMoment.format("DD MMM YYYY")!==dateUpdatedMoment.format("DD MMM YYYY")) && (diffHours >= 24)) {
      updatedAtTimeStr += ` on ${dateUpdatedMoment.format("DD MMM")}`
      if (nowMoment.format("YYYY")!==dateUpdatedMoment.format("YYYY")) {
        updatedAtTimeStr += ` on ${dateUpdatedMoment.format("YYYY")}`
      }
    }

    if (diffHours < 240) {
      updatedAtTimeStr += ` (${updatedAtRelativeStr})`
    }

    return `${updatedAtTimeStr}`
  }

  isMobileView = () => {
    const screenWidth = window.innerWidth
    return (screenWidth < styledTheme.breakpoints.md)
  }

  renderDataViewsRows(dataViews) {
    const growingSeasonSlug = this.props.growingSeason.slug
    return dataViews.sort((a,b) => a.sort_order-b.sort_order).map((dv,index) => <ListRow divisions={12} key={index} onClick={this.props.rowClickHandler.bind(this,growingSeasonSlug,dv.slug)}>
      <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer><DataViewIconContainer><ChartLine /></DataViewIconContainer>{dv.name}</ColumnResponsiveInnerContainer></Column>
      <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer onClick={this.props.rowClickHandler.bind(this,growingSeasonSlug,dv.slug)}><ArrowRightBoldCircle right /></ActionIconContainer></Column>
    </ListRow>)
  }

  renderShowDataView(props,state) {
    const { dispatch, growingSeason, dataView, dataViewHash, closeDataViewHandler } = props
    if (!growingSeason) return null
    if (dataView) {
      switch (dataView.data_view_type_category) {
        case 'graph':
          const options = props.dataViewHash || {}
          return <GrowingSeasonGraph
            closeDataViewHandler={closeDataViewHandler}
            dispatch={dispatch}
            growingSeason={growingSeason}
            dataView={dataView}
            options={options}
            dataViewSummary={props.dataViews}
            ref={this.graphComponent}
          />
          break
        default:
          return null
      }
    }
  }


  renderGrowingSeasonDataViewsList(growingSeason,dataViews) {
    if (!growingSeason || !dataViews.length) return null
    if (dataViews) {
      return <div>
        <H1>{growingSeason.name}</H1>
        <GridContainer>
          <ContainerHeading title={`View Data`} />
          <GridSection>
            {this.renderDataViewsRows(dataViews)}
          </GridSection>
        </GridContainer>
      </div>
    }
  }

  renderBody = (props,state) => {
    return <div>
      {this.renderGrowingSeasonDataViewsList(props.growingSeason,props.dataViews)}
      { props.dataView && this.renderShowDataView(props,state) }
    </div>
  }

  render = () => this.renderBody(this.props,this.state)

}
