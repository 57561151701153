import axios from 'axios'
import { DateTime } from "luxon"

export function fetchGrowingSeasons() {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_SEASONS_FETCH',
      payload: axios(`/members/growing_seasons`)
    })
  }
}

export function updateGrowingSeason(id,updateParams) {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_SEASON_UPDATE',
      payload: axios.put(`/members/growing_seasons/${id}`,updateParams)
    })
  }
}

export function deleteGrowingSeason(id) {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_SEASON_DELETE',
      payload: axios.delete(`/members/growing_seasons/${id}`)
    })
  }
}

export function ConvertGrowingSeasonDetailModelData(growingSeason,growingLocations) {
  var isHome = false;
  var locationStr = "";
  growingLocations.forEach(location=>{
    if(location.slug == growingSeason.growing_location_slug){
      if(location.name == "Home"){
        isHome = true;
        locationStr = "";
      }else{
        isHome = false;
        locationStr = location.location_coords.lat.toFixed(14)+ ","+location.location_coords.lng.toFixed(14);
      }
    }
  });


  return {
    name: growingSeason.name,
    sowingDate: growingSeason.sowing_date ? DateTime.fromISO(growingSeason.sowing_date) : '',
    emergenceDateOption: growingSeason.emergence_date_option,
    emergenceDate: growingSeason.emergence_date ? DateTime.fromISO(growingSeason.emergence_date) : '',
    floralInitiationDateOption: growingSeason.floral_initiation_date_option,
    floralInitiationDate: growingSeason.floral_initiation_date ? DateTime.fromISO(growingSeason.floral_initiation_date) : '',
    harvestDateOption: growingSeason.harvest_date_option,
    harvestDate: growingSeason.harvest_date ? DateTime.fromISO(growingSeason.harvest_date) : '',
    locationOption: isHome?"home":"custom",
    location:locationStr,
    remoteId: growingSeason.remote_id,
  }
}

export function GrowingSeasonDetailModelDefaultData(){
  return {
    name: '',
    sowingDate: '',
    emergenceDateOption: 'auto_calculate',
    emergenceDate: '',
    floralInitiationDateOption: 'auto_calculate',
    floralInitiationDate: '',
    harvestDateOption: 'not_yet_known',
    harvestDate: '',
    locationOption: 'home',
    location: '',
    remoteId: '',
  }
}

export const LocationStrReg = /-?\d+.\d+,\s*-?\d+.\d+/g

export function IsGrowingSeasonLocationValid(location){
  if (location.match(LocationStrReg)) {
    var latlng = location.match(/-?\d+.\d+/g).map(Number);

    if (latlng[0] >= -38.2 && latlng[0] <= -32.8 && latlng[1] >= 137.8 && latlng[0] <= 141.2) {
      return true;
    }
  }
  return false;
}

export function ConvertGrowingSeasonUpdateParams(activeGrowingSeason){
  const updateParams = {}
    updateParams['name'] = activeGrowingSeason['name']
    var _location = activeGrowingSeason['location'].split(",")
    updateParams['location'] = {
      lat:parseFloat(_location[0]),
      lng:parseFloat(_location[1]),
    }
    const dateKeysArraysArray = [
      ['sowingDate','sowing_date'],
      ['emergenceDate','emergence_date'],
      ['floralInitiationDate','floral_initiation_date'],
      ['harvestDate','harvest_date']
    ]
    dateKeysArraysArray.forEach((dateKeysArray) => {
      if (activeGrowingSeason[dateKeysArray[0]]) {
        updateParams[dateKeysArray[1]] = activeGrowingSeason[dateKeysArray[0]].toISO()
      }
    })
    const dateOptionKeysArraysArray = [
      ['emergenceDateOption','emergence_date_option'],
      ['floralInitiationDateOption','floral_initiation_date_option'],
      ['harvestDateOption','harvest_date_option'],
      ['locationOption','location_option'],
    ]
    dateOptionKeysArraysArray.forEach((dateOptionKeysArray) => {
      if (activeGrowingSeason[dateOptionKeysArray[0]]) {
        updateParams[dateOptionKeysArray[1]] = activeGrowingSeason[dateOptionKeysArray[0]]
      }
    })

    return updateParams;
}

