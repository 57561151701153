export function openMenu() {
  return {
    type: 'MENU_OPEN',
  }
}

export function closeMenu() {
  return {
    type: 'MENU_CLOSE',
  }
}

export function toggleMenu() {
  return {
    type: 'MENU_TOGGLE',
  }
}
