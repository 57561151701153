import axios from 'axios'
import { push } from 'connected-react-router'

import { clearCurrentUser } from '../utils/authUtils'

export function validateToken() {
  return (dispatch, getState) => dispatch({
    type: 'VALIDATE_TOKEN',
    payload: axios('/auth/validate_token'),
  }).catch((error) => {
    clearCurrentUser()
    return Promise.reject(error)
  })
}

export function signIn(credentials) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_SIGN_IN',
      payload: axios.post('/auth/sign_in', credentials)
    })
    .then((response) => {
      const user = response.action.payload.data
      dispatch(push('/'))
    })
    .catch((error) => {
      clearCurrentUser()
      return Promise.reject(error)
    })
  }
}

export function signUp(parameters) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_SIGN_UP',
      payload: axios.post('/auth', { sign_up: parameters })
    })
    .then((response) => {
      const user = response.action.payload.data
      dispatch(push('/login'))
    })
    .catch((error) => {
      clearCurrentUser()
      return Promise.reject(error)
    })
  }
}

export function acceptInvitationAndCompleteSignup(parameters) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_SIGN_UP',
      payload: axios.put('/accept_invitation_and_complete_signup', { acceptance_params: parameters })
    })
    .then((response) => {
      const user = response.action.payload.data
      dispatch(push('/login'))
    })
    .catch((error) => {
      clearCurrentUser()
      return Promise.reject(error)
    })
  }
}

export function submitCompanyOwnershipInvitationResponse(parameters) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_UPDATE',
      payload: axios.put('/company_ownership_invitation_response', { acceptance_params: parameters })
    })
    .catch((error) => {
      return Promise.reject(error)
    })
  }
}

export function submitCompanyTransferInvitationResponse(parameters) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_UPDATE',
      payload: axios.put('/company_transfer_invitation_response', { acceptance_params: parameters })
    })
    .catch((error) => {
      return Promise.reject(error)
    })
  }
}


export function createNewCompanyAccount(parameters) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_UPDATE',
      payload: axios.put('/company_user_removal_create_new_company', { create_new_company_params: parameters })
    })
    .catch((error) => {
      return Promise.reject(error)
    })
  }
}

export function resetPassword(email) {
  const redirectUrl = `${window.location.origin}/new_password`
  const params = {
    email: email,
    redirect_url: redirectUrl,
  }
  return (dispatch) => {
    dispatch({
      type: 'USER_RESET_PASSWORD',
      payload: axios.post('/auth/password', params),
    })
  }
}

export function enablePasswordReset(reset_password_token) {
  const redirectUrl = `${window.location.origin}/new_password`
  const params = {
    reset_password_token,
    redirect_url: redirectUrl,
  }
  return (dispatch) => {
    dispatch({
      type: 'USER_ENABLE_PASSWORD',
      payload: axios('/auth/password/edit', { params }),
    })
  }
}

export function newPassword({ password, password_confirmation, reset_password_token }) {
  return (dispatch) => {
    dispatch({
      type: 'USER_NEW_PASSWORD',
      payload: axios.put('/auth/password', {
        password,
        password_confirmation,
        reset_password_token,
      }),
    })
  }
}

export function signOut(redirectPath='/') {
  return (dispatch) => {
    const logout = () => {
      clearCurrentUser()
      dispatch(push(redirectPath))
    }
    dispatch({
      type: 'USER_SIGN_OUT',
      payload: axios.delete('/auth/sign_out')
    })
    .then(() => {
      logout()
    })
    .catch((error) => {
      logout()
    })
  }
}

export function setPreviewMode(path) {
  return {
    type: 'SET_PREVIEW_MODE_ON',
  }
}

export function unsetPreviewMode(path) {
  return {
    type: 'SET_PREVIEW_MODE_OFF',
  }
}

export function setRedirectAfterLogin(path) {
  return {
    type: 'SET_REDIRECT_AFTER_LOGIN',
    payload: path,
  }
}

export function resetRedirectAfterLogin() {
  return {
    type: 'RESET_REDIRECT_AFTER_LOGIN',
  }
}
