const initialState = {
  list: [],
  seenItemIds: JSON.parse(localStorage.getItem('infoSplashAlertsSeenItems')) || [], 
  readItemIds: JSON.parse(localStorage.getItem('infoSplashAlertsReadItems')) || [], 
  item: null,
  fetching: false,
  fetched: false,
  errors: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INFO_ALERTS_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: null,
      }
    case "INFO_ALERTS_FETCH_REJECTED":
      return {
        ...state,
        fetching: false,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
      }
    case "INFO_ALERTS_FETCH_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.data,
        errors: null,
      }
    case "INFO_ALERTS_UPDATE_SEEN_ITEMS":
      const newSeenItemIds = [...new Set([ ...state.seenItemIds, ...action.meta.newSeenItems ])]
      localStorage.setItem('infoSplashAlertsSeenItems',JSON.stringify(newSeenItemIds))
      return {
        ...state,
        seenItemIds: newSeenItemIds,
      }    
    case "INFO_ALERTS_UPDATE_READ_ITEMS":
      const newReadItemIds = [...new Set([ ...state.readItemIds, ...action.meta.newReadItems ])]
      localStorage.setItem('infoSplashAlertsReadItems',JSON.stringify(newReadItemIds))
      return {
        ...state,
        readItemIds: newReadItemIds,
      }    
    default:
      return state
  }
}
