import React from 'react'

const DashboardIcon = () => (<svg width="24px" height="23px" viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1">
        <g id="dashboardIcon">
            <path d="M22.8013916,0 L1.19860838,0 C0.53778075,0 0,0.53778075 0,1.19860838 L0,21.3951416 C0,22.0559692 0.53778075,22.59375 1.19860838,22.59375 L22.8013916,22.59375 C23.4624023,22.59375 24,22.0559692 24,21.3951416 L24,1.19860838 C24,0.53778075 23.4622192,0 22.8013916,0 Z M22.59375,1.40625 L22.59375,5.33148192 L1.40625,5.33148192 L1.40625,1.40625 L22.59375,1.40625 Z M1.40625,21.1875 L1.40625,6.73773192 L22.59375,6.73773192 L22.59375,21.1875 L1.40625,21.1875 Z" id="Shape"></path>
            <path d="M12,8.67681886 C7.28833008,8.67681886 3.45501708,12.5101318 3.45501708,17.2218018 C3.45501708,17.6099854 3.76977539,17.9249268 4.15814208,17.9249268 C4.54650881,17.9249268 4.86126708,17.6099854 4.86126708,17.2218018 C4.86126708,13.2854004 8.06359866,10.0830689 12,10.0830689 C15.9364013,10.0830689 19.1387329,13.2854004 19.1387329,17.2218018 C19.1387329,17.6099854 19.4534912,17.9249268 19.8418579,17.9249268 C20.2302246,17.9249268 20.5449829,17.6099854 20.5449829,17.2218018 C20.5449829,12.5101318 16.7116699,8.67681886 12,8.67681886 Z" id="Shape"></path>
            <path d="M14.1161499,12.1116943 C13.7574463,11.9631958 13.3460083,12.133667 13.1975098,12.4923706 L12.0296631,15.312561 C12.0197754,15.3123779 12.0098877,15.3118286 12,15.3118286 C10.9467773,15.3118286 10.0900268,16.1685791 10.0900268,17.2218018 C10.0900268,18.2750244 10.9467773,19.1317749 12,19.1317749 C13.0532227,19.1317749 13.9099732,18.2748413 13.9099732,17.2218018 C13.9099732,16.6845703 13.6867676,16.1987915 13.3284302,15.8514404 L14.4966431,13.0303345 C14.6453247,12.6716309 14.4748535,12.260376 14.1161499,12.1116943 Z M12,17.7255249 C11.722229,17.7255249 11.4962768,17.4995728 11.4962768,17.2218018 C11.4962768,16.9440307 11.722229,16.7180786 12,16.7180786 C12.277771,16.7180786 12.5037232,16.9440308 12.5037232,17.2218018 C12.5037232,17.4995728 12.277771,17.7255249 12,17.7255249 Z" id="Shape"></path>
            <path d="M18.2999268,3.36895753 C18.2999268,3.89758303 17.8714599,4.32604978 17.3428345,4.32604978 C16.8140259,4.32604978 16.3855591,3.89758303 16.3855591,3.36895753 C16.3855591,2.84014894 16.8140259,2.41168214 17.3428345,2.41168214 C17.8714599,2.41168214 18.2999268,2.84014894 18.2999268,3.36895753 Z" id="Shape"></path>
            <path d="M21.1391602,3.36895753 C21.1391602,3.89758303 20.7105103,4.32604978 20.1818848,4.32604978 C19.6532593,4.32604978 19.2246094,3.89758303 19.2246094,3.36895753 C19.2246094,2.84014894 19.6532593,2.41168214 20.1818848,2.41168214 C20.7105103,2.41168214 21.1391602,2.84014894 21.1391602,3.36895753 Z" id="Shape"></path>
            <path d="M15.4608765,3.36895753 C15.4608765,3.89758303 15.0322266,4.32604978 14.5036011,4.32604978 C13.9749756,4.32604978 13.5463257,3.89758303 13.5463257,3.36895753 C13.5463257,2.84014894 13.9749756,2.41168214 14.5036011,2.41168214 C15.0322266,2.41168214 15.4608765,2.84014894 15.4608765,3.36895753 Z" id="Shape"></path>
        </g>
    </g>
</svg>)

export default DashboardIcon
