import axios from 'axios'

export function fetchPublicSites() {
  return (dispatch, getState) => {
    dispatch({
      type: 'PUBLIC_SITES_FETCH',
      payload: axios(`/sites`)
    })
  }
}

export function fetchMemberSites() {
  return (dispatch, getState) => {
    dispatch({
      type: 'MEMBERS_SITES_FETCH',
      payload: axios(`/members/sites`)
    })
  }
}
