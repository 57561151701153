import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { updateUserPermissions, inviteUserToBeOwner, removeUser } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton, UserInvitationSuccessMessage } from '../../../components/my/styled-elements'
import UserManagementEditUserForm from '../../../components/my/account/UserManagementEditUserForm'
import { RoundedFormButton, RoundedOutlineButton } from '../../../styles/styled-elements'
import BackToLink from '../../../components/my/account/BackToLink'
import { combineUserSets } from '../../../utils/userManagementUtils'

const MakeUserAccountOwner = ({ userEmailAddress, handleMakeUserAccountOwner, setDisplayStateHandler }) => {

  const formSubmitHandler = (e) => {
    e.preventDefault()
    handleMakeUserAccountOwner.call(this)
  }

  const cancel = () => {
    setDisplayStateHandler.call(this,'showMakeOwnerConfirmation',false)
  }

  return <div>
    <h3>Confirm Change of Owner</h3>
    <p>Are you sure you want to make {userEmailAddress} an owner?</p>
    <form onSubmit={formSubmitHandler}>
      <RoundedOutlineButton enabled onClick={cancel.bind(this)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
      <RoundedFormButton enabled name="Start" type="submit">Confirm</RoundedFormButton>
    </form>
  </div>
}

const DeleteUserConfirmation = ({ userEmailAddress, handleDeleteUser, setDisplayStateHandler }) => {

  const formSubmitHandler = (e) => {
    e.preventDefault()
    handleDeleteUser.call(this)
  }

  const cancel = () => {
    setDisplayStateHandler.call(this,'showDeleteUserConfirmation',false)
  }

  return <div>
    <h3>Confirm Deletion</h3>
    <p>Are you sure you want to delete {userEmailAddress}?</p>
    <form onSubmit={formSubmitHandler}>
      <RoundedOutlineButton enabled onClick={cancel.bind(this)} name="Cancel" type="button">Cancel</RoundedOutlineButton> &nbsp;
      <RoundedFormButton enabled name="Start" type="submit">Confirm</RoundedFormButton>
    </form>
  </div>
}

class UserManagementEditUser extends React.Component {

  constructor(props) {
    super(props)
    const { match } = props
    const { params } = match
    const encodedEmailAddress = params.emailAddress
    const emailAddress = decodeURIComponent(encodedEmailAddress)
    let user
    const { company } = this.props.apiAuth.user
    if (company) {
      user = combineUserSets(company).find(({email}) => email===emailAddress)
    }
    this.state = {
      userEmailAddress: emailAddress,
      user: user,
      showMainForm: true,
      userPermissionsUpdated: false,
      makeUserAccountOwnerSubmitted: false,
      removeUserSubmitted: false,
      showMakeOwnerConfirmation: false,
      showDeleteUserConfirmation: false,
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.props!==prevProps) {
      const { match } = this.props
      const { params } = match
      const encodedEmailAddress = params.emailAddress
      const emailAddress = decodeURIComponent(encodedEmailAddress)
      let user
      let permissions = {}
      const { company } = this.props.apiAuth.user
      const permittedUsers = company.permitted_users
      if (company) {
        user = permittedUsers.find(({email}) => email===emailAddress)
      }
      if (user) {
        permissions = user.permissions
      }
      const stateUser = this.state.user
      const statePermissions = stateUser && stateUser.permissions || {}
      if (
        (permissions.home_location_growing_seasons!==statePermissions.home_location_growing_seasons) ||
        (permissions.account_billing!==statePermissions.account_billing) ||
        (permissions.user_management!==statePermissions.user_management)
      ) {
        this.setState({
          userEmailAddress: emailAddress,
          user: user,
          userPermissionsUpdated: true,
        })
      } else if (permissions.owner_invitation_created_at && !statePermissions.owner_invitation_created_at) {
        this.setState({
          userEmailAddress: emailAddress,
          user: user,
          makeUserAccountOwnerSubmitted: true,
          showMakeOwnerConfirmation: false,
          showMainForm: false,
        })
      } else if (permissions.removal_created_at && !statePermissions.removal_created_at) {
        this.setState({
          userEmailAddress: emailAddress,
          user: user,
          removeUserSubmitted: true,
          showDeleteUserConfirmation: false,
          showMainForm: false,
        })
      }
    }
  }

  getUsers = () => {
    const { company } = this.props.apiAuth.user
    if (!company) return []
    return combineUserSets(company).sort((a,b) => (a.email < b.email) ? 1 : -1).filter(({permissions}) => (!permissions.removal_created_at))
  }

  handleSubmit = ({ permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement }) => {
    this.setState({ userPermissionsUpdated: false })
    window.scrollTo(0,window.innerHeight)
    const { dispatch } = this.props
    dispatch(updateUserPermissions({
      email: this.state.userEmailAddress.trim(),
      permission_home_location_growing_seasons: permissionHomeLocationGrowingSeasons,
      permission_account_billing: permissionAccountBilling,
      permission_user_management: permissionUserManagement,
    }))
  }

  handleShowConfirmationView = (viewName) => {
    const stateHash = {}
    if (viewName==='makeUserAccountOwner') {
      stateHash['showMakeOwnerConfirmation'] = true
    } else if (viewName==='deleteUser') {
      stateHash['showDeleteUserConfirmation'] = true
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash,() => {
        // console.log('state',this.state)
      })
    }
  }

  handleMakeUserAccountOwner = () => {
    const { dispatch } = this.props
    dispatch(inviteUserToBeOwner({
      email: this.state.userEmailAddress.trim(),
    }))
  }

  handleDeleteUser = () => {
    const { dispatch } = this.props
    dispatch(removeUser({
      email: this.state.userEmailAddress.trim(),
    }))
  }

  setDisplayState = (displayKey,visible) => {
    if (!['showMakeOwnerConfirmation','showDeleteUserConfirmation'].includes(displayKey)) return true
    if (![true,false].includes(visible)) return true
    const stateHash = {}
    stateHash[displayKey] = visible
    this.setState(stateHash)
  }

  getMainPanelContent = (state) => {
    const { user } = this.props.apiAuth
    const userPermissions = (user && user.permissions) || {}
    if (userPermissions.removal_created_at) {
      return null
    } else if (state.showMakeOwnerConfirmation) {
      return <MakeUserAccountOwner
        userEmailAddress={state.userEmailAddress}
        handleMakeUserAccountOwner={this.handleMakeUserAccountOwner}
        setDisplayStateHandler={this.setDisplayState}
      />
    } else if (state.showDeleteUserConfirmation) {
      return <DeleteUserConfirmation
        userEmailAddress={state.userEmailAddress}
        handleDeleteUser={this.handleDeleteUser}
        setDisplayStateHandler={this.setDisplayState}
      />
    } else if (state.showMainForm) {
      return <UserManagementEditUserForm
        userPermissions={userPermissions}
        editingUser={state.user}
        submitHandler={this.handleSubmit}
        showConfirmationViewHandler={this.handleShowConfirmationView}
      />
    }
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    if (!company) return null
    return <div>
      <BackToLink
        path={'/my/account/user_management'}
        label={'User Management'}
      />
      <H1>User Management</H1>
      {state.userPermissionsUpdated && <UserInvitationSuccessMessage>
        You have successfully updated permissions for {state.userEmailAddress}
      </UserInvitationSuccessMessage>}
      {state.makeUserAccountOwnerSubmitted && <UserInvitationSuccessMessage>
        You have successfully invited {state.userEmailAddress} to be the account owner. They will receive an email invitation, which they will need to accept in order to complete the process.
      </UserInvitationSuccessMessage>}
      {state.removeUserSubmitted && <UserInvitationSuccessMessage>
        You have successfully removed {state.userEmailAddress} from your account. They will still be able to sign in but will need to create a new company account.
      </UserInvitationSuccessMessage>}
      <PanelContainer>
        <ContainerHeading title={`Edit user – ${state.userEmailAddress}`} />
        <WhitePanel>
          {this.getMainPanelContent(state)}
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'User Management – Invite a new user'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(UserManagementEditUser)
