import React from "react"
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, FormControlLabel, TextField,  } from "@material-ui/core"
import styledTheme from '../../styles/theme'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DateFnsUtils from "@date-io/luxon"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import Grid from "@material-ui/core/Grid"
import { ModalScreenOverlay, GrowingSeasonSettingsPanelContainer, GrowingSeasonSettingsPanelContainerInner, GrowingSeasonSettingsPanelCloseIcon } from "./styled-elements"
import {IsGrowingSeasonLocationValid, LocationStrReg} from '../../actions/GrowingSeasonActions'

const styles = {
  buttonsContainer: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  button: {
    border: '2px solid #2F6BE1',
    borderRadius: '3px',
    padding: '4px 8px',
    fontSize: '12px',
    color: '#2F6BE1',
    marginLeft: '10px',
    cursor: 'pointer',
  },
}

const SmallFormControlLabel = withStyles({
  label: {
    fontSize: 14,
  }
})((props) => <FormControlLabel {...props} />)

const BlueRadio = withStyles({
  root: {
    color: styledTheme.colors.button_background,
    "&$checked": {
      color: styledTheme.colors.button_background,
    }
  },
})((props) => <Radio color="default" {...props} />)

const SmallFormLabel = withStyles({
  root: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
})((props) => <FormLabel component="legend" {...props} />)

export default class GrowingSeasonSettingsPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      locationHelpText:"",
      locationWarning:false
    }
  }

  callCloseActionIfClickOutsidePopup = (event,cancelHandler) => {
    if (event.target === event.currentTarget) cancelHandler.call()
  }

  formatDateTime = (dateTimeVal) => {
    if (typeof dateTimeVal==='undefined' || dateTimeVal==='') {
      return null
    }
    return dateTimeVal
  }

  renderBody = (props,state) => {

    const { growingSeasonName, sowingDate, emergenceDateOption, emergenceDate, floralInitiationDateOption, floralInitiationDate, harvestDateOption, harvestDate,locationOption, location, nameChangeHandler, radioChangeHandler, dateChangeHandler,locationChangeHandler, submitHandler, cancelHandler } = props

    return <ModalScreenOverlay>
      <GrowingSeasonSettingsPanelContainer>
        <GrowingSeasonSettingsPanelCloseIcon onClick={cancelHandler} />
        <GrowingSeasonSettingsPanelContainerInner>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 0}}>
              <FormControl component="fieldset">
                  <TextField
                    margin="normal"
                    label="Growing Season Name"
                    inputProps={{ style: { width: 226 }}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name={'name'}
                    value={growingSeasonName}
                    onChange={nameChangeHandler}
                  />
              </FormControl>
            </Grid>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 0}}>
              <FormControl component="fieldset">
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Sowing Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name={'sowingDate'}
                  value={this.formatDateTime(sowingDate)}
                  onChange={(date) => dateChangeHandler.call(this,'sowingDate',date)}
                  // KeyboardButtonProps={{
                  //   'aria-label': 'change date',
                  // }}
                />
              </FormControl>
            </Grid>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 20}}>
              <FormControl component="fieldset">
                <SmallFormLabel>Emergence Date</SmallFormLabel>
                <RadioGroup name="emergenceDateOption" value={emergenceDateOption} onChange={(event) => radioChangeHandler.call(this,'emergenceDateOption',event.target.value)}>
                  <SmallFormControlLabel value="auto_calculate" control={<BlueRadio size="small" />} label="Auto-calculate" />
                  <SmallFormControlLabel value="select" control={<BlueRadio size="small" />} label={<DatePicker
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.formatDateTime(emergenceDate)}
                      disabled={(emergenceDateOption!=='select')}
                      onChange={(date) => dateChangeHandler.call(this,'emergenceDate',date)}
                      // KeyboardButtonProps={{
                      //   'aria-label': 'change date',
                      // }}
                    />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 20}}>
              <FormControl component="fieldset">
                <SmallFormLabel>Floral Initiation Date</SmallFormLabel>
                <RadioGroup name="floralInitiationDateOption" value={floralInitiationDateOption} onChange={(event) => radioChangeHandler.call(this,'floralInitiationDateOption',event.target.value)}>
                  <SmallFormControlLabel value="auto_calculate" control={<BlueRadio size="small" />} label="Auto-calculate" />
                  <SmallFormControlLabel value="select" control={<BlueRadio size="small" />} label={<DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.formatDateTime(floralInitiationDate)}
                      disabled={(floralInitiationDateOption!=='select')}
                      onChange={(date) => dateChangeHandler.call(this,'floralInitiationDate',date)}
                      // KeyboardButtonProps={{
                      //   'aria-label': 'change date',
                      // }}
                    />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 20}}>
              <FormControl component="fieldset">
                <SmallFormLabel>Harvest Date</SmallFormLabel>
                <RadioGroup name="harvestDateOption" value={harvestDateOption} onChange={(event) => radioChangeHandler.call(this,'harvestDateOption',event.target.value)}>
                  <SmallFormControlLabel value="not_yet_known" control={<BlueRadio size="small" />} label="Not Yet Known" />
                  <SmallFormControlLabel value="select" control={<BlueRadio size="small" />} label={<DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.formatDateTime(harvestDate)}
                      disabled={(harvestDateOption!=='select')}
                      onChange={(date) => dateChangeHandler.call(this,'harvestDate',date)}
                      // KeyboardButtonProps={{
                      //   'aria-label': 'change date',
                      // }}
                    />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container justifyContent={"flex-start"} style={{marginTop: 20}}>
              <FormControl component="fieldset">
                <SmallFormLabel>Location</SmallFormLabel>
                <RadioGroup name="locationOption" value={locationOption} onChange={(event) => radioChangeHandler.call(this,'locationOption',event.target.value)}>
                  <SmallFormControlLabel value="home" control={<BlueRadio size="small" />} label="Home" />
                  <SmallFormControlLabel value="custom" control={<BlueRadio size="small" />} label={<TextField
                      margin="normal"
                      label="Custom Location"
                      placeholder="-35.85, 139.78"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={(locationOption!=='custom')}
                      name={'location'}
                      value={location}
                      error={this.state.locationWarning}
                      helperText={this.state.locationHelpText}
                      onChange={(event)=>{
                        const updateHash = {}
                        if(event.target.value.length > 0 && event.target.value.match(LocationStrReg)) {
                          if (IsGrowingSeasonLocationValid(event.target.value)) {
                            updateHash['locationHelpText'] = "";
                            updateHash['locationWarning'] = false;
                          } else {
                            updateHash['locationHelpText'] = "Must be between: -32.8, 137.8 and -38.2, 141.2";
                            updateHash['locationWarning'] = true;
                          }
                        } else {
                          updateHash['locationHelpText'] = "e.g. -35.95, 139.78"
                          updateHash['locationWarning'] = false;
                        }
                        this.setState(updateHash)
                        locationChangeHandler(event);
                      }}
                  />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container justifyContent="flex-end" style={{marginTop: 20}}>
              <FormControl component="fieldset">
                <div style={styles.buttonsContainer}>
                  <div style={styles.button} onClick={cancelHandler} key={1}>
                    Cancel
                  </div>
                  <div style={styles.button} onClick={submitHandler} key={2}>
                    Save
                  </div>
                </div>
              </FormControl>
            </Grid>
          </MuiPickersUtilsProvider>
        </GrowingSeasonSettingsPanelContainerInner>
      </GrowingSeasonSettingsPanelContainer>
    </ModalScreenOverlay>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
