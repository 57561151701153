import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { sendNewUserInvitation } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton, UserInvitationSuccessMessage } from '../../../components/my/styled-elements'
import UserManagementInviteNewForm from '../../../components/my/account/UserManagementInviteNewForm'
import { RoundedOutlineButton } from '../../../styles/styled-elements'
import BackToLink from '../../../components/my/account/BackToLink'
import { combineUserSets } from '../../../utils/userManagementUtils'

class UserManagementInviteNew extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      emailAddressJustInvited: '',
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.props!==prevProps) {
      const { company } = this.props.apiAuth.user
      const prevCompany = prevProps.apiAuth.user.company
      const newUsers = this.getUsers()
      const prevUsers = this.getUsers(prevCompany)
      const newEmails = newUsers.map(({email}) => email)
      const prevEmails = prevUsers.map(({email}) => email)
      const emailAddressJustInvited = newEmails.filter((emailAddr) => (!prevEmails.includes(emailAddr)))[0]
      if (emailAddressJustInvited) {
        this.setState({ emailAddressJustInvited: emailAddressJustInvited })
      }
    }
  }

  getUsers = (company=null) => {
    if (!company) company = this.props.apiAuth.user.company
    if (!company) return []
    return combineUserSets(company).sort((a,b) => (a.email < b.email) ? 1 : -1).filter(({permissions}) => (!permissions.removal_created_at))
  }

  getUserLimit = () => {
    const defaultLimit = 3
    const { company } = this.props.apiAuth.user
    if (!company) return defaultLimit
    const billingItemAccessPlan = company.billing_item_access_plan
    if (billingItemAccessPlan.user_limit) {
      return billingItemAccessPlan.user_limit
    } else if (company.default_access_plan_user_limit) {
      return billingItemAccessPlan.user_limit
    } else {
      return defaultLimit
    }
  }

  handleSubmit = ({ email, permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement }) => {
    window.scrollTo(0,window.innerHeight)
    const { dispatch } = this.props
    dispatch(sendNewUserInvitation({
      email: email.trim(),
      permission_home_location_growing_seasons: permissionHomeLocationGrowingSeasons,
      permission_account_billing: permissionAccountBilling,
      permission_user_management: permissionUserManagement,
    }))
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    if (!company) return null
    const users = this.getUsers()
    const userLimit = this.getUserLimit()
    return <div>
      <BackToLink
        path={'/my/account/user_management'}
        label={'User Management'}
      />
      <H1>User Management</H1>
      {state.emailAddressJustInvited && <UserInvitationSuccessMessage>
        You have successfully invited {state.emailAddressJustInvited}
      </UserInvitationSuccessMessage>}
      <PanelContainer>
        <ContainerHeading title={`Invite a new user`} />
        <WhitePanel>
          {(users.length >= userLimit) && <div>
            You have invited the maximum number of users.
          </div>}
          {(users.length < userLimit) && <UserManagementInviteNewForm
            submitHandler={this.handleSubmit}
          />}
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'User Management – Invite a new user'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(UserManagementInviteNew)
