import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { fetchMemberSites } from '../../actions/SiteActions'
import SiteLayout from '../../containers/layout/SiteLayout'
import ContainerHeading from '../../components/layout/ContainerHeading'
import HelmetTitle from '../../components/layout/HelmetTitle'
import LoadingScreen from '../../components/LoadingScreen'
import { SitesListControlContainer, GridContainer, GridSection, Column, ListHeaderRow, ListRow, ColumnStyle, SmallScreenSiteDataColumnStyle,
  SmallScreenSiteDataSiteName, SmallScreenSiteDataDetail, ActionHeaderColumnStyle, ActionColumnStyle,
  ActionColumnStyleRightAligned, ActionIconContainer, UpdatingIndicatorSpan, UpdatingIndicatorEllipsisContainer } from './styled-elements'
import styledTheme from '../../styles/theme'
import ArrowRightBoldCircle from '../../components/icons/ArrowRightBoldCircle'
import Moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'

const SitesListControls = ({changeHandler,selectedParam}) => {

  const options = [
    { value: 'numerical', label: 'Numerical' },
    { value: 'alphabetical', label: 'Alphabetical' },
    { value: 'datetime', label: 'Date/Time' },
  ]

  const selectedOption = options.find((o) => o.value===selectedParam)

  const selectStyles = {
    container: (base, state) => ({
      ...base,
      width: 220,
      margin: 6,
    }),
  }

  const useStyles = makeStyles(theme => ({
    label: {
      ...theme.typography.button,
      backgroundColor: theme.palette.background.paper,
      padding: `0 ${theme.spacing(1)}px`,
      display: 'flex',
      alignItems: 'center',
    }
  }))

  const classes = useStyles()

  return <SitesListControlContainer>
    <div className={classes.label}>
      Sort by:
    </div>
    <Select
      options={options}
      value={selectedOption}
      styles={selectStyles}
      onChange={changeHandler}
      isSearchable={false}
    />
  </SitesListControlContainer>

}

const UpdatingIndicator = ({isMobileView}) => <UpdatingIndicatorSpan isMobileView={isMobileView}>
    Updating
    <UpdatingIndicatorEllipsisContainer />
  </UpdatingIndicatorSpan>

class Sites extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      layoutWidth: this.getLayoutWdth(),
      sortParam: localStorage.getItem('sites_sort_param') || 'numerical'
    }
  }

  getLayoutWdth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.innerWidth
  }

  resizeHandler = (e) => {
    if (e.target.innerWidth===this.state.layoutWidth) return
    this.setState({ layoutWidth: this.getLayoutWdth() })
  }

  componentDidMount() {
    const { dispatch, isSignedIn } = this.props
    if (isSignedIn) {
      dispatch(fetchMemberSites())
    }
    window.addEventListener('resize', this.resizeHandler)
  }

  sortBySelectChangeHandler = (selectedObject) => {
    const sortParam=selectedObject['value']
    localStorage.setItem('sites_sort_param',sortParam)
    this.setState({sortParam: sortParam})
  }

  componentDidUpdate() {
  }

  formatDateUpdated = (lastUpdated,timezone) => {
    const dateUpdatedMoment = Moment.tz(lastUpdated,timezone)
    const nowMoment = Moment.tz(new Date(),timezone)
    const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
    const diffDays = diffDuration ? diffDuration.asDays() : 0
    const diffHours = diffDuration ? diffDuration.asHours() : 0
    const diffMins = diffDuration ? diffDuration.asMinutes() : 0

    let updatedAtRelativeStr = ''

    if (diffHours < 2) {
      if (diffMins < 1) {
        updatedAtRelativeStr = `less than a minute ago`
      } else if (diffMins < 2) {
        updatedAtRelativeStr = `a minute ago`
      } else {
        updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
      }
    } else if (diffHours < 48) {
      updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
    }

    let updatedAtTimeStr = dateUpdatedMoment.format("HH:mm")
    if ((nowMoment.format("DD MMM YYYY")!==dateUpdatedMoment.format("DD MMM YYYY")) && (diffHours >= 24)) {
      updatedAtTimeStr += ` on ${dateUpdatedMoment.format("DD MMM")}`
      if (nowMoment.format("YYYY")!==dateUpdatedMoment.format("YYYY")) {
        updatedAtTimeStr += ` on ${dateUpdatedMoment.format("YYYY")}`
      }
    }

    if (diffHours < 240) {
      updatedAtTimeStr += ` (${updatedAtRelativeStr})`
    }

    return `${updatedAtTimeStr}`
  }

  routeToDataViews = (site) => {
    this.props.dispatch(push(`/sites/${site.slug}`))
  }

  isMobileView = () => {
    const screenWidth = window.innerWidth
    return (screenWidth < styledTheme.breakpoints.md)
  }

  renderSitesHeaderRow = () => <ListHeaderRow key={0}>
    <Column xs={5} style={ColumnStyle}>Site Name</Column>
    <Column xs={5} style={ColumnStyle}>Data Last Updated</Column>
    <Column xs={2} style={ActionHeaderColumnStyle}>View</Column>
  </ListHeaderRow>

  renderSmallScreenSiteRows = (sites) => sites.map((site,index) => <ListRow key={index+1} onClick={this.routeToDataViews.bind(this,site)}>
    <Column xs={10} style={SmallScreenSiteDataColumnStyle}>
        <SmallScreenSiteDataSiteName>{site.name}</SmallScreenSiteDataSiteName>
        <SmallScreenSiteDataDetail>{site.historical_data_last_updated ? `${this.formatDateUpdated(site.historical_data_last_updated, site.timezone_name)}` : 'No data retrieved yet'} {site.data_processing ? <UpdatingIndicator isMobileView={true} /> : ''}</SmallScreenSiteDataDetail>
    </Column>
    <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer onClick={this.routeToDataViews.bind(this,site)}><ArrowRightBoldCircle /></ActionIconContainer></Column>
  </ListRow>)

  renderLargeScreenSiteRows = (sites) => sites.map((site,index) => <ListRow key={index+1} onClick={this.routeToDataViews.bind(this,site)}>
    <Column xs={5} style={ColumnStyle}>{site.name}</Column>
    <Column xs={5} style={ColumnStyle}>{site.historical_data_last_updated ? this.formatDateUpdated(site.historical_data_last_updated, site.timezone_name) : ''} {site.data_processing ? <UpdatingIndicator isMobileView={false} /> : ''}</Column>
    <Column xs={2} style={ActionColumnStyle}><ActionIconContainer onClick={this.routeToDataViews.bind(this,site)}><ArrowRightBoldCircle /></ActionIconContainer></Column>
  </ListRow>)

  renderSites(sites,sortParam) {
    const isMobileView = this.isMobileView()
    if (sites.sites) {
      const sitesCopy = sites.sites.slice()
      let regexedSortedSites
      const alphabeticalRegexp = /^([0-9]+|BOM)[_\- ](.*)$/
      const numericalRegexp = /^([0-9]+|BOM)[_\- ](.*$)/
      switch (sortParam) {
        case 'numerical':
          regexedSortedSites = sitesCopy.sort((a,b) => {
            const aNameMatch = a.name.match(numericalRegexp) || []
            const bNameMatch = b.name.match(numericalRegexp) || []
            const aNameMatchToken = aNameMatch[1]
            const bNameMatchToken = bNameMatch[1]
      	    const eitherSiteIsBom = [aNameMatchToken,bNameMatchToken].includes("BOM")
            const aNameCompareVar = eitherSiteIsBom ? a.name : parseInt(aNameMatchToken)
            const bNameCompareVar = eitherSiteIsBom ? b.name : parseInt(bNameMatchToken)
            if (aNameCompareVar < bNameCompareVar) return -1
            if (aNameCompareVar > bNameCompareVar) return 1
            return 0
          })
          break
        case 'alphabetical':
          regexedSortedSites = sitesCopy.sort((a,b) => {
            const aNameMatch = a.name.match(alphabeticalRegexp) || []
            const bNameMatch = b.name.match(alphabeticalRegexp) || []
            if (aNameMatch[2] < bNameMatch[2]) return -1
            if (aNameMatch[2] > bNameMatch[2]) return 1
            return 0
          })
          break
        case 'datetime':
          regexedSortedSites = sitesCopy.sort((a,b) => {
            if (!a.historical_data_last_updated && !b.historical_data_last_updated) return 0
            if (a.historical_data_last_updated && !b.historical_data_last_updated) return -1
            if (!a.historical_data_last_updated && b.historical_data_last_updated) return 1
            if (Moment(a.historical_data_last_updated) > Moment(b.historical_data_last_updated)) return -1
            if (Moment(a.historical_data_last_updated) < Moment(b.historical_data_last_updated)) return 1
            return 0
          })
          break
        default:
          break
      }
      return <GridContainer>
        <ContainerHeading title={`Sites`} />
        <SitesListControls changeHandler={this.sortBySelectChangeHandler} selectedParam={sortParam} />
        <GridSection>
          { isMobileView && this.renderSmallScreenSiteRows(regexedSortedSites) }
          { !isMobileView && this.renderSitesHeaderRow() }
          { !isMobileView && this.renderLargeScreenSiteRows(regexedSortedSites) }
        </GridSection>
      </GridContainer>
    }
  }

  renderBody = (props,state) => {
    const { isSignedIn } = props
    if (!isSignedIn) {
      return <div>
        <div style={{ fontSize: '1.6em', fontWeight: 700 }}>Log in to view sites</div>
        <p style={{ fontSize: '1.2em', fontWeight: 500 }}>To view detailed AWS data, <Link to={'/login'}>Log in</Link> or <Link to={'/signup'}>Sign Up</Link>.</p>
      </div>
    }
    return <div>
      <LoadingScreen show={(props.sites.fetching && !props.sites.sites.length)}/>
      {this.renderSites(props.sites,state.sortParam)}
    </div>
  }

  render = () => <SiteLayout>
    <HelmetTitle title={`Sites`} />
    {this.renderBody(this.props,this.state)}
  </SiteLayout>

}

export default connect(
  (store, props) => {
    const isSignedIn = store.apiAuth.isSignedIn
    return ({
      apiAuth: store.apiAuth,
      isSignedIn: isSignedIn,
      sites: store.sites,
      router: store.router,
    })
  },
)(Sites)
