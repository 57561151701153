import React from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton } from '../../../styles/styled-elements'

const FormElement = ({ siteOptions, formInputChangeHandler, setSelectedValue, toggleMapValue, handleSubmit, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const classes = useStyles();
  const socialMediaList = ["Facebook", "Instagram", "Twitter", "LinkedIn", "TikTok", "Other"];

  return <FormContainer>
    <FormInner>
      <Form onSubmit={handleSubmit}>
        {state.formErrors &&
          <FormHelperText style={{ textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper show={(!state.addressSameAsPrimary)}>
          <FieldDiv>
            <Autocomplete
              required
              name="user1Age"
              options={[
                'Under 18',
                '18-25',
                '26-35',
                '36-45',
                '46-55',
                '56-65',
                'Over 65',
              ]}
              onChange={(event, newValue) => {
                setSelectedValue('user1Age', newValue)
              }}
              value={state.user1Age}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Primary User's Age"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 200 }}
            />
            <FormHelperText error>{state.errors && state.errors.user1Age}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              required
              name="user1Gender"
              options={[
                'Male',
                'Female',
                'Unspecified',
              ]}
              onChange={(event, newValue) => {
                setSelectedValue('user1Gender', newValue)
              }}
              value={state.user1Gender}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Primary User's Gender"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 200 }}
            />
            <FormHelperText error>{state.errors && state.errors.user1Gender}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              multiline
              variant="standard"
              label="Occupation/Industry"
              name="occupationIndustry"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'occupationIndustry',
                maxLength: 50,
              }}
              value={state.occupationIndustry || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.occupationIndustry}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.occupationIndustry}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              id="selected-aws-autocomplete-input"
              name="mainAwsOfInterest"
              options={siteOptions}
              onChange={(event, newValue) => {
                setSelectedValue('mainAwsOfInterest', newValue)
              }}
              value={state.mainAwsOfInterest}
              isOptionEqualToValue={(a, b) => {
                return (a.value === b.value)
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Main Mesonet Station of Interest"
                  variant="standard"
                  placeholder="Type to search"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 300 }}
            />
            <FormHelperText error>{state.errors && state.errors.mainAwsOfInterest}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              required
              multiline
              variant="standard"
              label="Main Use of Mesonet"
              name="mainUseOfMesonet"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'mainUseOfMesonet',
                maxLength: 500,
              }}
              value={state.mainUseOfMesonet || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.mainUseOfMesonet}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.mainUseOfMesonet}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              required
              name="farmSize"
              options={[
                'Under 100 ha',
                '100-200 ha',
                '200-400 ha',
                '400-600 ha',
                '600-800 ha',
                '800-1000 ha',
                '1000-1500 ha',
                '1500-2000 ha',
                'Over 2000 ha',
                'N/A',
              ]}
              onChange={(event, newValue) => {
                setSelectedValue('farmSize', newValue)
              }}
              value={state.farmSize}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Approximate farm size"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 200 }}
            />
            <FormHelperText error>{state.errors && state.errors.farmSize}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Main crop type"
              name="mainCropType"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'mainCropType',
                maxLength: 50,
              }}
              value={state.mainCropType || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.mainCropType}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.mainCropType}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Main Two Herbicides Used in Summer"
              name="mainTwoHerbicidesUsedInSummer"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'mainTwoHerbicidesUsedInSummer',
                maxLength: 50,
              }}
              value={state.mainTwoHerbicidesUsedInSummer || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.mainTwoHerbicidesUsedInSummer}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.mainTwoHerbicidesUsedInSummer}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Usual supplier of summer herbicide"
              name="usualSupplierOfSummerHerbicide"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'off',
                autoComplete: 'usualSupplierOfSummerHerbicide',
                maxLength: 50,
              }}
              value={state.usualSupplierOfSummerHerbicide || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.usualSupplierOfSummerHerbicide}
              style={{ width: '100%', marginTop: 20 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.usualSupplierOfSummerHerbicide}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              required
              name="whereHeardAboutUs"
              options={[
                'Word of Mouth',
                'Recommendation from trusted advisor / agronomist',
                'Social Media',
                'Google search',
                'Event/expo/conference',
                'General media',
                'Ads',
                'Other',
              ]}
              onChange={(event, newValue) => {
                setSelectedValue('whereHeardAboutUs', newValue)
              }}
              value={state.whereHeardAboutUs}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Where did you hear about us?"
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 20, width: 400 }}
            />
            <FormHelperText error>{state.errors && state.errors.whereHeardAboutUs}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <p>Do you use social media (click all that apply)?</p>
            {socialMediaList.map((socialMediaValue) => {
              return <FormControlLabel
                key={socialMediaValue}
                control={<Checkbox
                  checked={state.socialMediaUse.has(socialMediaValue)}
                  name="socialMediaUse"
                  onChange={(event, newValue) => {
                    toggleMapValue(state.socialMediaUse, socialMediaValue);
                  }}
                  value={socialMediaValue}
                />}
                label={socialMediaValue} />
            })}
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Update</RoundedFormButton>
        </SubmitWrapper>
      </Form>
    </FormInner>
  </FormContainer>
}

export default class UserSurveyForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user1Age: '',
      user1Gender: '',
      occupationIndustry: '',
      mainAwsOfInterest: { label: '', value: '' },
      mainCropType: '',
      mainUseOfMesonet: '',
      farmSize: '',
      mainTwoHerbicidesUsedInSummer: '',
      usualSupplierOfSummerHerbicide: '',
      whereHeardAboutUs: '',
      socialMediaUse: new Map(),
      origValues: {
        user1Age: '',
        user1Gender: '',
        occupationIndustry: '',
        mainAwsOfInterest: { label: '', value: '' },
        mainCropType: '',
        mainUseOfMesonet: '',
        farmSize: '',
        mainTwoHerbicidesUsedInSummer: '',
        usualSupplierOfSummerHerbicide: '',
        whereHeardAboutUs: '',
        socialMediaUse: new Map(),
      },
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidMount(prevProps) {
    const populateStateFromPropsHash = this.populateStateFromPropsHash()
    this.setState(populateStateFromPropsHash)
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this
    const {
      user1Age, user1Gender, occupationIndustry, mainAwsOfInterest, mainUseOfMesonet, farmSize,
      mainCropType, mainTwoHerbicidesUsedInSummer, usualSupplierOfSummerHerbicide,
      whereHeardAboutUs, socialMediaUse,
      origValues
    } = this.state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state !== prevState) {
      if (user1Age !== props.user1Age && user1Age !== origValues.user1Age) hasChanged = true
      if (user1Gender !== props.user1Gender && user1Gender !== origValues.user1Gender) hasChanged = true
      if (occupationIndustry !== props.occupationIndustry && occupationIndustry !== origValues.occupationIndustry) hasChanged = true
      if (mainAwsOfInterest !== props.mainAwsOfInterest && mainAwsOfInterest !== origValues.mainAwsOfInterest) hasChanged = true
      if (mainUseOfMesonet !== props.mainUseOfMesonet && mainUseOfMesonet !== origValues.mainUseOfMesonet) hasChanged = true
      if (farmSize !== props.farmSize && farmSize !== origValues.farmSize) hasChanged = true
      if (mainCropType !== props.mainCropType && mainCropType !== origValues.mainCropType) hasChanged = true
      if (mainTwoHerbicidesUsedInSummer !== props.mainTwoHerbicidesUsedInSummer && mainTwoHerbicidesUsedInSummer !== origValues.mainTwoHerbicidesUsedInSummer) hasChanged = true
      if (usualSupplierOfSummerHerbicide !== props.usualSupplierOfSummerHerbicide && usualSupplierOfSummerHerbicide !== origValues.usualSupplierOfSummerHerbicide) hasChanged = true
      if (whereHeardAboutUs !== props.whereHeardAboutUs && whereHeardAboutUs !== origValues.whereHeardAboutUs) hasChanged = true
      if (socialMediaUse !== props.socialMediaUse && socialMediaUse !== origValues.socialMediaUse) hasChanged = true
      if (hasChanged !== state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if (
        user1Age &&
        user1Gender &&
        occupationIndustry &&
        mainAwsOfInterest &&
        mainUseOfMesonet &&
        whereHeardAboutUs
        // farmSize
        // mainCropType
        // mainTwoHerbicidesUsedInSummer
        // usualSupplierOfSummerHerbicide
      ) {
        canSubmit = true
      }
      if (canSubmit !== state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props !== prevProps) {
      if (
        (props.user1Age !== prevProps.user1Age) ||
        (props.user1Gender !== prevProps.user1Gender) ||
        (props.occupationIndustry !== prevProps.occupationIndustry) ||
        (props.mainAwsOfInterest['value'] !== prevProps.mainAwsOfInterest['value']) ||
        (props.mainUseOfMesonet !== prevProps.mainUseOfMesonet) ||
        (props.farmSize !== prevProps.farmSize) ||
        (props.mainCropType !== prevProps.mainCropType) ||
        (props.mainTwoHerbicidesUsedInSummer !== prevProps.mainTwoHerbicidesUsedInSummer) ||
        (props.usualSupplierOfSummerHerbicide !== prevProps.usualSupplierOfSummerHerbicide) ||
        (props.whereHeardAboutUs !== prevProps.whereHeardAboutUs) ||
        (props.socialMediaUse !== prevProps.socialMediaUse)
      ) {
        const populateStateHash = this.populateStateFromPropsHash()
        Object.keys(populateStateHash).forEach((key) => {
          stateHash[key] = populateStateHash[key]
        })
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  populateStateFromPropsHash = () => {
    const { props } = this
    const stateHash = {}
    stateHash['user1Age'] = props.user1Age || ''
    stateHash['user1Gender'] = props.user1Gender || ''
    stateHash['occupationIndustry'] = props.occupationIndustry || ''
    stateHash['mainAwsOfInterest'] = props.mainAwsOfInterest
    stateHash['mainUseOfMesonet'] = props.mainUseOfMesonet || ''
    stateHash['farmSize'] = props.farmSize || ''
    stateHash['mainCropType'] = props.mainCropType || ''
    stateHash['mainTwoHerbicidesUsedInSummer'] = props.mainTwoHerbicidesUsedInSummer || ''
    stateHash['usualSupplierOfSummerHerbicide'] = props.usualSupplierOfSummerHerbicide || ''
    stateHash['whereHeardAboutUs'] = props.whereHeardAboutUs || ''
    stateHash['socialMediaUse'] = props.socialMediaUse || new Map()
    const origValuesHash = { ...stateHash }
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['hasChanged'] = false
    stateHash['canSubmit'] = false
    return stateHash
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  setSelectedValue = (stateItem, v) => {
    this.setState({
      [stateItem]: v ? v : '',
      hasChanged: true,
    })
  }

  toggleMapValue = (map, value) => {
    if (map.has(value)) {
      map.delete(value);
    } else {
      map.set(value, true);
    }
    console.log(map);
    this.setState({ hasChanged: true });
  }

  validate = (callback) => {
    const errors = {}
    const {
      user1Age,
      user1Gender,
      occupationIndustry,
      mainAwsOfInterest,
      mainUseOfMesonet,
      farmSize,
      mainCropType,
      mainTwoHerbicidesUsedInSummer,
      usualSupplierOfSummerHerbicide
    } = this.state
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const {
      user1Age, user1Gender, occupationIndustry, mainAwsOfInterest, mainUseOfMesonet, farmSize, mainCropType,
      mainTwoHerbicidesUsedInSummer, usualSupplierOfSummerHerbicide,
      whereHeardAboutUs, socialMediaUse,
    } = this.state
    const params = {
      user1Age, user1Gender, occupationIndustry, mainAwsOfInterest, mainUseOfMesonet,
      farmSize, mainCropType, mainTwoHerbicidesUsedInSummer, usualSupplierOfSummerHerbicide,
      whereHeardAboutUs, socialMediaUse,
    }
    this.validate(() => {
      this.props.submitHandler.call(this, params)
    })
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value })
  }

  renderBody = (props, state) => <FormElement
    user1Age={props.user1Age}
    user1Gender={props.user1Gender}
    occupationIndustry={props.occupationIndustry}
    mainAwsOfInterest={props.mainAwsOfInterest}
    mainUseOfMesonet={props.mainUseOfMesonet}
    farmSize={props.farmSize}
    mainCropType={props.mainCropType}
    mainTwoHerbicidesUsedInSummer={props.mainTwoHerbicidesUsedInSummer}
    usualSupplierOfSummerHerbicide={props.usualSupplierOfSummerHerbicide}
    whereHeardAboutUs={props.whereHeardAboutUs}
    socialMediaUse={props.socialMediaUse}
    siteOptions={props.siteOptions}
    formInputChangeHandler={this.formInputChangeHandler}
    setSelectedValue={this.setSelectedValue}
    toggleMapValue={this.toggleMapValue}
    handleSubmit={this.handleSubmit}
    state={state}
  />

  render() {
    return this.renderBody(this.props, this.state)
  }
}
