import React from 'react'
import { signUp } from '../../actions/AuthActions'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, SubmitWrapper } from './styled-elements'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '../../styles/styled-elements'
import queryString from 'query-string'

export default class CompleteSignupForm extends React.Component {

  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      invitationToken: queryString.parse(window.location.search).token,
      firstName: null,
      surname: null,
      phone: null,
      email: queryString.parse(window.location.search).email,
      password: null,
      hasChanged: false,
      canSubmit: true,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps) {
    const authErrors = this.props.apiAuth.errors
    if (Array.isArray(authErrors) && authErrors!==prevProps.apiAuth.errors) {
      const errorsHash = this.generateErrorsHash(authErrors)
      if (Object.keys(errorsHash).length) {
        const updateHash = {
          hasErrors: true,
          errors: errorsHash,
        }
        return this.setState(updateHash)
      }
    }
    const stateHash = {}
    let hasChanged = false
    let canSubmit = true
    const { firstName, surname, phone, email, password } = this.state
    // if (
    //   firstName &&
    //   surname &&
    //   phone &&
    //   email &&
    //   password
    // ) {
    //   canSubmit = true
    // }
    if (canSubmit!==this.state.canSubmit) {
      stateHash['canSubmit'] = canSubmit
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash)
    }
  }

  generateErrorsHash = authErrors => {

    const errorsHash = {}
    authErrors.forEach((field) => {
      let fieldMessage = ''
      let fieldName = ''
      let fieldLabel = ''
      switch (field.field_name) {
        case 'email':
          fieldName = 'email'
          fieldLabel = 'Email address'
          if (field.messages[0]==='has already been taken') {
            fieldMessage = 'The email address is already registered. Please try logging in with this email (as opposed to signing up as a new user). If you have forgotten your password for this email login, you can use the Password Reset feature on the Login page.'
          } else {
            fieldMessage += `${fieldLabel} ${field.messages[0]}.`
          }
          break
        case 'phone':
          fieldName = 'phone'
          fieldLabel = 'Phone number'
          fieldMessage += `${fieldLabel} ${field.messages[0]}.`
          break
      }
      if (fieldMessage) {
        errorsHash[fieldName] = fieldMessage
      }
    })
    return errorsHash

  }

  validate = (callback) => {
    const errors = {}
    const updateHash = {}
    const { firstName, surname, phone, email, password } = this.state
    if (!firstName) {
      errors.firstName = 'First name is required'
    }
    if (!surname) {
      errors.surname = 'Surname is required'
    }
    if (!email) {
      errors.email = 'Email address is required'
    } else if (!(email || '').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.email = 'Must be a valid email address'
    }
    if (!password) {
      errors.password = 'Password is required'
    } else if ((password || '').length < 8) {
      errors.password = 'Password is too short'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      },() => {
        // console.log("XXX errors",errors)
      })
    } else if (Object.keys(updateHash).length) {
      this.setState(updateHash,() => {
        callback.call(this)
      },() => {
        // console.log("XXX errors",errors)
      })
    } else {
      callback.call(this)
    }
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    },() => {
      // console.log("XXX this.state",this.state)
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { invitationToken, firstName, surname, phone, email, password, hasChanged, canSubmit } = this.state
    if (!hasChanged || !canSubmit) return
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const params = { invitationToken, firstName, surname, phone, email, password }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  renderBody = (props,state) => <LoginContainer>
    <LoginInner>
      <LoginHeading>Complete Signup for COtL Mesonet</LoginHeading>
      <Form onSubmit={this.handleSubmit}>
        {this.state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <FieldDiv>
            <TextField
              label="First Name"
              name="firstName"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'firstName',
              }}
              value={state.firstName || ''}
              onChange={this.formInputChangeHandler}
              error={state.errors && state.errors.firstName}
              style={{ width: '100%' }}
            />
            <FormHelperText style={{ maxWidth: 300, marginBottom: 20 }} error>{state.errors && state.errors.firstName}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Surname"
              name="surname"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'surname',
              }}
              value={state.surname || ''}
              onChange={this.formInputChangeHandler}
              error={state.errors && state.errors.surname}
              style={{ width: '100%' }}
            />
            <FormHelperText style={{ maxWidth: 300, marginBottom: 20 }} error>{state.errors && state.errors.surname}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Email Address"
              name="email"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'none',
                autoComplete: 'email',
              }}
              value={state.email || ''}
              onChange={this.formInputChangeHandler}
              error={state.errors && state.errors.email}
              style={{ width: '100%' }}
            />
            <FormHelperText style={{ maxWidth: 300, marginBottom: 20 }} error>{state.errors && state.errors.email}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Phone Number (AU Mobile) *Optional"
              name="phone"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'none',
                autoComplete: 'phone',
              }}
              value={state.phone || ''}
              onChange={this.formInputChangeHandler}
              error={state.errors && state.errors.phone}
              style={{ width: '100%' }}
            />
            <FormHelperText style={{ maxWidth: 300, marginBottom: 20 }} error>{state.errors && state.errors.phone}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Password"
              name="password"
              type="password"
              inputProps={{
                autoComplete: 'new-password',
              }}
              value={state.password || ''}
              onChange={this.formInputChangeHandler}
              error={state.errors && state.errors.password}
              style={{ width: '100%' }}
            />
            <FormHelperText style={{ maxWidth: 300, marginBottom: 20 }} error>{state.errors && state.errors.password}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <Button primary rounded>Complete Signup</Button>
        </SubmitWrapper>
      </Form>
    </LoginInner>
  </LoginContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
