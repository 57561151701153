import React from 'react'
import { Link } from 'react-router-dom'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, CompletedMessageContainer, RejectedMessageContainer, LinkContainer, SubmitWrapper } from './styled-elements'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '../../styles/styled-elements'

export default class NewPasswordForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      password: '',
      password_confirmation: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiAuth.errors!==prevProps.apiAuth.errors)
    this.setState({
      formErrors: this.props.apiAuth.errors
    })
  }

  validate = (callback) => {
    const errors = {}
    const { password, password_confirmation } = this.state
    if (!password) {
      errors.password = 'Password is required'
    } else if ((password || '').length < 8) {
      errors.password = 'Password is too short'
    }
    if (password!==password_confirmation) {
      errors.password_confirmation = 'Passwords must match'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { password, password_confirmation } = this.state
    const params = { password, password_confirmation }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  renderForm = (props,state) => <Form onSubmit={this.handleSubmit}>
    {this.state.formErrors &&
      <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
    }
    <FieldsWrapper>
      <FieldDiv>
        <TextField
          label="Password"
          name="password"
          type="password"
          inputProps={{
            autoComplete: 'current-password',
          }}
          value={this.state.password || ''}
          onChange={(e) => this.setState({password: e.target.value})}
          error={state.errors && state.errors.password}
          style={{ width: '100%' }}
        />
        <FormHelperText error>{state.errors && state.errors.password}</FormHelperText>
      </FieldDiv>
      <FieldDiv>
        <TextField
          label="Re-enter Password"
          name="password_confirmation"
          type="password"
          inputProps={{
            autoComplete: 'current-password',
          }}
          value={this.state.password_confirmation || ''}
          onChange={(e) => this.setState({password_confirmation: e.target.value})}
          error={state.errors && state.errors.password_confirmation}
          style={{ width: '100%' }}
        />
        <FormHelperText error>{state.errors && state.errors.password_confirmation}</FormHelperText>
      </FieldDiv>
    </FieldsWrapper>
    <SubmitWrapper>
      <Button primary rounded>Submit</Button>
    </SubmitWrapper>
  </Form>

  renderFulfilledMessage = (props,state) => <div>
    <CompletedMessageContainer>
      <p>Your new password has been set.</p>
      <p>Please return to the <Link to={'/login'}>Login</Link> page to log in.</p>
    </CompletedMessageContainer>
    <LinkContainer left>
      <Link to={'/login'}>Login</Link>
    </LinkContainer>
  </div>

  renderRejectedMessage = (props,state) => <div>
    <RejectedMessageContainer>
      <p>Your password update request could not be accepted.</p>
      <p>Your reset request may have expired.</p>
      <p>Please request a password reset again via the Reset Password link below</p>
    </RejectedMessageContainer>
    <LinkContainer left>
      <Link to={'/password_reset'}>Reset Password</Link>
    </LinkContainer>
    <LinkContainer left>
      <Link to={'/login'}>Login</Link>
    </LinkContainer>
    <LinkContainer left>
      <Link to={'/'}>Home</Link>
    </LinkContainer>
  </div>

  renderBody = (props,state) => <LoginContainer>
    <LoginInner>
      <LoginHeading>Reset Password</LoginHeading>
      {props.submitRejected && this.renderRejectedMessage(props,state)}
      {props.submitFulfilled && !props.submitRejected && this.renderFulfilledMessage(props,state)}
      {!props.submitFulfilled && !props.submitRejected && this.renderForm(props,state)}
    </LoginInner>
  </LoginContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
