import { companyIsAdmin } from "./companyUtils";

export function isSiteEligibleForAllTimeGraph(user, site) {

  if (companyIsAdmin(user?.company)) return true;

  const billingItemAccessPlan = user?.company?.billing_item_access_plan;
  if (!billingItemAccessPlan) return false

  const billingProductKey = billingItemAccessPlan.billing_product_key;

  if (billingProductKey === 'all_access_1') return true;

  const awsSlugs = [...[billingItemAccessPlan.site_slug], ...billingItemAccessPlan.additional_aws_slugs]

  return (billingProductKey === 'premium_bundle_1') && (awsSlugs.includes(site.slug));
}

// @return [Boolean] True if the 'not signed in' banner should be shown
export function showNotSignedInBanner() {
  const location = window.location.pathname;
  const paths = [
    "/login",
    "/signup",
    "/password_reset",
  ];
  return !paths.includes(location);
}
