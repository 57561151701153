import styled from 'styled-components'

export const ColorBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
`

export const ColorBarNamesColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding-right: 10px;
`

export const ColorBarColorsColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 20px;
`

export const ColorBarNameCell = styled.div`
  height: 20px;
  padding: 2px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
`

export const ColorBarColorCell = styled.div`
  height: 20px;
  width: 20px;
  padding: 2px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`

export const ColorBarColorCellInner = styled.div`
  height: 16px;
  width: 16px;
  border: 1px solid black;
  background-color: ${({bgColor}) => bgColor};
`

export const ColorScaleValueCell = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: ${({position}) => { switch(position) {
    case 'top':
      return 'flex-start'
      break
    case 'middle':
      return 'center'
      break
    case 'bottom':
      return 'flex-end'
      break
    default:
      return 'flex-start'
    }
  }};
  align-items: flex-end;
  flex: 1;
  font-size: 10px;  
`

export const ColorGradientBar = styled.div`
  height: ${({height}) => height ? height : 50}px;
  width: 16px;
  border: 1px solid black;
  background-image: linear-gradient(to bottom, ${({colors}) => colors.join(', ')});
`

export const ColorBarContainerBottom = styled.div`
  width: 90px;
  margin-top: 15px;
`

export const LegenBottomText= styled.span`
  color: darkgrey;
  font-size: 10px;
`
