import axios from 'axios'
import { validateToken } from '../actions/AuthActions'
import Cookies from 'js-cookie'

export function setAuthFromCookies(updateCookies = false) {
  const authKeys = ['access-token', 'client', 'uid']
  authKeys.forEach((authDetail) => {
    const value = Cookies.get(authDetail);
    if (!value) {
      clearCurrentUser();
      return;
    }
    axios.defaults.headers.common[authDetail] = Cookies.get(authDetail)

    if (updateCookies) {
      // set the cookie again, to reset the expiry date
      Cookies.set(authDetail, Cookies.get(authDetail), { expires: 14 })
    }
  })
}

export function setCurrentUser(headers) {
  //console.log("XXX setCurrentUser",headers)
  //console.log("XXX headers['access-token']",headers['access-token'])
  Cookies.set('access-token', headers['access-token'], { expires: 14 })
  Cookies.set('client', headers['client'], { expires: 14 })
  Cookies.set('uid', headers['uid'], { expires: 14 })

  setAuthFromCookies()
}

export function setAuthInterceptor() {
  axios.interceptors.response.use((response) => {

    if (response.headers['access-token']) {
      setCurrentUser(response.headers)
    }

    return response
  }, function (error) {
    if (error.response) {
      // console.log("Switchingstatus", error.response.status)
      // console.log("error.response", error.response)
      switch(error.response.status) {
        case 401:
          clearCurrentUser()
          console.log("Error 401 (422)", error.response)
          break
        case 422:
          console.log("Record Save Error (422)", error.response)
          break
        case 500:
          console.log("Server Error", error.response)
          console.log("This action is unavailable. Please try again soon.")
          validateToken()
          break
        default:
          console.log("Unknown Request Error", error.response)
      }
    } else {
      console.log("Network error detected")
      //alert("Network error. Please check your connection and try again.")
    }

    return Promise.reject(error);
  })
}

export function clearCurrentUser() {
  Cookies.remove('access-token')
  Cookies.remove('client')
  Cookies.remove('uid')
}
