import React from 'react'
import { connect } from 'react-redux'
import { submitCompanyOwnershipInvitationResponse } from '../../actions/AuthActions'
import CompanyOwnershipInvitationForm from '../../components/auth/CompanyOwnershipInvitationForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'

class CompanyOwnershipInvitation extends React.Component {

  handleSubmit = ({ acceptance }) => {
    window.scrollTo(0,window.innerHeight)
    this.props.dispatch(submitCompanyOwnershipInvitationResponse({
      acceptance: acceptance
    }))
  }

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Invitation To Be Account Owner'} />
    <CompanyOwnershipInvitationForm
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
      submitHandler={this.handleSubmit}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
  }),
)(CompanyOwnershipInvitation)
