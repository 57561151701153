import React from 'react'
import { SiteNameHoverPopupContainer } from './styled-elements'

export default class SiteNameHoverPopup extends React.Component {

  componentDidUpdate(prevProps) {
  }

  renderBody = (props,state) => <SiteNameHoverPopupContainer>
    {props.name}
  </SiteNameHoverPopupContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
