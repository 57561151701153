import React from 'react'

import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './configureStore'

import axios from 'axios'
import injectTapEventPlugin from 'react-tap-event-plugin'
import { Helmet } from 'react-helmet'

import logo from './logo.svg'
import GlobalStyles from './styles/global-styles'

import Map from './containers/map'
import Sites from './containers/sites'
import Site from './containers/sites/site'
import FlatContent from './containers/flatContent'
import NewInfoAlertSplash from './containers/infoAlerts/NewInfoAlertSplash'
import ScheduledDataUpdater from './components/ScheduledDataUpdater'
import InfoAlertShowFull from './components/infoAlerts/InfoAlertShowFull'
import InfoAlertsIndex from './components/infoAlerts/InfoAlertsIndex'
import MyGrowingSeasons from './containers/my/growingSeasons'
import MyGrowingSeason from './containers/my/growingSeasons/MyGrowingSeason'
import MyAccount from './containers/my/account/'
import AccountSetupChecklist from './containers/my/account/AccountSetupChecklist'
import CompanyDetails from './containers/my/account/CompanyDetails'
import PaymentDetails from './containers/my/account/PaymentDetails'
import PlanAndExtras from './containers/my/account/PlanAndExtras'
import UserSurvey from './containers/my/account/UserSurvey'
import StartTrialOrBilling from './containers/my/account/StartTrialOrBilling'
import UserManagementInviteNew from './containers/my/account/UserManagementInviteNew'
import UserManagementEditUser from './containers/my/account/UserManagementEditUser'
import UserManagement from './containers/my/account/UserManagement'
import Login from './containers/auth/Login'
import Logout from './containers/auth/Logout'
import Signup from './containers/auth/Signup'
import CompleteSignup from './containers/auth/CompleteSignup'
import PasswordReset from './containers/auth/PasswordReset'
import NewPassword from './containers/auth/NewPassword'
import SetPreviewMode from './containers/auth/SetPreviewMode'
import UnsetPreviewMode from './containers/auth/UnsetPreviewMode'
import ResetCompanySettings from './containers/auth/ResetCompanySettings'
import CompanyOwnershipInvitation from './containers/auth/CompanyOwnershipInvitation'
import CompanyTransferInvitation from './containers/auth/CompanyTransferInvitation'
import UserCreateNewCompanyAccount from './containers/auth/UserCreateNewCompanyAccount'
import UpdateUserDetails from './containers/my/account/UpdateUserDetails'

import Analytics from './hoc/Analytics'
import SaveLastUrl from './hoc/SaveLastUrl'

import { setAuthInterceptor, setAuthFromCookies } from './utils/authUtils'
import Environment from './utils/environment'

try {
  injectTapEventPlugin()
} catch (e) {}

setAuthInterceptor()
setAuthFromCookies(true)

axios.defaults.baseURL = Environment.API_URL
axios.defaults.headers.common['Accept'] = `application/json`

const store = configureStore()

const ScrollToTop = ({ children }) => {
  window.scrollTo(0, 0)
  return (
    <div>
      {children}
    </div>
  )
}

const getTitle = () => {
  return "COtL Mesonet";
}

const HelmetRootTemplate = () => <Helmet
  titleTemplate={`%s :: ${getTitle()}`}
  defaultTitle={`${getTitle()}`}
  meta={[
    { property: 'og:title', content: `${getTitle()}` },
    { property: 'charset', content: 'utf-8' }
  ]}
/>

const MesonetApp = (props) => (
  <Provider store={store} key="provider">
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <GlobalStyles />
        <HelmetRootTemplate />
        <Analytics />
        <SaveLastUrl history={history} />
        <ScheduledDataUpdater />
        <NewInfoAlertSplash />
        <Switch>
          <Route exact path="/" component={Map} />
          <Route exact path="/map/:site_slug/:data_view_slug" component={Map} />
          <Route exact path="/sites" component={Sites} />
          <Route exact path="/sites/:site_slug/data_views/:data_view_slug" component={Site} />
          <Route exact path="/sites/:site_slug/data_views/" component={Site} />
          <Route exact path="/sites/:site_slug" component={Site}/>
          <Route exact path="/about/latest_news" render={(props) => <FlatContent {...props} slug={'about_latest_news'} />} />
          <Route exact path="/about/overview" render={(props) => <FlatContent {...props} slug={'about_overview'} />} />
          <Route exact path="/about/observations" render={(props) => <FlatContent {...props} slug={'about_observations'} />} />
          <Route exact path="/about/userguide" render={(props) => <FlatContent {...props} slug={'about_userguide'} />} />
          <Route exact path="/about/contact_us" render={(props) => <FlatContent {...props} slug={'about_contact_us'} />} />
          <Route exact path="/about/supporters_sponsors" render={(props) => <FlatContent {...props} slug={'about_supporters_sponsors'} />} />
          <Route exact path="/about/faults_improvements" render={(props) => <FlatContent {...props} slug={'about_faults_improvements'} />} />
          <Route exact path="/about/subscription_services" render={(props) => <FlatContent {...props} slug={'about_subscription_services'} />} />
          <Route exact path="/about/faqs" render={(props) => <FlatContent {...props} slug={'about_faqs'} />} />
          <Route exact path="/about/disclaimer" render={(props) => <FlatContent {...props} slug={'about_disclaimer'} />} />
          <Route exact path="/info_alerts/:date_str/:slug" component={InfoAlertShowFull} />
          <Route exact path="/info_alerts" component={InfoAlertsIndex} />
          <Route exact path="/my/growing_seasons/:growing_season_slug/data_views/:data_view_slug" component={MyGrowingSeason}/>
          <Route exact path="/my/growing_seasons/:growing_season_slug" component={MyGrowingSeason}/>
          <Route exact path="/my/growing_seasons" component={MyGrowingSeasons} />
          <Route exact path="/my/account/account_setup_checklist" component={AccountSetupChecklist} />
          <Route exact path="/my/account/company_details" component={CompanyDetails} />
          <Route exact path="/my/account/payment_details" component={PaymentDetails} />
          <Route exact path="/my/account/plan_and_extras" component={PlanAndExtras} />
          <Route exact path="/my/account/user_survey" component={UserSurvey} />
          <Route exact path="/my/account/start_trial_or_billing" component={StartTrialOrBilling} />
          <Route exact path="/my/account/user_management/invite" component={UserManagementInviteNew} />
          <Route exact path="/my/account/user_management/edit/:emailAddress" component={UserManagementEditUser} />
          <Route exact path="/my/account/user_management" component={UserManagement} />
          <Route exact path="/my/account/update_user_details" component={UpdateUserDetails} />
          <Route exact path="/my/account" component={MyAccount} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/complete_signup" component={CompleteSignup} />
          <Route exact path="/password_reset" component={PasswordReset} />
          <Route exact path="/new_password" component={NewPassword} />
          <Route exact path="/company_ownership_invitation" component={CompanyOwnershipInvitation} />
          <Route exact path="/company_transfer_invitation" component={CompanyTransferInvitation} />
          <Route exact path="/create_new_company_account" component={UserCreateNewCompanyAccount} />
          <Route exact path="/set_preview_mode" component={SetPreviewMode} />
          <Route exact path="/unset_preview_mode" component={UnsetPreviewMode} />
          <Route exact path="/reset_company_settings" component={ResetCompanySettings} />
        </Switch>
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>
)

export default MesonetApp
