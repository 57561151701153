import React from 'react'

const MapMarker = () => (<svg width="18px" height="24px" viewBox="0 0 18 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="map-marker" fill="#000000" fillRule="nonzero">
            <path d="M8.71875,0 C3.92601562,0 0.026859375,3.89915625 0.026859375,8.69184375 C0.026859375,14.6397188 7.80525,23.3715469 8.13642187,23.7403594 C8.44748437,24.0868125 8.99057812,24.0862031 9.30107812,23.7403594 C9.63225,23.3715469 17.4106406,14.6397188 17.4106406,8.69184375 C17.4105469,3.89915625 13.5114375,0 8.71875,0 Z M8.71875,13.0649531 C6.30740625,13.0649531 4.3456875,11.1031875 4.3456875,8.69184375 C4.3456875,6.2805 6.30745312,4.31878125 8.71875,4.31878125 C11.1300469,4.31878125 13.0917656,6.28054688 13.0917656,8.69189063 C13.0917656,11.1032344 11.1300469,13.0649531 8.71875,13.0649531 Z" id="Shape"></path>
        </g>
    </g>
</svg>)

export default MapMarker
