import React from 'react'
import { ContainerHeadingActionIcon, HeaderRow, HeaderCell,
  UnitsRow, UnitCell, DataRow, DataCell, Table, THead, TBody, DataTableModalContainer,
  TableContainer, DataTableFooter, DownloadButton, NoDataDiv } from './styled-elements'
import CloseCircle from '../icons/CloseCircle'
import ContainerHeading from '../layout/ContainerHeading'
import HelmetTitle from '../../components/layout/HelmetTitle'
import Moment from 'moment-timezone'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect"

export default class DataTable extends React.Component {

  componentDidMount = () => {
  }

  renderTable = (data,site) => {
    if (!data.data.length) return <NoDataDiv>No data found.</NoDataDiv>
    const header1Count = data.headers[0] && data.headers[0].length
    const header2Count = data.headers[1] && data.headers[1].length
    const header3Count = data.headers[2] && data.headers[2].length
    const colCount = data.data[0].length
    const Row1 = data.headers && data.headers[0] && <HeaderRow key={0}>{ data.headers[0].map((headerObj, index) => headerObj && <HeaderCell key={index} first={(index===0)} colSpan={headerObj['span']}>{headerObj['text']}</HeaderCell>) }<HeaderCell key={header1Count} /></HeaderRow>
    const Row2 = data.headers && data.headers[1] && <UnitsRow key={1}>{ data.headers[1].map((headerObj, index) => headerObj && <UnitCell key={index} first={(index===0)} colSpan={headerObj['span']}>{headerObj['text']}</UnitCell>) }<UnitCell key={header3Count} /></UnitsRow>
    const Row3 = data.headers && data.headers[2] && <UnitsRow key={2}>{ data.headers[2].map((headerObj, index) => headerObj && <UnitCell key={index} first={(index===0)} colSpan={headerObj['span']}>{headerObj['text']}</UnitCell>) }<UnitCell  key={header2Count} /></UnitsRow>
    const decimalPlacesArray = data.metadata && data.metadata.decimal_places
    const DataRows = data.data.map((row,rowIndex) => {
      const moment = Moment.tz(row[0],site.timezone_name)
      const momentPart1 = moment.format('YYYY-MM-')
      const momentPart2 = moment.format('DD')
      const momentPart3 = moment.format('HH:mm')
      const dataCells = []
      for (let i=1;i<colCount;i++) {
        dataCells.push(row[i])
      }
      return <DataRow key={rowIndex}>
        <DataCell key={0}>{momentPart1}<strong>{momentPart2}</strong> | <strong>{momentPart3}</strong></DataCell>
        {dataCells.map((value,cellIndex) =>  <DataCell key={cellIndex+1}>{value}</DataCell>)}
        <DataCell key={colCount} />
      </DataRow>
    })
    return <Table>
      <THead>
        {Row1}
        {Row2}
        {Row3 && Row3}
      </THead>
      <TBody>
        {DataRows}
      </TBody>
    </Table>
  }

  renderBody = (props,state) => {
    if ( !props.data || !props.data.headers || !props.data.data ) return null
    const { site, dataView, data } = props
    const title = `${site.name}: ${dataView.name}`
    const tableData = this.renderTable(data,site)
    return <DataTableModalContainer>
      <HelmetTitle title={title} />
      <ContainerHeading
        title={title}
        actionIcons={
          <ContainerHeadingActionIcon onClick={this.props.closeDataViewHandler}><CloseCircle /></ContainerHeadingActionIcon>
        }
      />
      <TableContainer>
        {tableData}
      </TableContainer>
    </DataTableModalContainer>
  }

  render = () => this.renderBody(this.props,this.state)

}
