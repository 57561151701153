import axios from 'axios'

export function fetchMetricDisplayStatuses() {
  return (dispatch, getState) => {
    dispatch({
      type: 'METRIC_DISPLAY_STATUSES_FETCH',
      payload: axios(`/metric_display_statuses`)
    })
  }
}

