import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, GridContainer, GridSection, Column, ListRow, ColumnStyle, ColumnResponsiveInnerContainer, ActionColumnStyleRightAligned, ActionIconContainer, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import { companyBillingRequired } from "../../../utils/companyUtils";

class AccountIndex extends React.Component {

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  renderAccountMenu(user) {

    const permissions = user.permissions || {}

    const permissionAccountBilling = permissions.owner || permissions.account_billing
    const permissionUserManagement = permissions.owner || permissions.user_management

    const company = user.company
    const accessPlanPresent = !!company.billing_item_access_plan
    const noBillingRequired = !companyBillingRequired(company);

    return <div>
      <H1>Account</H1>
      <GridContainer>
        <ContainerHeading title={`Account`} />
        {(permissionAccountBilling && !noBillingRequired) && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/account_setup_checklist')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Account Setup Checklist</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(permissionAccountBilling) && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/company_details')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Company Details</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(permissionAccountBilling && !noBillingRequired) && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/plan_and_extras')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Plan and Extras</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(permissionAccountBilling && !noBillingRequired)  && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/payment_details')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Payment Details</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(permissionAccountBilling && !noBillingRequired) && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/user_survey')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>User Survey (optional)</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(permissionAccountBilling && !noBillingRequired)  && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/start_trial_or_billing')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Start Trial or Billing</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        {(accessPlanPresent && permissionUserManagement) && <GridSection>
          <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/user_management')}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>User Management</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        </GridSection>}
        <GridSection>
        <ListRow divisions={12} key={0} onClick={this.routeTo.bind(this,'/my/account/update_user_details')}>
          <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer>Update User Profile</ColumnResponsiveInnerContainer></Column>
          <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
        </ListRow>
      </GridSection>
      </GridContainer>
    </div>
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { previewMode } = apiAuth
    return <div>
      { this.renderAccountMenu(user) }
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>

    <HelmetTitle title={'Account'} />
    { this.renderBody(this.props,this.state) }
  </SiteLayout>

}

export default connect(
  (store, props) => {
    const { apiAuth } = store
    return {
      apiAuth,
    }
  },
)(AccountIndex)
