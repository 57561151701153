import React from 'react'
import { isBrowser } from "react-device-detect"

import { DraggableStarWrapper, DraggableStarMarkerDiv } from './styled-elements'

import StarMarker from '../icons/StarMarker'

const Marker = () => {

  return <DraggableStarWrapper zIndexLayer={'bottom'}>
    <DraggableStarMarkerDiv>
      <StarMarker
        outerFill={'rgba(255,255,255,0.3)'}
        innerFill={"#FA0064"}
      />
    </DraggableStarMarkerDiv>
  </DraggableStarWrapper>

}

export default class MapDraggableStar extends React.Component {

  constructor(props) {
    super(props)
    this.markerDiv = React.createRef()
  }

  renderBody = () => {

    return (
      <Marker
        divRef={this.markerDiv}
      />
    )
  }

  render() {
    return this.renderBody(this.props)
  }
}
