import React from 'react'

const StarMarker = props => {

  return (<svg viewBox="0 0 72 69" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="star" transform="translate(0.000110, 0.000000)" fillRule="nonzero">
      <path id="starouter" fill={props.outerFill} d="M71.8966593,26.0805551 C71.6485972,25.3168219 70.9885044,24.7603696 70.1939744,24.6449166 L47.8737262,21.4014058 L37.8916159,1.17576388 C37.5362571,0.45576495 36.8030394,-7.10542736e-15 36.0000719,-7.10542736e-15 C35.1972449,-7.10542736e-15 34.4638866,0.455905575 34.1085278,1.17576388 L24.1258551,21.4015464 L1.80602874,24.6449166 C1.01149866,24.7603696 0.351405889,25.3169625 0.103343756,26.0805551 C-0.144859001,26.8441478 0.0621406922,27.6824121 0.637014841,28.2428019 L16.7874909,43.9864505 L12.9754341,66.2168395 C12.8397311,67.0081352 13.1651369,67.808009 13.8146828,68.2798052 C14.4640881,68.7516013 15.3249931,68.8138981 16.0361327,68.4403987 L35.9999312,57.9447267 L55.9631673,68.4405393 C56.2718387,68.6028203 56.6087757,68.6828358 56.9445877,68.6828358 C57.3819308,68.6828358 57.8173052,68.5469923 58.1846171,68.2799458 C58.8341631,67.808009 59.1595688,67.0082758 59.0238659,66.2168395 L55.210684,43.9864505 L71.3628476,28.2429426 C71.9378624,27.6824121 72.1448621,26.8441478 71.8966593,26.0805551 Z M51.4718927,41.739407 C50.9746434,42.224 50.747675,42.9223427 50.8652373,43.6066229 L54.1433419,62.7173915 L36.9817735,53.694483 C36.3672432,53.3714679 35.6330412,53.3714679 35.0185108,53.694483 L17.8559581,62.7173915 L21.1330782,43.6064823 C21.2503593,42.9222021 21.0235315,42.2241406 20.5265635,41.7395476 L6.6425372,28.2052551 L25.8300869,25.4168061 C26.5171796,25.3169625 27.1108975,24.8855257 27.4183033,24.2629797 L35.9999312,6.8752867 L44.5812779,24.2628391 C44.8886837,24.885385 45.4824016,25.3169625 46.1694943,25.4168061 L65.3573253,28.2051145 L51.4718927,41.739407 Z" ></path>
      <path id="starfill" fill={props.innerFill} d="M51.4718927,41.7104382 C50.9746434,42.1950313 50.747675,42.893374 50.8652373,43.5776542 L54.1433419,62.6884228 L36.9817735,53.6655143 C36.3672432,53.3424991 35.6330412,53.3424991 35.0185108,53.6655143 L17.8559581,62.6884228 L21.1330782,43.5775136 C21.2503593,42.8932334 21.0235315,42.1951719 20.5265635,41.7105789 L6.6425372,28.1762864 L25.8300869,25.3878374 C26.5171796,25.2879938 27.1108975,24.8565569 27.4183033,24.234011 L35.9999312,6.84631799 L44.5812779,24.2338704 C44.8886837,24.8564163 45.4824016,25.2879938 46.1694943,25.3878374 L65.3573253,28.1761458 L51.4718927,41.7104382 Z" ></path>
    </g>
  </g>
</svg>)

}

export default StarMarker
