import React, { useMemo } from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, PlanDetailsDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton } from '../../../styles/styled-elements'
import { compareArrays } from '../../../utils/arrayUtils'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

const FormElement = ({ formInputChangeHandler, formCheckboxChangeHandler, setSelectedStateValue, updateCardState, enableShowNewCardEntry, validate, onCreatePaymentMethod, onCardError, onCreatePaymentMethodError, updateLoading, state }) => {

  const stripe = useStripe()
  const elements = useElements()

  const getLocalParams = () => {
    return {
      addressSameAsCompanyAddress: state.addressSameAsCompanyAddress,
      nameOnCard: state.nameOnCard,
      addressLine1: state.addressLine1,
      addressLine2: state.addressLine2,
      addressLocality: state.addressLocality,
      addressStateProvince: state.addressStateProvince,
      addressPostalCode: state.addressPostalCode,
    }
  }

  const handleStripeSubmit = (event) => {

    updateLoading.call(this,true)

    const exec = async () => {

      event.preventDefault()

      updateLoading.call(this,true)

      if (elements == null) {
        return
      }

      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })

      if (paymentMethod) {
        const localParams = getLocalParams()
        onCreatePaymentMethod.call(this,localParams,paymentMethod)
        updateLoading.call(this,false)
      }

      if (error) {
        onCardError.call(this,error)
      }

    }

    validate(() => {
      exec.call(this)
    })

  }

  const handleLocalParamsOnlySubmit = () => {
    validate(() => {
      const localParams = getLocalParams()
      onCreatePaymentMethod.call(this,localParams)
    })
  }

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const formOnSubmit = (event) => {
    event.preventDefault()
    if (state.cardInfoHasChanged && state.cardInfoComplete) {
      handleStripeSubmit(event)
    } else if (state.hasSavedPaymentMethod) {
      handleLocalParamsOnlySubmit(event)
    }
  }

  const formatCardLength = (last4,cardBrand) => {
    let ret
    let padStr = last4 || ''
    switch (cardBrand) {
      case 'amex':
        ret = padStr.padStart(15,"*")
        break
      default:
        ret = padStr.padStart(16,"*")
    }
    return ret
  }

  const classes = useStyles()

  const cardIsDeclining = state.paymentMethodStatus==='declining'

  return <FormContainer>
    <FormInner>
      <Form onSubmit={formOnSubmit}>
        {state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper style={{ paddingTop: 20, paddingBottom: 20 }}>
          <FormLabel id="access-plan-radio-buttons-heading">Credit Card Details</FormLabel>
          <div style={{ fontSize: 14, marginTop: 10, marginBottom: 16, color: "#666" }}>Please note: Your full credit card details are NOT stored with COtL, they are securely stored with our credit card processing partner, Stripe.</div>

            {(cardIsDeclining) && <div style={{ border: '1px solid black', marginBottom: '20px', backgroundColor: 'rgba(255, 46, 56, 0.3)', fontSize: '0.9em', padding: '10px' }}>
              Your last payment was unsuccessful. Please check your card details, and if required, enter new card details to ensure continued service.
            </div>}

            {
              state.showNewCardEntry ?
              <div>
                <FieldDiv style={{ paddingTop: 20, marginBottom: 20 }}>
                <CardElement
                  options={{hidePostalCode: true}}
                  onChange={(cardInfo) => {
                    updateCardState(cardInfo)
                  }}
                />
                <FormHelperText error>{state.errors && state.errors.cardDetails}</FormHelperText>
                </FieldDiv>
                <FieldDiv style={{ paddingTop: 16}}>
                  <TextField
                    variant="standard"
                    label="Name on card"
                    name="nameOnCard"
                    required
                    inputProps={{
                      autoCorrect: 'off',
                      autoCapitalize: 'words',
                      autoComplete: 'nameOnCard',
                    }}
                    value={state.nameOnCard || ''}
                    onChange={formInputChangeHandler}
                    error={(state.errors && state.errors.nameOnCard)}
                    style={{ width: '100%' }}
                    InputProps={{ className: classes.textFieldInput }}
                    InputLabelProps={{ className: classes.textFieldInputLabel }}
                  />
                  <FormHelperText error>{state.errors && state.errors.nameOnCard}</FormHelperText>
                </FieldDiv>
              </div> :
              <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                <Cards
                  cvc={''}
                  expiry={`${state.cardExpMonth}${state.cardExpYear}`.padStart(6,"0")}
                  locale={{valid: 'EXPIRES'}}
                  name={state.nameOnCard}
                  number={formatCardLength(state.cardLast4,state.cardBrand.toLowerCase())}
                  preview={true}
                  issuer={state.cardBrand.toLowerCase()}
                />
                <div style={{ fontSize: 14, paddingLeft: 6, fontWeight: 500, marginTop: 16, cursor: 'pointer', textDecoration: 'underline' }} onClick={enableShowNewCardEntry}>Change Card Details</div>
              </div>
            }
            <FormHelperText error>{state.errors && state.errors.cardInfoComplete}</FormHelperText>
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel id="access-plan-radio-buttons-heading">Address of Credit Card Holder</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox
                  checked={state.addressSameAsCompanyAddress}
                  name="addressSameAsCompanyAddress"
                  onChange={formCheckboxChangeHandler}
                />}
                label="Same as company address"
              />
            </FormGroup>
        </FieldsWrapper>
        <FieldsWrapper show={(!state.addressSameAsCompanyAddress)}>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Address Line 1"
              name="addressLine1"
              required={!state.addressSameAsCompanyAddress}
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'addressLine1',
              }}
              value={state.addressLine1 || ''}
              onChange={formInputChangeHandler}
              error={(state.errors && state.errors.addressLine1)}
              style={{ width: '100%', marginTop: 10 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.addressLine1}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Address Line 2"
              name="addressLine2"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'addressLine2',
              }}
              helperText="Optional"
              value={state.addressLine2 || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.addressLine2}
              style={{ width: '100%', marginTop: 10 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.addressLine2}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="City/Town/Locality"
              name="addressLocality"
              required={!state.addressSameAsCompanyAddress}
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'addressLocality',
              }}
              value={state.addressLocality || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.addressLocality}
              style={{ width: '100%', marginTop: 10 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.addressLocality}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <Autocomplete
              name="addressStateProvince"
              options={[
                'ACT',
                'NSW',
                'NT',
                'QLD',
                'SA',
                'VIC',
                'TAS',
                'WA',
              ]}
              onChange={(event, newValue) => {
                setSelectedStateValue(newValue)
              }}
              value={state.addressStateProvince}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  variant="standard"
                  required={!state.addressSameAsCompanyAddress}
                  InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
                  InputLabelProps={{ ...params.InputLabelProps, className: classes.textFieldInputLabel }}
                />
              )}
              style={{ width: '100%', marginTop: 10, width: 200 }}
            />
            <FormHelperText error>{state.errors && state.errors.state}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              variant="standard"
              label="Postal Code"
              name="addressPostalCode"
              required={!state.addressSameAsCompanyAddress}
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'addressPostalCode',
              }}
              value={state.addressPostalCode || ''}
              onChange={formInputChangeHandler}
              error={state.errors && state.errors.addressPostalCode}
              style={{ width: '100%', marginTop: 10, width: 200 }}
              InputProps={{ className: classes.textFieldInput }}
              InputLabelProps={{ className: classes.textFieldInputLabel }}
            />
            <FormHelperText error>{state.errors && state.errors.addressPostalCode}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Update</RoundedFormButton>
        </SubmitWrapper>
      </Form>
    </FormInner>
  </FormContainer>
}

export default class PaymentDetailsForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showNewCardEntry: true,
      cardInfoHasChanged: false,
      cardInfoComplete: false,
      hasSavedPaymentMethod: false,
      paymentMethodType: '',
      cardBrand: '',
      cardLast4: '',
      cardExpMonth: '',
      cardExpYear: '',
      paymentMethodStatus: '',
      lastChargedAt: '',
      lastDeclinedAt: '',
      lastErrorCode: '',
      lastDeclineCode: '',
      lastErrorCodeLabel: '',
      lastDeclineCodeLabel: '',
      nameOnCard: '',
      addressSameAsCompanyAddress: true,
      addressLine1: '',
      addressLine2: '',
      addressLocality: '',
      addressStateProvince: '',
      addressPostalCode: '',
      addressCountry: '',
      origValues: {
        nameOnCard: '',
        addressSameAsCompanyAddress: true,
        addressLine1: '',
        addressLine2: '',
        addressLocality: '',
        addressStateProvince: '',
        addressPostalCode: '',
        addressCountry: '',
      },
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  classes = makeStyles(() => ({
    input1: {
      height: 50
    },
    input2: {
      height: 200,
      fontSize: "3em"
    }
  }))

  componentDidMount(prevProps) {
    this.populateForm()
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const { cardInfoHasChanged, cardInfoComplete, hasSavedPaymentMethod, nameOnCard, addressSameAsCompanyAddress, addressLine1, addressLine2, addressLocality, addressStateProvince, addressPostalCode, origValues } = state
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      if (cardInfoHasChanged) hasChanged = true
      if (nameOnCard!==props.nameOnCard && nameOnCard!==origValues.nameOnCard) hasChanged = true
      if (addressSameAsCompanyAddress!==props.addressSameAsCompanyAddress && addressSameAsCompanyAddress!==origValues.addressSameAsCompanyAddress) hasChanged = true
      if (addressLine1!==props.addressLine1 && addressLine1!==origValues.addressLine1) hasChanged = true
      if (addressLine2!==props.addressLine2 && addressLine2!==origValues.addressLine2) hasChanged = true
      if (addressLocality!==props.addressLocality && addressLocality!==origValues.addressLocality) hasChanged = true
      if (addressStateProvince!==props.addressStateProvince && addressStateProvince!==origValues.addressStateProvince) hasChanged = true
      if (addressPostalCode!==props.addressPostalCode && addressPostalCode!==origValues.addressPostalCode) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      if (
        (cardInfoComplete || (hasSavedPaymentMethod && !cardInfoHasChanged)) &&
        (nameOnCard) &&
        (addressSameAsCompanyAddress || (
          addressLine1 &&
          addressLocality &&
          addressStateProvince &&
          addressPostalCode
        ))) {
        canSubmit = true
      }
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props!==prevProps) {
      if (
          ( props.hasSavedPaymentMethod!==prevProps.hasSavedPaymentMethod ) ||
          ( props.cardBrand!==prevProps.cardBrand ) ||
          ( props.cardLast4!==prevProps.cardLast4 ) ||
          ( props.cardExpMonth!==prevProps.cardExpMonth ) ||
          ( props.cardExpYear!==prevProps.cardExpYear ) ||
          ( props.addressSameAsCompanyAddress!==prevProps.addressSameAsCompanyAddress ) ||
          ( props.nameOnCard!==prevProps.nameOnCard ) ||
          ( props.addressLine1!==prevProps.addressLine1 ) ||
          ( props.addressLine2!==prevProps.addressLine2 ) ||
          ( props.addressLocality!==prevProps.addressLocality ) ||
          ( props.addressStateProvince!==prevProps.addressStateProvince ) ||
          ( props.addressPostalCode!==prevProps.addressPostalCode ) ||
          ( props.addressCountry!==prevProps.addressCountry )
        ) {
          stateHash['nameOnCard'] = props.nameOnCard || ''
          stateHash['addressLine1'] = props.addressLine1 || ''
          stateHash['addressLine2'] = props.addressLine2 || ''
          stateHash['addressLocality'] = props.addressLocality || ''
          stateHash['addressStateProvince'] = props.addressStateProvince || ''
          stateHash['addressPostalCode'] = props.addressPostalCode || ''
          stateHash['addressCountry'] = props.addressCountry || ''
          const origValuesHash = { ...stateHash }
          stateHash['showNewCardEntry'] = !props.hasSavedPaymentMethod
          stateHash['hasSavedPaymentMethod'] = props.hasSavedPaymentMethod
          stateHash['paymentMethodType'] = props.paymentMethodType || ''
          stateHash['cardBrand'] = props.cardBrand || ''
          stateHash['cardLast4'] = props.cardLast4 || ''
          stateHash['cardExpMonth'] = props.cardExpMonth || ''
          stateHash['cardExpYear'] = props.cardExpYear || ''
          stateHash['paymentMethodStatus'] = props.paymentMethodStatus || ''
          stateHash['addressSameAsCompanyAddress'] = props.addressSameAsCompanyAddress
          stateHash['origValues'] = origValuesHash
          stateHash['errors'] = props.errors
          stateHash['cardInfoHasChanged'] = false
          stateHash['hasChanged'] = false
          stateHash['canSubmit'] = false
        }
    }
    if (Object.keys(stateHash).length) {
     this.setState(stateHash,() => {
     })
    }
  }

  siteSlugToAutocompleteItem = (siteSlug) => {
    const siteOption = this.props.siteOptions.find(({value}) => value===siteSlug) || null
    return siteOption
  }

  populateForm = () => {
    const { props } = this
    const stateHash = {}
    stateHash['nameOnCard'] = props.nameOnCard || ''
    stateHash['addressLine1'] = props.addressLine1 || ''
    stateHash['addressLine2'] = props.addressLine2 || ''
    stateHash['addressLocality'] = props.addressLocality || ''
    stateHash['addressStateProvince'] = props.addressStateProvince || ''
    stateHash['addressPostalCode'] = props.addressPostalCode || ''
    stateHash['addressCountry'] = props.addressCountry || ''
    const origValuesHash = { ...stateHash }
    stateHash['showNewCardEntry'] = !props.hasSavedPaymentMethod // || (props.paymentMethodStatus==='declining')
    stateHash['hasSavedPaymentMethod'] = props.hasSavedPaymentMethod
    stateHash['paymentMethodType'] = props.paymentMethodType || ''
    stateHash['cardBrand'] = props.cardBrand || ''
    stateHash['cardLast4'] = props.cardLast4 || ''
    stateHash['cardExpMonth'] = props.cardExpMonth || ''
    stateHash['cardExpYear'] = props.cardExpYear || ''
    stateHash['paymentMethodStatus'] = props.paymentMethodStatus || ''
    stateHash['lastChargedAt'] = props.lastChargedAt
    stateHash['lastDeclinedAt'] = props.lastDeclinedAt
    stateHash['lastErrorCode'] = props.lastErrorCode || ''
    stateHash['lastDeclineCode'] = props.lastDeclineCode || ''
    stateHash['lastErrorCodeLabel'] = props.lastErrorCodeLabel || ''
    stateHash['lastDeclineCodeLabel'] = props.lastDeclineCodeLabel || ''
    stateHash['addressSameAsCompanyAddress'] = props.addressSameAsCompanyAddress
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['hasChanged'] = false
    stateHash['canSubmit'] = false
    this.setState(stateHash, () => {
    })
  }

  updateCardState = (cardInfo) => {
    const updateHash = {
      cardInfoHasChanged: true,
      hasChanged: true,
      errors: { ...this.state.errors }
    }
    updateHash['errors']['cardDetails'] = ''
    if (cardInfo.complete) {
      updateHash['cardInfoComplete'] = true
    } else {
      updateHash['cardInfoComplete'] = false
    }
    this.setState(updateHash)
  }

  enableShowNewCardEntry = () => {
    const updateHash = { showNewCardEntry: true }
    this.setState(updateHash,() => {
    })
  }

  formInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      hasChanged: true,
    })
  }

  formCheckboxChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      hasChanged: true,
    })
  }

  setSelectedStateValue = (v) => {
    this.setState({
      addressStateProvince: v ? v : '',
      hasChanged: true,
    },() => {
    })
  }

  validate = (callback) => {
    const errors = {}
    const { cardInfoHasChanged, cardInfoComplete, hasSavedPaymentMethod, nameOnCard, addressSameAsCompanyAddress, addressLine1, addressLine2, addressLocality, addressStateProvince, addressPostalCode } = this.state
    if  ((!cardInfoComplete) && (!hasSavedPaymentMethod || cardInfoHasChanged)) {
      errors.cardInfoComplete = 'Credit Card details must be completed'
    }
    if (!addressSameAsCompanyAddress) {
      if (addressLine1.length < 5) {
        errors.address1 = 'Address is too short'
      }
      if (addressLocality.length < 2) {
        errors.addressLocality = 'Locality is too short'
      }
      if (addressPostalCode.trim().length < 4) {
        errors.addressPostalCode = 'Postcode is too short'
      }
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  onCreatePaymentMethod = (localParams,paymentMethod=null) => {
    const { dispatch, apiAuth, createPaymentMethodHandler } = this.props
    createPaymentMethodHandler.call(this,localParams,paymentMethod)
  }

  onCardError = (error) => {
    let errorStr = ''
    if (error['decline_code'] == "card_not_supported") {
      errorStr = "This type of card is not supported. Please use a Visa, MasterCard, American Express or JCB card."
    } else if (error['decline_code'] == "do_not_honor") {
      errorStr = "This card was declined with the error \"Do Not Honour\". Please try a different card"
    }
    this.setState({ errors: { cardDetails: errorStr } })
    this.props.updateLoading.call(this,false)
  }

  onCreatePaymentMethodError = (error) => {
    this.props.updateLoading.call(this,false)
  }

  renderBody = (props,state) => {
    return <Elements stripe={props.stripePromise}>
      <FormElement
        formInputChangeHandler={this.formInputChangeHandler}
        formCheckboxChangeHandler={this.formCheckboxChangeHandler}
        setSelectedStateValue={this.setSelectedStateValue}
        updateCardState={this.updateCardState}
        enableShowNewCardEntry={this.enableShowNewCardEntry}
        validate={this.validate}
        onCreatePaymentMethod={this.onCreatePaymentMethod}
        onCardError={this.onCardError}
        onCreatePaymentMethodError={this.onCreatePaymentMethodError}
        updateLoading={this.props.updateLoading}
        state={state}
      />
    </Elements>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }

}
