import React from 'react'
import { connect } from 'react-redux'

import SiteContainer from '../../components/layout/SiteContainer'

class SiteLayout extends React.Component {

  render = () => {
    return <SiteContainer
      noPadding={this.props.noPadding}
      children={this.props.children}
      dispatch={this.props.dispatch}
      apiAuth={this.props.apiAuth}
      menuOpen={this.props.menuOpen}
      appSettings={this.props.appSettings}
  />}
}

export default connect(
  (store, props) => {
  return ({
    apiAuth: store.apiAuth,
    menuOpen: store.menu.open,
    router: store.router,
    appSettings: store.appSettings,
  })},
)(SiteLayout)
