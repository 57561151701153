import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validateToken } from '../../../actions/AuthActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, TopLevelActionsButtonContainer, GridContainer, GridSection, Column, ListRow, ColumnStyle, ColumnResponsiveInnerContainer, ActionColumnStyleRightAligned, ActionIconContainer, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import { RoundedOutlineButton } from '../../../styles/styled-elements'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import { combineUserSets } from '../../../utils/userManagementUtils'

class UserManagement extends React.Component {

  componentDidMount() {
    this.props.dispatch(validateToken())
  }

  routeToInviteUserView = () => {
    const { company } = this.props.apiAuth.user

    if (this.getUsers().length >= this.getUserLimit()) return
    this.props.dispatch(push(`${this.props.match.url}/invite`))
  }

  routeToUserView = (user) => {
    this.props.dispatch(push(`${this.props.match.url}/edit/${encodeURIComponent(user.email)}`))
  }

  getUsers = () => {
    const { company } = this.props.apiAuth.user
    if (!company) return []
    return combineUserSets(company).sort((a,b) => (a.email < b.email) ? 1 : -1).filter(({permissions}) => (!permissions.removal_created_at))
  }

  getUserLimit = () => {
    const defaultLimit = 3
    const { company } = this.props.apiAuth.user
    if (!company) return defaultLimit
    const billingItemAccessPlan = company.billing_item_access_plan
    if (billingItemAccessPlan.user_limit) {
      return billingItemAccessPlan.user_limit
    } else if (company.default_access_plan_user_limit) {
      return billingItemAccessPlan.user_limit
    } else {
      return defaultLimit
    }
  }

  renderUserLabel = (rowUser) => {
    let ret
    if (rowUser.first_name) {
      ret = rowUser.first_name
      if (rowUser.surname) ret += ` ${rowUser.surname}`
      ret += ` (${rowUser.email})`
    } else {
      ret = rowUser.email
    }

    const permissions = rowUser.permissions || {}
    const userIsOwner = permissions.owner
    const { user } = this.props.apiAuth
    const userIsSelf = (user.email===rowUser.email)

    const userHasNotAccepted = (permissions.company_invitation_created_at && !permissions.company_invitation_accepted_at)
    if (userIsOwner) {
      ret += ' [Owner]'
    }
    if (userIsSelf) {
      ret += ' [Me]'
    }
    if (userHasNotAccepted) {
      ret += ' [Not yet accepted]'
    }
    return ret
  }

  renderUserRows(rowUsers) {

    return rowUsers
      .sort((a,b) => {
        if (a.surname < b.surname) return -1
        if (a.surname > b.surname) return 1
        return 0
      })
      .map((rowUser,index) => {
        const permissions = rowUser.permissions || {}
        const userIsOwner = permissions.owner
        const { user } = this.props.apiAuth
        const userIsSelf = (user.email===rowUser.email)
        const userHasNotAccepted = (permissions.company_invitation_created_at && !permissions.company_invitation_accepted_at)
        if (userIsOwner || userIsSelf) {
          return <ListRow divisions={12} key={index} standardPointer>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer faded={userHasNotAccepted}>{this.renderUserLabel(rowUser)}</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} />
          </ListRow>
        } else {
          return <ListRow divisions={12} key={index} onClick={this.routeToUserView.bind(this,rowUser)}>
            <Column xs={10} style={ColumnStyle}><ColumnResponsiveInnerContainer faded={userHasNotAccepted}>{this.renderUserLabel(rowUser)}</ColumnResponsiveInnerContainer></Column>
            <Column xs={2} style={ActionColumnStyleRightAligned}><ActionIconContainer><ArrowRightBoldCircle right /></ActionIconContainer></Column>
          </ListRow>
        }
    })

  }

  renderUsersList(users) {
    return <div>
      <TopLevelActionsButtonContainer><RoundedOutlineButton onClick={this.routeToInviteUserView.bind(this)} enabled={(users.length < this.getUserLimit())}>Invite New User</RoundedOutlineButton></TopLevelActionsButtonContainer>
      <GridContainer>
        <ContainerHeading title={`Users`} />
        <GridSection>
          {this.renderUserRows(users)}
        </GridSection>
      </GridContainer>
    </div>
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    const billingItemAccessPlan = company.billing_item_access_plan
    if (!billingItemAccessPlan) return <div>This feature is only available when a billing plan has been selected.</div>
    return <div>
      <BackToAccountNavLink />
      <H1>User Management</H1>
      { this.renderUsersList(this.getUsers()) }
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'User Management'} />
    { this.renderBody(this.props,this.state) }
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
  }),
)(UserManagement)
