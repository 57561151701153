import React from 'react'
import { FormInner, FormContainer, Form, FormHeading, FieldsWrapper, FieldDiv, SubmitWrapper } from '../styled-elements'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles'
import { RoundedFormButton, RoundedOutlineButton } from '../../../styles/styled-elements'

const DeleteUserConfirmation = () => {
  return <div>
    <h3>Confirm Deletion</h3>
  </div>
}

const FormElement = ({ editingUser, formCheckboxChangeHandler, handleSubmit, handleShowConfirmationView, userPermissions, state }) => {

  const useStyles = makeStyles((theme) => {
    return ({
      textFieldInput: {
        fontSize: 13,
        height: '1.4em',
      },
      textFieldInputLabel: {
        fontSize: '13px !important'
      }
    })
  })

  const classes = useStyles()

  const userIsOwner = !!userPermissions.owner
  const permissionUserManagement = !!userPermissions.user_management

  return <FormContainer>
    <FormInner>
      <Form onSubmit={handleSubmit}>
        {state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <h3>Edit Permissions</h3>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionHomeLocationGrowingSeasons}
                name="permissionHomeLocationGrowingSeasons"
                onChange={formCheckboxChangeHandler}
              />}
              label="Home Location and Growing Seasons"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionAccountBilling}
                name="permissionAccountBilling"
                onChange={formCheckboxChangeHandler}
              />}
              label="Account and Billing"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.permissionUserManagement}
                name="permissionUserManagement"
                onChange={formCheckboxChangeHandler}
              />}
              label="User Management"
            />
          </FormGroup>
        </FieldsWrapper>
        <SubmitWrapper left padsmall>
          <RoundedFormButton enabled={(state.hasChanged && state.canSubmit)}>Update</RoundedFormButton>
        </SubmitWrapper>
        {(userIsOwner || permissionUserManagement) && <FieldsWrapper>
          <h3>Actions</h3>

          {(userIsOwner) && <SubmitWrapper left padsmall>
            <RoundedOutlineButton enabled={(editingUser)} type='button' onClick={handleShowConfirmationView.bind(this,'makeUserAccountOwner')}>Make Account Owner</RoundedOutlineButton>
          </SubmitWrapper>}
          {(userIsOwner || permissionUserManagement) && <SubmitWrapper left padsmall>
            <RoundedOutlineButton enabled={(editingUser)} type='button' onClick={handleShowConfirmationView.bind(this,'deleteUser')}>Delete User</RoundedOutlineButton>
          </SubmitWrapper>}
        </FieldsWrapper>}
      </Form>
    </FormInner>
  </FormContainer>
}

export default class UserManagementInviteNewForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      permissionHomeLocationGrowingSeasons: false,
      permissionAccountBilling: false,
      permissionUserManagement: false,
      origValues: {
        permissionHomeLocationGrowingSeasons: false,
        permissionAccountBilling: false,
        permissionUserManagement: false,
      },
      hasChanged: false,
      canSubmit: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidMount(prevProps) {
    const populateStateFromPropsHash = this.populateStateFromPropsHash(this.props)
    this.setState(populateStateFromPropsHash)
  }

  componentDidUpdate(prevProps,prevState) {
    const { props, state } = this
    const { permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement, origValues } = this.state
    const propsValues = this.populateStateFromPropsHash(this.props)
    const prevPropsValues = this.populateStateFromPropsHash(prevProps)
    const stateHash = {}
    let hasChanged = false
    let canSubmit = false
    if (this.state!==prevState) {
      if (permissionHomeLocationGrowingSeasons!==prevPropsValues.permissionHomeLocationGrowingSeasons && permissionHomeLocationGrowingSeasons!==origValues.permissionHomeLocationGrowingSeasons) hasChanged = true
      if (permissionAccountBilling!==prevPropsValues.permissionAccountBilling && permissionAccountBilling!==origValues.permissionAccountBilling) hasChanged = true
      if (permissionUserManagement!==prevPropsValues.permissionUserManagement && permissionUserManagement!==origValues.permissionUserManagement) hasChanged = true
      if (hasChanged!==state.hasChanged) {
        stateHash['hasChanged'] = hasChanged
      }
      canSubmit = true
      if (canSubmit!==state.canSubmit) {
        stateHash['canSubmit'] = canSubmit
      }
    }
    if (this.props!==prevProps) {
      if (
        (propsValues.permissionHomeLocationGrowingSeasons!==prevPropsValues.permissionHomeLocationGrowingSeasons) ||
        (propsValues.permissionAccountBilling!==prevPropsValues.permissionAccountBilling) ||
        (propsValues.permissionUserManagement!==prevPropsValues.permissionUserManagement)
      ) {
        const populateStateHash = this.populateStateFromPropsHash(this.props)
        Object.keys(populateStateHash).forEach((key) => {
          stateHash[key] = populateStateHash[key]
        })
      }
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash,() => {
        // console.log('state',this.state)
      })
    }
  }

  populateStateFromPropsHash = (props=this.props) => {
    const { editingUser } = props
    const permissions = editingUser && editingUser.permissions || {}
    const permissionHomeLocationGrowingSeasons = !!(permissions.home_location_growing_seasons)
    const permissionAccountBilling = !!(permissions.account_billing)
    const permissionUserManagement = !!(permissions.user_management)
    const stateHash = {}
    stateHash['permissionHomeLocationGrowingSeasons'] =  permissionHomeLocationGrowingSeasons
    stateHash['permissionAccountBilling'] =  permissionAccountBilling
    stateHash['permissionUserManagement'] = permissionUserManagement
    const origValuesHash = { ...stateHash }
    stateHash['origValues'] = origValuesHash
    stateHash['errors'] = props.errors
    stateHash['hasChanged'] = false
    stateHash['canSubmit'] = false
    return stateHash
  }


  formCheckboxChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      hasChanged: true,
    })
  }

  validate = (callback) => {
    const errors = {}
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement } = this.state
    const params = { permissionHomeLocationGrowingSeasons, permissionAccountBilling, permissionUserManagement }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  handleShowConfirmationView = (viewName) => {
    this.props.showConfirmationViewHandler.call(this,viewName)
  }

  updateLoading = (value) => {
    this.setState({ loading: !!value })
  }

  renderBody = (props,state) => <FormElement
    editingUser={props.editingUser}
    userPermissions={props.userPermissions || {}}
    formCheckboxChangeHandler={this.formCheckboxChangeHandler}
    handleSubmit={this.handleSubmit}
    handleShowConfirmationView={this.handleShowConfirmationView}
    state={state}
  />

  render() {
    return this.renderBody(this.props,this.state)
  }
}
