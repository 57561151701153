import axios from 'axios'

export function fetchAppSettings() {
  return (dispatch, getState) => {
    dispatch({
      type: 'APP_SETTINGS_FETCH',
      payload: axios(`/app_settings`)
    })
  }
}
