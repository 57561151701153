const initialState = {
  open: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'MENU_OPEN':
      return {
        open: true,
      }

    case 'MENU_CLOSE':
      return {
        open: false,
      }

    case 'MENU_TOGGLE':
      return {
        open: !state.open,
      }
    default:
      return state
  }
}
