import React from 'react'
import GoogleMapReact from 'google-map-react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment-timezone'
import ReactGA from 'react-ga'
import { isMobile } from "react-device-detect"
import { DataViewModalContainer, ContainerHeadingActionIcon,
  DashboardContainer, DashboardToolbarContainer, DashboardToolbarExpandLinkCell, DashboardToolbarExpandLink,
  DashboardTopRow, DashboardMapContainer, MapComponentInnerContainer, DashboardMapMarkerDiv,
  KeyIndicatorStatusPanel, KeyIndicatorStatusPanelHeading, KeyIndicatorStatusPanelValue,
  KeyIndicatorStatusPanelValueUnit, KeyIndicatorStatusPanelStatusLabel, MetricsPanelContainer,
  MetricsPanelHeadingContainer, MetricsPanelHeading, MetricsPanelLastUpdated, MetricsPanelDiv,
  MetricsPanelRow, MetricsPanelLabelCell, MetricsPanelValueCell, MetricsPanelStatusCell, MetricsPanelFillCell,
  MetricIndicatorBox, DashboardGraphContainer, DashboardGraphHeaderRow, DashboardGraphHeadingCell,
  DashboardGraphHeading, DashboardGraphExpandLinkRow, DashboardGraphExpandLinkCell, DashboardGraphExpandLink,
  DashboardGraphInnerContainer } from './styled-elements'
import CloseCircle from '../icons/CloseCircle'
import LoadingIndicator from './LoadingIndicator'
import MapMarker from '../icons/MapMarker'
import ContainerHeading from '../layout/ContainerHeading'
import HelmetTitle from '../../components/layout/HelmetTitle'
import styledTheme from '../../styles/theme'
import Environment from '../../utils/environment'
import { getDynamicAssetHostPoolAddress } from '../../utils/assetUtils'
import { isSiteEligibleForAllTimeGraph } from '../../utils/userUtils'
import { isDataFresh } from "../../utils/mapUtils";

const Marker = () => <DashboardMapMarkerDiv><MapMarker /></DashboardMapMarkerDiv>

const MapComponent = ({ data, location }) => {
  return <MapComponentInnerContainer>
    <GoogleMapReact
      bootstrapURLKeys={{ key: Environment.GOOGLE_MAP_API_KEY }}
      center={location}
      zoom={10}
      options={{
        fullscreenControl: true,
        zoomControl: !isMobile,
        gestureHandling: "greedy",
      }}
    >
      {<Marker lat={location.lat} lng={location.lng} />}
    </GoogleMapReact>
  </MapComponentInnerContainer>}

const KeyIndicatorStatus = ({heading,data,dataIsFresh}) => {
  return <KeyIndicatorStatusPanel
    backgroundColor={dataIsFresh ? data.styles.background_color : "#FFF"}
    fontColor={dataIsFresh ? data.styles.font_color : "#666"}
  >
    <KeyIndicatorStatusPanelHeading>
      {heading}
    </KeyIndicatorStatusPanelHeading>
    <KeyIndicatorStatusPanelValue fontWeight={dataIsFresh ? data.styles.font_weight : 'normal'}>
    {
     dataIsFresh ?
      <span>{data.value}<KeyIndicatorStatusPanelValueUnit>{data.unit}</KeyIndicatorStatusPanelValueUnit></span> :
      <span>–</span>
    }
    </KeyIndicatorStatusPanelValue>
    <KeyIndicatorStatusPanelStatusLabel>
      {dataIsFresh ? data.status_label : ''}
    </KeyIndicatorStatusPanelStatusLabel>
  </KeyIndicatorStatusPanel>
}

const formatDateUpdated = (dateUpdatedStr,timezone) => {

  const unknownDateStr = "Updated date unknown"
  const aYearAgo = Moment(new Date()).subtract('1','years').format('x')

  const dateUpdatedMs = Date.parse(dateUpdatedStr)

  if (typeof dateUpdatedMs!=='number') return unknownDateStr
  if (dateUpdatedMs===0) return unknownDateStr
  if (dateUpdatedMs < aYearAgo) return unknownDateStr

  const dateUpdatedMoment = Moment.tz(dateUpdatedMs,timezone)
  const nowMoment = Moment.tz(new Date(),timezone)
  const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
  const diffDays = diffDuration ? diffDuration.asDays() : 0
  const diffHours = diffDuration ? diffDuration.asHours() : 0
  const diffMins = diffDuration ? diffDuration.asMinutes() : 0

  let updatedAtRelativeStr = ''

  if (diffHours < 2) {
    if (diffMins < 1) {
      updatedAtRelativeStr = `less than a minute ago`
    } else if (diffMins < 2) {
      updatedAtRelativeStr = `a minute ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
    }
  } else if (diffHours < 48) {
    updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
  } else {
    updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
  }

  let updatedAtTimeStr = `${dateUpdatedMoment.format("HH:mm")} on ${dateUpdatedMoment.format("DD MMM YYYY")}`

  if (diffHours < 240) {
    updatedAtTimeStr += ` (${updatedAtRelativeStr})`
  }

  return `Updated at ${updatedAtTimeStr}`

}

const Toolbar = ({dashboardToolbarExpandLinkClickHandler,siteSlug}) => <DashboardToolbarContainer>
  <a href="" onClick={dashboardToolbarExpandLinkClickHandler.bind(this)}>More charts/tables</a>
</DashboardToolbarContainer>

const MetricsPanel = ({site,statusArray,awsType,showUpgradeRequiredModalHandler,dataIsFresh}) => {
  if (!statusArray) return null
  return <MetricsPanelContainer>
    <MetricsPanelHeadingContainer>
      <MetricsPanelHeading>Summary – {site.name}</MetricsPanelHeading>
      <MetricsPanelLastUpdated>{formatDateUpdated(site.current_data_last_updated,site.timezone_name)}</MetricsPanelLastUpdated>
    </MetricsPanelHeadingContainer>
    <MetricsPanelDiv>
    {statusArray.sort(({sort_order}) => sort_order).map(({label, value_str, status_label, styles, included_in_feature_set, unsupported},index) => {
      if ((awsType==='bom') && (value_str==='B')) return null
      if ((awsType==='hilltop') && (value_str==='H')) return null
      if (unsupported) return null;
      let faded = false
      let showColorBar = true
      let showStatusLabel = true
      const includedInFeatureSet = included_in_feature_set
      if (!dataIsFresh || !includedInFeatureSet) {
        faded=true
        showColorBar=false
        showStatusLabel=false
      }

      const valueCellContent =  (includedInFeatureSet && dataIsFresh) ? value_str :
        includedInFeatureSet ? "–" : <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={showUpgradeRequiredModalHandler.bind(this)}>U</span>

      return <MetricsPanelRow key={index}>
        <MetricsPanelLabelCell faded={faded}>
          {label}
        </MetricsPanelLabelCell>
        <MetricsPanelValueCell faded={faded}>
          {valueCellContent}
        </MetricsPanelValueCell>
        <MetricsPanelStatusCell faded={faded}>
          {showColorBar && <MetricIndicatorBox backgroundColor={styles.background_color} />} {showStatusLabel && status_label}
        </MetricsPanelStatusCell>
        <MetricsPanelFillCell />
      </MetricsPanelRow>
    })}
    </MetricsPanelDiv>

  </MetricsPanelContainer>
}

const Graph = React.forwardRef(({ title, options, state, children, graphExpandButtonClickHandler, siteSlug, dataViewSlug, siteEligibleForAllTimeGraph }, ref) => {
  const dataViewName = title
  return <DashboardGraphContainer>
    <DashboardGraphHeaderRow>
      <DashboardGraphHeadingCell><DashboardGraphHeading onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Graph - {title}</DashboardGraphHeading></DashboardGraphHeadingCell>
    </DashboardGraphHeaderRow>
    <DashboardGraphExpandLinkRow>

      <DashboardGraphExpandLinkCell>
        {!siteEligibleForAllTimeGraph && <DashboardGraphExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Expanded 48H Graph</DashboardGraphExpandLink>}
        {siteEligibleForAllTimeGraph && <DashboardGraphExpandLink allHistory onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Expanded All-History Graph</DashboardGraphExpandLink>}
      </DashboardGraphExpandLinkCell>
    </DashboardGraphExpandLinkRow>

    <DashboardGraphInnerContainer id={`graph-container-${new Date().getTime()}`} ref={ref}>
      {children}
    </DashboardGraphInnerContainer>
  </DashboardGraphContainer>
  })

const RainfallGraph = React.forwardRef(({ title, options, state, children, graphExpandButtonClickHandler, siteSlug, dataViewSlug, siteEligibleForAllTimeGraph }, ref) => {
  const dataViewName = title
  return <DashboardGraphContainer>
    <DashboardGraphHeaderRow>
      <DashboardGraphHeadingCell><DashboardGraphHeading onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Graph - {title}</DashboardGraphHeading></DashboardGraphHeadingCell>
    </DashboardGraphHeaderRow>
    <DashboardGraphExpandLinkRow>
      <DashboardGraphExpandLinkCell>
        {!siteEligibleForAllTimeGraph && <DashboardGraphExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Expanded 48H Graph</DashboardGraphExpandLink>}
        {siteEligibleForAllTimeGraph && <DashboardGraphExpandLink allHistory onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Expanded All-History Graph</DashboardGraphExpandLink>}
      </DashboardGraphExpandLinkCell>
      <DashboardGraphExpandLinkCell><DashboardGraphExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,"rainfall-totals-daily")}>Daily Totals</DashboardGraphExpandLink></DashboardGraphExpandLinkCell>
      <DashboardGraphExpandLinkCell><DashboardGraphExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,"rainfall-totals-monthly")}>Monthly Totals</DashboardGraphExpandLink></DashboardGraphExpandLinkCell>
      <DashboardGraphExpandLinkCell><DashboardGraphExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,"rainfall-totals-yearly")}>Yearly Totals</DashboardGraphExpandLink></DashboardGraphExpandLinkCell>
    </DashboardGraphExpandLinkRow>
    <DashboardGraphInnerContainer id={`graph-container-${new Date().getTime()}`} ref={ref}>
      {children}
    </DashboardGraphInnerContainer>
  </DashboardGraphContainer>
  })


export default class Dashboard extends React.Component {

  constructor(props) {

    super(props)

    const layoutWidth=this.getLayoutWidth()
    const layoutHeight=this.getLayoutHeight()
    const isSmallScreen=(layoutWidth < styledTheme.breakpoints.lg)
    const mobileLandscapeView=(isSmallScreen && (layoutWidth > layoutHeight))

    this.state = {
      layoutWidth,
      layoutHeight,
      isSmallScreen,
      mobileLandscapeView,
      vtdGraphOptions: null,
      temperatureGraphOptions: null,
      windGraphOptions: null,
      relativeHumidityDeltaTGraphOptions: null,
      ghcopGraphOptions: null,
      gfdiGraphOptions: null,
      rainfallGraphOptions: null,
      dewPointGraphOptions: null,
      showSigmaW: true,
      showVTD: true,
      showGhcop: true,
    }

    this.sigmawGraphContainer = React.createRef()
    this.sigmawGraphChart = React.createRef()
    this.sigmaw2hrGraphContainer = React.createRef()
    this.sigmaw2hrGraphChart = React.createRef()
    this.vtdGraphContainer = React.createRef()
    this.vtdGraphChart = React.createRef()
    this.temperatureGraphContainer = React.createRef()
    this.temperatureGraphChart = React.createRef()
    this.windGraphContainer = React.createRef()
    this.windGraphChart = React.createRef()
    this.relativeHumidityDeltaTGraphContainer = React.createRef()
    this.relativeHumidityDeltaTGraphChart = React.createRef()
    this.ghcopGraphContainer = React.createRef()
    this.ghcopGraphChart = React.createRef()
    this.gfdiGraphContainer = React.createRef()
    this.gfdiGraphChart = React.createRef()
    this.rainfallGraphContainer = React.createRef()
    this.rainfallGraphChart = React.createRef()
    this.dewPointGraphContainer = React.createRef()
    this.dewPointGraphChart = React.createRef()

    Highcharts.wrap(Highcharts.PlotLineOrBand.prototype, 'render', function (proceed) {
      var chart = this.axis.chart;
      proceed.call(this);
      if (!chart.seriesGroup) {
        chart.seriesGroup = chart.renderer.g('series-group')
          .attr({ zIndex: 3 })
          .add();
      }
      if (this.svgElem.parentGroup !== chart.seriesGroup) {
        this.svgElem
            .attr({ zIndex: this.options.zIndex })
            .add(chart.seriesGroup);
      }
      return this;
    })

  }

  componentDidMount = () => {
    this.loadDashboardContent(this.state)
    window.addEventListener('resize', this.resizeHandler)
    //console.log("rainfallGraphChart",this.rainfallGraphChart)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeHandler)
    //console.log("rainfallGraphChart",this.rainfallGraphChart)
  }

  componentDidUpdate = (oldProps,oldState) => {
    this.loadDashboardContent(oldState)
    //console.log("rainfallGraphChart",this.rainfallGraphChart)
  }

  loadDashboardContent = (oldState) => {
    const awsType = this.props.site && this.props.site.aws_type
    const isMesonet = this.props.site && this.props.site.mesonet
    const showSigmaW = isMesonet
    const showVTD = isMesonet && (!['hilltop'].includes(awsType))
    const showGhcop = (isMesonet || ['greenbrain'].includes(awsType)) && (!['hilltop'].includes(awsType))
    const showGfdi = !['greenbrain'].includes(awsType)
    const showDewPoint = !['greenbrain'].includes(awsType)

    if (this.props.data && this.props.data.graphs && (
      (this.state.showSigmaW && !oldState.sigmawGraphOptions) ||
      (this.state.showSigmaW && !oldState.sigmaw2hrGraphOptions) ||
      (this.state.showVTD && !oldState.vtdGraphOptions) ||
      (this.state.showGhcop && !oldState.ghcopGraphOptions) ||
      (this.state.showGfdi && !oldState.gfdiGraphOptions) ||
      !oldState.temperatureGraphOptions ||
      !oldState.windGraphOptions ||
      !oldState.relativeHumidityDeltaTGraphOptions ||
      !oldState.rainfallGraphOptions ||
      (this.state.showDewPoint && !oldState.dewPointGraphOptions)
    )) {
      this.setState({
        sigmawGraphOptions: showSigmaW ? this.processedGraphOptions((this.props.data.graphs.ann_sigma_w || this.props.data.graphs.graph_ann_sigma_w || this.props.data.graphs.graph_sigmaw),this.sigmawGraphContainer,this.sigmawGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        sigmaw2hrGraphOptions: showSigmaW ? this.processedGraphOptions((this.props.data.graphs.ann_2hr_sigma_w || this.props.data.graphs.graph_ann_2hr_sigma_w),this.sigmaw2hrGraphContainer,this.sigmaw2hrGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        vtdGraphOptions: showVTD ? this.processedGraphOptions((this.props.data.graphs.vtd || this.props.data.graphs.graph_vtd),this.vtdGraphContainer,this.vtdGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        ghcopGraphOptions: showGhcop ? this.processedGraphOptions((this.props.data.graphs.ghcop || this.props.data.graphs.graph_ghcop),this.ghcopGraphContainer,this.ghcopGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        gfdiGraphOptions: showGfdi ? this.processedGraphOptions((this.props.data.graphs.gfdi || this.props.data.graphs.graph_gfdi),this.gfdiGraphContainer,this.gfdiGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        temperatureGraphOptions: this.processedGraphOptions((this.props.data.graphs.temperature || this.props.data.graphs.graph_temperature),this.temperatureGraphContainer,this.temperatureGraphChart,{ timezone_name: this.props.site.timezone_name }),
        windGraphOptions: this.processedGraphOptions((this.props.data.graphs.wind || this.props.data.graphs.graph_wind),this.windGraphContainer,this.windGraphChart,{ timezone_name: this.props.site.timezone_name }),
        relativeHumidityDeltaTGraphOptions: this.processedGraphOptions((this.props.data.graphs.relative_humidity_delta_t || this.props.data.graphs.graph_relative_humidity_delta_t),this.relativeHumidityDeltaTGraphContainer,this.relativeHumidityDeltaTGraphChart,{ timezone_name: this.props.site.timezone_name }),
        rainfallGraphOptions: this.processedGraphOptions((this.props.data.graphs.rainfall || this.props.data.graphs.graph_rainfall),this.rainfallGraphContainer,this.rainfallGraphChart,{ timezone_name: this.props.site.timezone_name }),
        dewPointGraphOptions: showDewPoint ? this.processedGraphOptions((this.props.data.graphs.dew_point || this.props.data.graphs.graph_dew_point),this.dewPointGraphContainer,this.dewPointGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        showSigmaW: showSigmaW,
        showVTD: showVTD,
        showGhcop: showGhcop,
        showGfdi: showGfdi,
        showDewPoint: showDewPoint,
      })
    }
    const highchartsCreditElArray = document.querySelectorAll('.highcharts-credits')
    if (highchartsCreditElArray.length) {
      highchartsCreditElArray.forEach((highchartsCredit) => {
        highchartsCredit.parentNode.removeChild(highchartsCredit)
      })
    }
  }

  getLayoutWidth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.offsetWidth
  }

  getLayoutHeight = () => {
    if (typeof window === 'undefined') return (styledTheme.breakpoints.lg*9/16)
    return window.offsetHeight
  }

  processedGraphOptions = (options,containerRef,chartRef,extraOptions = {}) => {

    if (!options) {
      return {}
    }

    options['scrollbar'] = { enabled: true }
    options['legend'] = { enabled: false }

    options['rangeSelector'] = {
      enabled: true,
      inputEnabled: false,
      buttons: [{
        type: 'day',
        count: 1,
        text: '1d'
      },{
        type: 'day',
        count: 2,
        text: '2d'
      },{
        type: 'week',
        count: 1,
        text: '1w'
      }]
    }

    const xAxes = options['xAxis']
    const maxUnixTimeMoment = Moment(new Date(xAxes[0]['max']))
    const twoDaysAgoUnixtime = maxUnixTimeMoment.clone().subtract(2,'days')
    const xAxisRange = maxUnixTimeMoment.diff(twoDaysAgoUnixtime)
    xAxes[0]['min'] = xAxes[0]['max']-xAxisRange
    xAxes['startOnTick'] = true
    xAxes['endOnTick'] = true
    xAxes['ordinal'] = false

    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight
      const containerWidth = containerRef.current.offsetWidth
      options.chart['height'] = containerHeight
      options.chart['width'] = containerWidth
    }

    let windDirectionSeries = options['series'].slice().filter(({id}) => id.match(/^wind_direction/))

    options.chart['events']['load'] = ((event) => {
      event.target['yAxis'].forEach((axis) => {
        if (axis.userOptions.id==="y_axis_km_h") {
          axis.update({
            min: axis['dataMin'],
            max: axis['dataMax'],
          })
        }
      })
    })

    if (windDirectionSeries.length) {
      let proceedWithIconRender = true
      let lastViewXAxisMin = null
      let lastViewXAxisMax = null
      if (options.chart['events']) options.chart['events']['redraw'] = ((event) => {
        const chart = event.target
        const targetXAxis = event.target.xAxis[0]
        if ((targetXAxis.min!==lastViewXAxisMin) || (targetXAxis.max!==lastViewXAxisMax)) proceedWithIconRender=true
        if (!proceedWithIconRender) return true
        proceedWithIconRender = false
        lastViewXAxisMin = targetXAxis.min
        lastViewXAxisMax = targetXAxis.max
        windDirectionSeries.forEach((optionsSeries) => {
          const newData = []
          const chartSeries = chart.get(optionsSeries.id)
          optionsSeries.data.filter((optionsSeriesDataPoint) => (optionsSeriesDataPoint[0] >= targetXAxis.min && optionsSeriesDataPoint[0] <= targetXAxis.max)).forEach((optionsSeriesDataPoint) => {
            const degrees = optionsSeriesDataPoint[2]
            const markerAngle = this.convertDegreesToIconAngle(degrees)
            const cardinal = optionsSeriesDataPoint[3]
            const marker = (typeof markerAngle==='number') ?
              {
                enabled: false,
                symbol: `url(${getDynamicAssetHostPoolAddress()}/icons/up-arrow-circular-button.png?rotation=${markerAngle})`,
                radius: 24,
              } :
              {
                enabled: false
              }
            const x = optionsSeriesDataPoint[0]
            const y = optionsSeriesDataPoint[1]
            newData.push({ x, y, marker, degrees, cardinal })
          })
          const noDataPoints = Math.floor(window.innerWidth/40)
          const incrementsPerItem = Math.floor(newData.length/noDataPoints)
          let iterator=0
          newData.forEach((chartDataPoint) => {
            if (iterator++===incrementsPerItem) {
              const degrees = chartDataPoint && chartDataPoint.degrees
              const markerAngle = this.convertDegreesToIconAngle(degrees)
              if (chartDataPoint && (typeof markerAngle==='number')) {
                chartDataPoint.marker = {
                  enabled: true,
                  symbol: `url(${getDynamicAssetHostPoolAddress()}/icons/up-arrow-circular-button.png?rotation=${markerAngle})`,
                  radius: 16,
                }
              }
              iterator=0
            }
          })
          chartSeries.setData(newData,false,null,false)
        })
        chart.redraw()
      })
    }

    options['yAxis'] = this.formatYAxis(options['yAxis'].slice())

    options['series'] = this.formatSeries(options['series'].slice())

    options['time'] = {
      getTimezoneOffset: function (timestamp) {
        return -Moment.tz(timestamp, extraOptions['timezone_name']).utcOffset()
      }
    }

    const xAxis = xAxes[0]

    const xAxisMin = xAxis.min
    const xAxisMax = xAxis.max
    const xAxisRangeDiff = xAxisMax-xAxisMin

    if (xAxisRangeDiff < 174000000) {
      options['rangeSelector'] = null
    } else if (xAxisRangeDiff < 610000000) {
      options['rangeSelector'] = {
        selected: 2,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [{
          type: 'millisecond',
          count: 86400000,
          text: '1d'
        },{
          type: 'millisecond',
          count: 172800000,
          text: '2d'
        },{
          type: 'millisecond',
          count: xAxisRangeDiff,
          text: 'All'
        }]
      }
    } else if (xAxisRangeDiff < 2764800000) {
      options['rangeSelector'] = {
        selected: 4,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [{
          type: 'millisecond',
          count: 86400000,
          text: '1d'
        }, {
          type: 'millisecond',
          count: 172800000,
          text: '2d'
        }, {
          type: 'millisecond',
          count: 604800016,
          text: '1w'
        }, {
          type: 'millisecond',
          count: 1209600033,
          text: '2w'
        },{
          type: 'millisecond',
          count: xAxisRangeDiff,
          text: 'All'
        }]
      }
    }

    return options

  }

  resizeHandler = (e) => {

    const graphRefSets = [
      [this.sigmawGraphContainer,this.sigmawGraphChart],
      [this.sigmaw2hrGraphContainer,this.sigmaw2hrGraphChart],
      [this.vtdGraphContainer,this.vtdGraphChart],
      [this.temperatureGraphContainer,this.temperatureGraphChart],
      [this.windGraphContainer,this.windGraphChart],
      [this.relativeHumidityDeltaTGraphContainer,this.relativeHumidityDeltaTGraphChart],
      [this.ghcopGraphContainer,this.ghcopGraphChart],
      [this.gfdiGraphContainer,this.gfdiGraphChart],
      [this.rainfallGraphContainer,this.rainfallGraphChart],
      [this.dewPointGraphContainer,this.dewPointGraphChart],
    ]

    graphRefSets.forEach((graphRefSets) => {

      const container = graphRefSets[0].current
      const graph = graphRefSets[1].current

      if ((!container) || (!graph)) return true

      let last3Widths = []
      let last3Heights = []

      const resizeFunction = () => {
        const containerWidth = container.offsetWidth
        const containerHeight = container.offsetHeight
        const graphWidth = containerWidth-2
        const graphHeight = containerHeight-2
        graph.chart.setSize(
          graphWidth,
          graphHeight,
        )
        graph.chart.reflow()

      }

      const intervalId = setInterval(() => {
        last3Widths.push(container.offsetWidth)
        last3Heights.push(container.offsetHeight)
        last3Widths = last3Widths.slice(-3)
        last3Heights = last3Heights.slice(-3)
        if ((last3Widths.length < 3) || (last3Heights.length < 3)) {
          resizeFunction()
        } else {
          const widthTotal = last3Widths.reduce((n,total) => total+n,0)
          const heightTotal = last3Heights.reduce((n,total) => total+n,0)
          if (((widthTotal/3)===last3Widths[2]) && ((heightTotal/3)===last3Heights[2])) {
            clearInterval(intervalId)
          } else {
            resizeFunction()
          }
        }
      },500)
    })
  }

  formatSeries = (seriesArray) => seriesArray.map(series => {
    if (series.id.match(/^wind_direction/)) {
      return {
        ...series,
        turboThreshold: 0,
        tooltip: {
          pointFormatter: function() {
            if (this.degrees==null) return null
            return `<span style="color:${this.color}">\u25CF </span>${series.name}: <b>${this.cardinal} (${this.degrees}\u00B0)</b><br/>`
          }
        }
      }
    }
    else if (series.id.match(/^ann_.*sigma/) || series.useCategoryLabels) {
      return {
        ...series,
        tooltip: {
          pointFormatter: function() {
            return `<span style="color:${this.color}">\u25CF </span>${series.name}: <b>${this.series.yAxis.categories[this.y]}</b><br/>`
          },
        }
      }
    }
    return series
  })

  formatYAxis = (yAxesArray) => yAxesArray.map(axis => {
    if (axis.id==="y_axis_km_h") {
      return {
        ...axis,
        labels: {
          formatter: function() {
            if (parseInt(this.value) >= 0) return this.value;
            return null;
          }
        }
      }
    }
    return axis
  })

  convertDegreesToIconAngle(degrees) {
    const ret = (typeof degrees==='number') ?
      (degrees<180) ?
        degrees+180 :
        degrees-180 :
      null
    return ret
  }

  siteDataViewsButtonClickHandler = () => {
    const { site, routeToSiteDataViewsListHandler } = this.props
    ReactGA.event({
      category: 'Site Action',
      action: `View Site Data Views List from Dashboard`,
      label: site.name,
    })
    routeToSiteDataViewsListHandler.call(this,site.slug)
  }

  graphExpandButtonClickHandler = (dataViewName,dataViewSlug) => {
    const { site, routeToDataViewHandler } = this.props
    const siteSlug = site.slug
    const siteName = site.name
    ReactGA.event({
      category: 'Site Action',
      action: `Expand Graph from Dashboard`,
      label: `${siteName}:${dataViewName}`,
      site: site.name,
      data_view_name: dataViewName,
    })
    routeToDataViewHandler.call(this,siteSlug,dataViewSlug)
  }

  renderBody = (props,state,refs) => {
    const { data, site, awsType, routeToSiteDataViewsListHandler, fetching, fetched } = props
    const title = (data && data.site_name) ? `Dashboard: ${data.site_name}` : ''
    const lastUpdated = Date.parse(site.current_data_last_updated)
    const siteEligibleForAllTimeGraph = isSiteEligibleForAllTimeGraph(props.user, site);

    const dataIsFresh = isDataFresh(awsType, lastUpdated, 3600000 * 3.5);

    return <DataViewModalContainer>
      <LoadingIndicator show={fetching} />
      <HelmetTitle title={title} />
      {<ContainerHeading
        title={title}
        actionIcons={
          <ContainerHeadingActionIcon onClick={this.props.closeDataViewHandler}><CloseCircle /></ContainerHeadingActionIcon>
        }
      />}
      <DashboardContainer>
        { data && data.site_summary && routeToSiteDataViewsListHandler && <Toolbar dashboardToolbarExpandLinkClickHandler={this.siteDataViewsButtonClickHandler} siteSlug={site.slug} />}
        <DashboardTopRow>
          {data && data.location && <DashboardMapContainer><MapComponent data={data} location={data.location} /></DashboardMapContainer>}
          {data && data.key_indicators && data.key_indicators.vtd && <KeyIndicatorStatus heading={'Inversion: Vertical Temperature Difference'} data={data.key_indicators.vtd} dataIsFresh={dataIsFresh} />}
          {data && data.key_indicators && data.key_indicators.ghcop && <KeyIndicatorStatus heading={'Grain Harvest Code of Practice (GHCoP, wind@2m)'} data={data.key_indicators.ghcop} dataIsFresh={dataIsFresh} />}
        </DashboardTopRow>
        { data && data.site_summary && <MetricsPanel site={site} statusArray={data.site_summary} awsType={awsType} showUpgradeRequiredModalHandler={props.showUpgradeRequiredModalHandler} dataIsFresh={dataIsFresh} />}

        {data && data.graphs && this.state.showSigmaW && (data.graphs.ann_sigma_w || data.graphs.graph_ann_sigma_w || data.graphs.graph_sigmaw) && <Graph
          title={"Hazardous Inversion"}
          state={state}
          ref={this.sigmawGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'hazardous-inversion'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.sigmawGraphOptions && <HighchartsReact
              ref={this.sigmawGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.sigmawGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }

        {data && data.graphs && this.state.showSigmaW && (data.graphs.ann_2hr_sigma_w || data.graphs.graph_ann_2hr_sigma_w) && <Graph
          title={"2hr Prediction Hazardous Inversion"}
          state={state}
          ref={this.sigmaw2hrGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'2hr-hazardous-inversion'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.sigmaw2hrGraphOptions && <HighchartsReact
              ref={this.sigmaw2hrGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.sigmaw2hrGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }

        {data && data.graphs && this.state.showVTD && (data.graphs.vtd || data.graphs.graph_vtd) && <Graph
          title={"Inversion: Vertical Temperature Difference"}
          state={state}
          ref={this.vtdGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'vertical-temperature-difference'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.vtdGraphOptions && <HighchartsReact
              ref={this.vtdGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.vtdGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }

        {data && data.graphs && this.state.showGhcop && (data.graphs.ghcop || data.graphs.graph_ghcop) && <Graph
          title={"Grain Harvest Code of Practice (GHCoP, wind@2m)"}
          state={state}
          ref={this.ghcopGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'grain-harvest-code-of-practice-ghcop-wind2m'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.ghcopGraphOptions && <HighchartsReact
              ref={this.ghcopGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.ghcopGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }

        {data && data.graphs && <Graph
          title={"Temperatures"}
          state={state}
          ref={this.temperatureGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'temperatures'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.temperatureGraphOptions && <HighchartsReact
              ref={this.temperatureGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.temperatureGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && (data.graphs.wind || data.graphs.graph_wind) && <Graph
          title={"Wind"}
          state={state}
          ref={this.windGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'wind'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.windGraphOptions && <HighchartsReact
              ref={this.windGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.windGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && (data.graphs.relative_humidity_delta_t || data.graphs.graph_relative_humidity_delta_t) && <Graph
          title={"Relative Humidity & Delta T"}
          state={state}
          ref={this.relativeHumidityDeltaTGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'relative-humidity-delta-t'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.relativeHumidityDeltaTGraphOptions && <HighchartsReact
              ref={this.relativeHumidityDeltaTGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.relativeHumidityDeltaTGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && (data.graphs.rainfall || data.graphs.graph_rainfall) && <RainfallGraph
          title={"Rainfall"}
          state={state}
          ref={this.rainfallGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'rainfall'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.rainfallGraphOptions && <HighchartsReact
              ref={this.rainfallGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.rainfallGraphOptions}
              allowChartUpdate={false}
            />
          }
        </RainfallGraph>
        }
        {data && data.graphs && this.state.showDewPoint && (data.graphs.dew_point || data.graphs.graph_dew_point) && <Graph
          title={"Dew Point"}
          state={state}
          ref={this.dewPointGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'dew-point'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.dewPointGraphOptions && <HighchartsReact
              ref={this.dewPointGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.dewPointGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && this.state.showGfdi && (data.graphs.gfdi || data.graphs.graph_gfdi) && <Graph
          title={"GFDI"}
          state={state}
          ref={this.gfdiGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'gfdi-wind10m'}
          siteEligibleForAllTimeGraph={siteEligibleForAllTimeGraph}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.gfdiGraphOptions && <HighchartsReact
              ref={this.gfdiGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.gfdiGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
      </DashboardContainer>
    </DataViewModalContainer>
  }

  render = () => this.renderBody(this.props,this.state,this.refs)

}
