import React from 'react'
import { connect } from 'react-redux'
import SignupForm from '../../components/auth/SignupForm'
import SiteLayout from '../../containers/layout/SiteLayout'
import { signOut } from '../../actions/AuthActions'
import HelmetTitle from '../../components/layout/HelmetTitle'

class Signup extends React.Component {

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Sign Up'} />
    <SignupForm
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
  }),
)(Signup)
