
export function combineUserSets(company) {
  const users = [...company.active_users]
  const permittedUsers = company.permitted_users || []
  permittedUsers.forEach((user) => {
    if (!users.find(({email}) => email===user.email)) {
      users.push(user)
    }
  })
  return users.filter(({removal_created_at}) => (!removal_created_at))
}
