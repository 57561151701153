import axios from 'axios'

export function fetchFlatContentPages() {
  return (dispatch, getState) => {
    dispatch({
      type: 'FLAT_CONTENT_PAGES_FETCH',
      payload: axios(`/flat_content_pages`)
    })
  }
}
