import React from 'react'

import {ColorBarContainerBottom,LegenBottomText } from './styled-elements'

export default class WindSpeedLegendTip extends React.Component {

  renderBody = ({ tip }) =>
    <ColorBarContainerBottom>
    <LegenBottomText>{tip}</LegenBottomText>
  </ColorBarContainerBottom>

  render() {
    return this.renderBody(this.props)
  }
}

