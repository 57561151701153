import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { confirmAlert } from 'react-confirm-alert';

import { fetchGrowingLocations } from '../../../actions/GrowingLocationActions'
import {
  fetchGrowingSeasons,
  updateGrowingSeason,
  deleteGrowingSeason,
  ConvertGrowingSeasonDetailModelData,
  GrowingSeasonDetailModelDefaultData,
  ConvertGrowingSeasonUpdateParams,
  IsGrowingSeasonLocationValid
} from '../../../actions/GrowingSeasonActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import GrowingSeasonsList from '../../../components/my/growingSeasons/GrowingSeasonsList'
import GrowingSeasonSettingsPanel from '../../../components/map/GrowingSeasonSettingsPanel'

class MyGrowingSeasons extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      growingSeasonSettingsModalVisible: false,
      activeGrowingSeason: GrowingSeasonDetailModelDefaultData(),
    }
  }

  componentDidMount = () => {
    this.props.dispatch(fetchGrowingSeasons())
    this.props.dispatch(fetchGrowingLocations())
  }

  routeToGrowingSeason = (growingSeason) => {
    this.props.dispatch(push(`/my/growing_seasons/${growingSeason.slug}`))
  }

  growingSeasonNameChangeHandler = event => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason['name'] = event.target.value
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonLocationChangeHandler = event => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason['location'] = event.target.value
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonDateChangeHandler = (fieldName,dateVal) => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason[fieldName] = dateVal.set({hour: 0, minute: 0})
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonRadioChangeHandler = (fieldName,option) => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason[fieldName] = option
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  getGrowingSeasonSettingsModal = (props,state) => {
    return <GrowingSeasonSettingsPanel
      growingSeasonName={state.activeGrowingSeason['name']}
      sowingDate={state.activeGrowingSeason['sowingDate']}
      emergenceDateOption={state.activeGrowingSeason['emergenceDateOption']}
      emergenceDate={state.activeGrowingSeason['emergenceDate']}
      floralInitiationDateOption={state.activeGrowingSeason['floralInitiationDateOption']}
      floralInitiationDate={state.activeGrowingSeason['floralInitiationDate']}
      harvestDateOption={state.activeGrowingSeason['harvestDateOption']}
      harvestDate={state.activeGrowingSeason['harvestDate']}
      locationOption={state.activeGrowingSeason['locationOption']}
      location={state.activeGrowingSeason['location']}
      nameChangeHandler={this.growingSeasonNameChangeHandler}
      dateChangeHandler={this.growingSeasonDateChangeHandler}
      radioChangeHandler={this.growingSeasonRadioChangeHandler}
      submitHandler={this.growingSeasonDetailsSubmitHandler}
      cancelHandler={this.growingSeasonDetailsCancelHandler}
      locationChangeHandler={this.growingSeasonLocationChangeHandler}
    />
  }

  editGrowingSeasonDetailsClickHandler = (growingSeason,growingLocations) => {
    this.setState({
      growingSeasonSettingsModalVisible: true,
      activeGrowingSeason: (this.state.activeGrowingSeason.remoteId !== growingSeason.remote_id ? ConvertGrowingSeasonDetailModelData(growingSeason,growingLocations) : this.state.activeGrowingSeason),
    })
  }

  growingSeasonDetailsSubmitHandler = () => {
    const { activeGrowingSeason } = this.state
    if (activeGrowingSeason.locationOption === "home" || (activeGrowingSeason.locationOption === "custom" && IsGrowingSeasonLocationValid(activeGrowingSeason.location))) {
      const updateParams = ConvertGrowingSeasonUpdateParams(activeGrowingSeason)
      const remoteId = activeGrowingSeason['remoteId']

      this.props.growingSeasons.list.forEach(gs=>{
        if(gs.remote_id === remoteId){
          gs.name = updateParams.name;
        }});

      this.props.dispatch(updateGrowingSeason(remoteId,updateParams))
      this.setState({
        growingSeasonSettingsModalVisible: false
      })
    }
  }

  growingSeasonDetailsCancelHandler = () => {
    this.setState({
      growingSeasonSettingsModalVisible: false
    })
  }

  deleteGrowingSeasonDetailsClickHandler = (growingSeason) => {
    const that = this;

    confirmAlert({
      title: 'Confirm to Delete Growing Seasons',
      message: 'Are you sure to delete it.',
      buttons: [
        {
          label: 'Delete',
          onClick: () => {
            that.props.growingSeasons.list.forEach(gs=>{
              if(gs.remote_id === growingSeason.remote_id){
                var index = that.props.growingSeasons.list.indexOf(gs);
                if (index !== -1) {
                  that.props.growingSeasons.list.splice(index, 1);
                  that.props.dispatch(deleteGrowingSeason(growingSeason.remote_id))
                  that.setState({growingSeasonSettingsModalVisible: false})
                }
              }});
          }
        },
        {
          label: 'Cancel',
          onClick: () => {
            that.setState({growingSeasonSettingsModalVisible: false})
          }
        }
      ]
    })
  }

  renderBody = (props,state) => {
    return <div>
    <LoadingScreen show={(props.growingSeasons.fetching && !props.growingSeasons.list.length)}/>
    <GrowingSeasonsList
      growingSeasons={props.growingSeasons}
      growingLocations={props.growingLocations}
      rowClickHandler={this.routeToGrowingSeason}
      editClickHandler={this.editGrowingSeasonDetailsClickHandler}
      deleteClickHandler={this.deleteGrowingSeasonDetailsClickHandler}
    />
    { state.growingSeasonSettingsModalVisible && this.getGrowingSeasonSettingsModal(props,state) }
  </div>}

  render = () => <SiteLayout>
    <HelmetTitle title={`Growing Seasons`} />
    {this.renderBody(this.props,this.state)}
  </SiteLayout>

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    sites: store.sites,
    router: store.router,
    growingSeasons: store.growingSeasons,
    growingLocations: store.growingLocations,
  }),
)(MyGrowingSeasons)
