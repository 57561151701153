import React from 'react'
import { signUp } from '../../actions/AuthActions'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, SubmitWrapper } from './styled-elements'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '../../styles/styled-elements'

export default class SignupForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      firstName: null,
      surname: null,
      companyName: null,
      phone: null,
      email: null,
      password: null,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps) {
    const authErrors = this.props.apiAuth.errors
    if (Array.isArray(authErrors) && authErrors!==prevProps.apiAuth.errors) {
      const errorsHash = this.generateErrorsHash(this.state,authErrors)
      if (Object.keys(errorsHash).length) {
        const updateHash = {
          hasErrors: true,
          errors: errorsHash,
        }
        this.setState(updateHash)
      }
    }
  }

  generateErrorsHash = (state,authErrors) => {

    const errorsHash = {}
    authErrors.forEach((field) => {
      let fieldMessage = ''
      let fieldName = ''
      let fieldLabel = ''
      switch (field.field_name) {
        case 'email':
          fieldName = 'email'
          fieldLabel = 'Email address'
          if (field.messages[0]==='has already been taken') {
            fieldMessage = 'The email address is already registered. Please try logging in with this email (as opposed to signing up as a new user). If you have forgotten your password for this email login, you can use the Password Reset feature on the Login page.'
          } else {
            fieldMessage += `${fieldLabel} ${field.messages[0]}.`
          }
          break
        case 'phone':
          fieldName = 'phone'
          fieldLabel = 'Phone number'
          fieldMessage += `${fieldLabel} ${field.messages[0]}.`
          break
        case 'company_name':
          fieldName = 'companyName'
          fieldLabel = 'Company/Organisation Name'
          if(field.messages[0]==='has already been taken'){
            fieldMessage = 'The company name '+state.companyName+' is already in use. You may wish to contact your organisation to see if you can be added to their account, or maybe an organisational license may be a better fit. Alternatively, you can use a company name of '+state.companyName+'_'+state.firstName+'_'+state.surname+'.'
          }else {
            fieldMessage += `${fieldLabel} ${field.messages[0]}.`
          }
          break
      }
      if (fieldMessage) {
        errorsHash[fieldName] = fieldMessage
      }
    })
    return errorsHash

  }

  validate = (callback) => {
    const errors = {}
    const updateHash = {}
    const { firstName, surname, companyName, phone, email, password } = this.state
    if (!firstName) {
      errors.firstName = 'First name is required'
    }
    if (!surname) {
      errors.surname = 'Surname is required'
    }
    if (!companyName) {
      updateHash['companyName'] = `${firstName} ${surname} `
    }
    if (!email) {
      errors.email = 'Email address is required'
    } else if (!(email || '').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.email = 'Must be a valid email address'
    }
    if (!password) {
      errors.password = 'Password is required'
    } else if ((password || '').length < 8) {
      errors.password = 'Password is too short'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else if (Object.keys(updateHash).length) {
      this.setState(updateHash,() => {
        callback.call(this)
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    this.validate(() => {
      const { firstName, surname, companyName, phone, email, password } = this.state
      this.props.dispatch(signUp({
        first_name: firstName.trim(),
        surname: surname.trim(),
        company_name: companyName.trim(),
        phone: phone?.trim(),
        email: email.trim(),
        password: password.trim(),
        password_confirmation: password.trim(),
      }))
      localStorage.setItem('uid', email.trim())
    })

  }

  renderBody = (props,state) => <LoginContainer>
    <LoginInner>
      <LoginHeading>Sign Up for COtL Mesonet</LoginHeading>
      <Form onSubmit={this.handleSubmit}>
        {this.state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <FieldDiv>
            <TextField
              label="First Name"
              name="firstName"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'firstName',
              }}
              value={this.state.firstName || ''}
              onChange={(e) => this.setState({ firstName: e.target.value })}
              error={state.errors && state.errors.firstName}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.firstName}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Surname"
              name="surname"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'surname',
              }}
              value={this.state.surname || ''}
              onChange={(e) => this.setState({ surname: e.target.value })}
              error={state.errors && state.errors.surname}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.surname}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Company/Organisation Name"
              name="companyName"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'words',
                autoComplete: 'companyName',
              }}
              helperText="If none, enter your full name"
              value={this.state.companyName || ''}
              onChange={(e) => this.setState({ companyName: e.target.value })}
              error={state.errors && state.errors.companyName}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.companyName}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Email Address"
              name="email"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'none',
                autoComplete: 'email',
              }}
              value={this.state.email || ''}
              onChange={(e) => this.setState({ email: e.target.value.trim() })}
              error={state.errors && state.errors.email}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.email}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Phone Number (AU Mobile) *Optional"
              name="phone"
              inputProps={{
                autoCorrect: 'off',
                autoCapitalize: 'none',
                autoComplete: 'phone',
              }}
              value={this.state.phone || ''}
              onChange={(e) => this.setState({ phone: e.target.value.trim() })}
              error={state.errors && state.errors.phone}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.phone}</FormHelperText>
          </FieldDiv>
          <FieldDiv>
            <TextField
              label="Password"
              name="password"
              type="password"
              inputProps={{
                autoComplete: 'new-password',
              }}
              value={this.state.password || ''}
              onChange={(e) => this.setState({ password: e.target.value.trim() })}
              error={state.errors && state.errors.password}
              style={{ width: '100%' }}
            />
            <FormHelperText error>{state.errors && state.errors.password}</FormHelperText>
          </FieldDiv>
        </FieldsWrapper>
        <SubmitWrapper>
          <Button primary rounded>Sign Up</Button>
        </SubmitWrapper>
      </Form>
    </LoginInner>
  </LoginContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
