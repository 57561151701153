import React from 'react'
import { connect } from 'react-redux'
import { signOut } from '../../actions/AuthActions'

class Logout extends React.Component {

  componentDidMount() {
    this.props.dispatch(signOut())
  }

  render = () => null

}

export default connect(
  (store, props) => ({
    router: store.router
  }),
)(Logout)
