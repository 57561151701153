import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validateToken } from '../../../actions/AuthActions'
import { updateBillingProducts } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import PlanAndExtrasForm from '../../../components/my/account/PlanAndExtrasForm'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'
import { RoundedFormButton, RoundedOutlineButton } from '../../../styles/styled-elements'
import { DateTime } from "luxon"
import { compareArrays } from '../../../utils/arrayUtils'

const PlanAndExtrasSummary = ({ billingItemAccessPlan, scheduledBillingItemAccessPlan, setSelectPlanFormVisibility }) => {

  const billingProductName = billingItemAccessPlan.billing_product_name
  const billingProductKey = billingItemAccessPlan.billing_product_key
  const additionalAwss = billingItemAccessPlan.additional_aws_objects
  const additionalAwsSentencePrep = (additionalAwss.length > 1) ? 'AWSs are' : 'AWS is'
  const additionlAwsDetails = billingItemAccessPlan.additionl_aws_details || {}
  const discount = billingItemAccessPlan.discount;
  const discountAmount = billingItemAccessPlan.discount_amount;
  const discountType = billingItemAccessPlan.discount_type;
  const discountDescription = discountType === "percentage" ? `${discountAmount}%` : `$${discountAmount}`
  const chargeAmountExTaxNumber = billingItemAccessPlan.price_yearly_recurring_ex_tax + (additionalAwss.length*(additionlAwsDetails.price_yearly_recurring_ex_tax || 0))
  const chargeAmountExTaxStr = `AUD $${parseFloat(chargeAmountExTaxNumber).toFixed(2)}`
  const chargeAmountIncTaxNumber =  (parseFloat(chargeAmountExTaxNumber) * 1.1).toFixed(2)
  const chargeAmountIncTaxStr = `$${parseFloat(chargeAmountIncTaxNumber).toFixed(2)}`

  const freeUntil = billingItemAccessPlan.free_until ? DateTime.fromISO(billingItemAccessPlan.free_until) : null
  const firstBilledAt = billingItemAccessPlan.first_billed_at ? DateTime.fromISO(billingItemAccessPlan.first_billed_at) : null
  const nextBillableAt = billingItemAccessPlan.next_billable_at ? DateTime.fromISO(billingItemAccessPlan.next_billable_at) : null

  const paidUntil = billingItemAccessPlan.paid_until ? DateTime.fromISO(billingItemAccessPlan.paid_until) : null

  let scheduledBillingProductName
  let scheduledBillingProductKey
  let scheduledAdditionalAwss
  let scheduledChargeAmountExTaxNumber
  let scheduledChargeAmountExTaxStr
  let scheduledChargeAmountIncTaxNumber
  let scheduledChargeAmountIncTaxStr

  if (scheduledBillingItemAccessPlan) {
    scheduledBillingProductName = scheduledBillingItemAccessPlan.billing_product_name
    scheduledBillingProductKey = scheduledBillingItemAccessPlan.billing_product_key
    scheduledAdditionalAwss = scheduledBillingItemAccessPlan.additional_aws_objects
    scheduledChargeAmountExTaxNumber = scheduledBillingItemAccessPlan.price_yearly_recurring_ex_tax + (scheduledAdditionalAwss.length*(additionlAwsDetails.price_yearly_recurring_ex_tax || 0))
    scheduledChargeAmountExTaxStr = `AUD $${scheduledChargeAmountExTaxNumber.toFixed(2)}`
    scheduledChargeAmountIncTaxNumber = (parseFloat(scheduledChargeAmountExTaxNumber) * 1.1).toFixed(2)
    scheduledChargeAmountIncTaxStr = `$${scheduledChargeAmountIncTaxNumber.toFixed(2)}`
  }

  return <div style={{ marginBottom: 60 }}>
    {(scheduledBillingItemAccessPlan) && <p><strong>You have plan change scheduled for {nextBillableAt.toFormat('dd LLL yyyy')}</strong>.</p>}
    <p>You are subscribed to the <strong>{billingProductName}</strong> plan.</p>
    {((billingProductKey==="premium_bundle_1")) && <p>Your nominated AWS is <strong>{billingItemAccessPlan.site_name}</strong>.</p>}
    {((billingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <p>Your additional nominated {additionalAwsSentencePrep}:</p> : null}
    {((billingProductKey==="premium_bundle_1") && (additionalAwss.length)) ? <ul>{additionalAwss.map(({label},index) => {
      return <li key={index.toString()}>{label}</li>
    })}</ul> : null}
    <p>Your current annual charge is <strong>{chargeAmountExTaxStr} ex-GST ({chargeAmountIncTaxStr} inc-GST)</strong>.</p>
    {discount && <p>The above charge includes a plan discount of <strong>{discountDescription}</strong></p>}
    {nextBillableAt && <p>Your next billing date is <strong>{nextBillableAt.toFormat('dd LLL yyyy')}</strong>.</p>}
    {!nextBillableAt && <p>You can change your plan at any time prior to commencing billing.</p>}
    {(scheduledBillingItemAccessPlan) && <p>On that date, you are <strong>scheduled</strong> to change plans to <strong>{scheduledBillingProductName}</strong>.</p>}
    {(scheduledBillingItemAccessPlan) && <p>Your annual charge will be <strong>{scheduledChargeAmountExTaxStr} ex-GST ({scheduledChargeAmountIncTaxStr} inc-GST)</strong></p>}
    {(nextBillableAt && !scheduledBillingItemAccessPlan) && <p>You can change your plan by clicking the button below:</p>}
    {(nextBillableAt && scheduledBillingItemAccessPlan) && <p>You can adjust the plan you'll be changing to by clicking the button below:</p>}
    <RoundedFormButton enabled onClick={setSelectPlanFormVisibility.bind(this,true)}>Change Billing Plan and Extras</RoundedFormButton>
  </div>

}

class PlanAndExtras extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showSelectPlanForm: false,
    }
  }

  componentDidUpdate(prevProps,prevState) {

    const { user } = this.props.apiAuth
    if (!user) return true
    const { company } = user
    if (!company) return true
    const billingItemAccessPlan = company.billing_item_access_plan
    const scheduledBillingItemAccessPlan = company.scheduled_billing_item_access_plan

    const prevUser = prevProps.apiAuth.user
    const prevCompany = prevUser.company
    const prevBillingItemAccessPlan = prevCompany.billing_item_access_plan
    const prevScheduledBillingItemAccessPlan = prevCompany.scheduled_billing_item_access_plan

    let billingProductKey
    let billingProductAwsKeys
    let prevBillingProductKey
    let prevBillingProductAwsKeys

    let scheduledBillingProductKey
    let scheduledBillingProductAwss
    let prevScheduledBillingProductKey
    let prevScheduledBillingProductAwss

    if (billingItemAccessPlan) {
      billingProductKey=billingItemAccessPlan.billing_product_key
      billingProductAwsKeys=billingItemAccessPlan.additional_aws_slugs || []
      prevBillingProductKey=prevBillingItemAccessPlan ? prevBillingItemAccessPlan.billing_product_key : null
      prevBillingProductAwsKeys=prevBillingItemAccessPlan ? (prevBillingItemAccessPlan.additional_aws_slugs || []) : []
    }

    if (scheduledBillingItemAccessPlan) {
      scheduledBillingProductKey = scheduledBillingItemAccessPlan.billing_product_key
      scheduledBillingProductAwss = scheduledBillingItemAccessPlan.additional_aws_slugs
      prevScheduledBillingProductKey = prevScheduledBillingItemAccessPlan ? prevScheduledBillingItemAccessPlan.billing_product_key : null
      prevScheduledBillingProductAwss = prevScheduledBillingItemAccessPlan ? (prevScheduledBillingItemAccessPlan.additional_aws_slugs || []) : []
    }

    if (
      (billingItemAccessPlan && ((billingProductKey!==prevBillingProductKey) || !compareArrays(billingProductAwsKeys,prevBillingProductAwsKeys))) ||
      (scheduledBillingItemAccessPlan && !prevScheduledBillingItemAccessPlan) || (scheduledBillingItemAccessPlan && (
        (scheduledBillingProductKey!==prevScheduledBillingProductKey) ||
        !compareArrays(scheduledBillingProductAwss,prevScheduledBillingProductAwss)
        ))
      ) {
        this.setState({ showSelectPlanForm: false })
    }

  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  handleSubmit = ({ primaryBillingProductKey, primaryBillingProductAwsSlug, additionalAwsSlugs }) => {
    window.scrollTo(0,window.innerHeight)
    this.props.dispatch(updateBillingProducts({
      primary_billing_product_key: primaryBillingProductKey,
      primary_billing_product_aws_slug: primaryBillingProductAwsSlug,
      additional_aws_slugs: additionalAwsSlugs,
    }))
  }

  setSelectPlanFormVisibility = (visibility) => {
    this.setState({ showSelectPlanForm: !!visibility })
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    const billingItemAccessPlan = company ? company.billing_item_access_plan : null
    const scheduledBillingItemAccessPlan = company ? company.scheduled_billing_item_access_plan : null

    const primaryBillingProductAws = (scheduledBillingItemAccessPlan && (scheduledBillingItemAccessPlan.billing_product_key=='premium_bundle_1')) ? scheduledBillingItemAccessPlan.site_object :
      billingItemAccessPlan ? billingItemAccessPlan.site_object : { label: '', value: ''}

    const additionalAwsSlugs =  (scheduledBillingItemAccessPlan && (scheduledBillingItemAccessPlan.billing_product_key=='premium_bundle_1')) ? scheduledBillingItemAccessPlan.additional_aws_slugs :
      (billingItemAccessPlan && (billingItemAccessPlan.billing_product_key=='premium_bundle_1')) ? billingItemAccessPlan.additional_aws_slugs : []

    const additionalAwsObjects = (scheduledBillingItemAccessPlan && (scheduledBillingItemAccessPlan.billing_product_key=='premium_bundle_1')) ? scheduledBillingItemAccessPlan.additional_aws_objects :
      (billingItemAccessPlan && (billingItemAccessPlan.billing_product_key=='premium_bundle_1')) ? billingItemAccessPlan.additional_aws_objects : []

    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`Plan and Extras`} />
        {(billingItemAccessPlan && !state.showSelectPlanForm) && <WhitePanel>
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasBillingPlan'} position={'top'} />
          <PlanAndExtrasSummary
            billingItemAccessPlan={billingItemAccessPlan}
            scheduledBillingItemAccessPlan={scheduledBillingItemAccessPlan}
            setSelectPlanFormVisibility={this.setSelectPlanFormVisibility}
          />
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasBillingPlan'} position={'bottom'} />
        </WhitePanel>}
        {(scheduledBillingItemAccessPlan && state.showSelectPlanForm) && <WhitePanel>
          <PlanAndExtrasForm
            primaryBillingProductKey={scheduledBillingItemAccessPlan.billing_product_key}
            primaryBillingProductAws={primaryBillingProductAws}
            billingItemAccessPlan={billingItemAccessPlan}
            additionalAwsSlugs={additionalAwsSlugs}
            additionalAwsObjects={additionalAwsObjects}
            billingItemAccessPlan={billingItemAccessPlan}
            scheduledBillingItemAccessPlan={null}
            siteOptions={props.siteOptions}
            errors={apiAuth.errors || []}
            setSelectPlanFormVisibility={this.setSelectPlanFormVisibility}
            submitHandler={this.handleSubmit}
          />
        </WhitePanel>}
        {(!billingItemAccessPlan || ((!scheduledBillingItemAccessPlan && billingItemAccessPlan)) && state.showSelectPlanForm) && <WhitePanel>
          {(!billingItemAccessPlan) && <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasBillingPlan'} position={'top'} />}
          <PlanAndExtrasForm
            primaryBillingProductKey={billingItemAccessPlan ? billingItemAccessPlan.billing_product_key : 'premium_bundle_1'}
            primaryBillingProductAws={primaryBillingProductAws}
            billingItemAccessPlan={billingItemAccessPlan}
            additionalAwsSlugs={additionalAwsSlugs}
            additionalAwsObjects={additionalAwsObjects}
            billingItemAccessPlan={billingItemAccessPlan}
            scheduledBillingItemAccessPlan={null}
            siteOptions={props.siteOptions}
            errors={apiAuth.errors || []}
            setSelectPlanFormVisibility={this.setSelectPlanFormVisibility}
            submitHandler={this.handleSubmit}
          />
          {(!billingItemAccessPlan) && <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasBillingPlan'} position={'bottom'} />}
        </WhitePanel>}
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'Plan and Extras'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => {
    const alphabeticalRegexp = /^([0-9]+|BOM)[_\- ](.*)$/
    const siteObjects = store.sites.sites
      .filter(({mesonet}) => mesonet)
      .map(({name,slug}) => {return {label: name, value: slug}})
      .sort((a,b) => {
        const aNameMatch = a.label.match(alphabeticalRegexp)
        const bNameMatch = b.label.match(alphabeticalRegexp)
        if (aNameMatch[2] < bNameMatch[2]) return -1
        if (aNameMatch[2] > bNameMatch[2]) return 1
        return 0
      })
    const { apiAuth } = store
    return {
      apiAuth,
      siteOptions: [{ label: '', value: '' }].concat(siteObjects)
    }
  },
)(PlanAndExtras)
