const DEFAULT_CENTRAL_COORDINATES = {lat: -34.38633, lng: 139.34528}
const DEFAULT_MAP_ZOOM_LEVEL = 8

/**
 * Maintains consistency between the map index component's internal state,
 * and the local storage and user models that also store map configuration.
 *
 * @param updateHash result object to merge with the map index component's state
 * @param user current user JSON object from the server (may be nil)
 */
export function initializeMapCenterAndZoomLevel(updateHash, user) {
    // Saved map zoom and center values are present when a user is signed in
    const savedMapZoom = localStorage.getItem('savedMapZoom') && parseInt(localStorage.getItem('savedMapZoom'))
    const savedMapCenter = localStorage.getItem('savedMapCenter') && JSON.parse(localStorage.getItem('savedMapCenter'))

    // Map zoom and center values are set and preserved through logout/login
    const localStorageMapCenter = localStorage.getItem('mapCenter') && JSON.parse(localStorage.getItem('mapCenter'))
    const localStorageMapZoom = localStorage.getItem('mapZoom') && JSON.parse(localStorage.getItem('mapZoom'))

    if (savedMapZoom && savedMapCenter) {
        updateHash['center'] = savedMapCenter
        updateHash['zoom'] = savedMapZoom
    } else if (user && user.map_default_centre_coords && user.map_default_zoom_level) {
        updateHash['savedMapCenter'] = user.map_default_centre_coords
        updateHash['savedMapZoom'] = user.map_default_zoom_level
        updateHash['center'] = user.map_default_centre_coords
        updateHash['zoom'] = user.map_default_zoom_level
        localStorage.setItem('savedMapZoom', user.map_default_zoom_level)
        localStorage.setItem('savedMapCenter', JSON.stringify(user.map_default_centre_coords))
        localStorage.setItem('mapZoom', user.map_default_zoom_level)
        localStorage.setItem('mapCenter', JSON.stringify(user.map_default_centre_coords))
    } else if (localStorageMapCenter && localStorageMapZoom) {
        updateHash['center'] = localStorageMapCenter
        updateHash['zoom'] = localStorageMapZoom
    } else {
        updateHash['center'] = DEFAULT_CENTRAL_COORDINATES
        updateHash['zoom'] = DEFAULT_MAP_ZOOM_LEVEL
    }
}

/**
 * Determine if data is 'fresh'. If data is not fresh, a "-" is shown on the map screen and site popup.
 *
 * @param awsType Site AWS type (e.g. standard, bom, greenbrain etc)
 * @param lastUpdated Timestamp of latest data (milliseconds past the epoch)
 * @param maxAge Maximum age in milliseconds (optional). If null, the default maximum age is used.
 * @return Boolean True if the data is fresh
 */
export function isDataFresh(awsType, lastUpdated, maxAge = null) {
    if (!awsType || !lastUpdated) {
        return false;
    }

    const dataAge = new Date().getTime() - lastUpdated;

    if (maxAge == null) {
        maxAge = siteDataDefaultMaxAge(awsType);
    }
    return dataAge < maxAge;
}

// default maximum age is 1 hour (1.5 hours for greenbrain sites)
function siteDataDefaultMaxAge(awsType) {
    switch (awsType) {
        case "greenbrain":
            return 5400 * 1000;
        default:
            return 3600 * 1000;
    }
}
