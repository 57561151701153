import React from 'react'
import { connect } from 'react-redux'
import LoginForm from '../../components/auth/LoginForm'
import SiteLayout from '../layout/SiteLayout'
import { signOut } from '../../actions/AuthActions'
import HelmetTitle from '../../components/layout/HelmetTitle'

class Login extends React.Component {

  renderBody = (props,state) => <SiteLayout apiAuth={props.apiAuth}>
    <HelmetTitle title={'Login'} />
    <LoginForm
      apiAuth={props.apiAuth}
      dispatch={props.dispatch}
    />
  </SiteLayout>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
  }),
)(Login)
