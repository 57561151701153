import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { validateToken } from '../../../actions/AuthActions'
import { updateUserSurvey } from '../../../actions/CompanyActions'
import SiteLayout from '../../../containers/layout/SiteLayout'
import HelmetTitle from '../../../components/layout/HelmetTitle'
import LoadingScreen from '../../../components/LoadingScreen'
import ContainerHeading from '../../../components/layout/ContainerHeading'
import ArrowRightBoldCircle from '../../../components/icons/ArrowRightBoldCircle'
import InfoCircle from '../../../components/icons/InfoCircle'
import { H1, PanelContainer, WhitePanel, NavBar, NavFwdBackButton } from '../../../components/my/styled-elements'
import UserSurveyForm from '../../../components/my/account/UserSurveyForm'
import BackToAccountNavLink from '../../../components/my/account/BackToAccountNavLink'
import AccountSetupNextStepLink from '../../../components/my/account/AccountSetupNextStepLink'

const InfoBox = () => {
  return <div style={{ display: 'flex', border: '2px solid #AAA', maxWidth: 400, padding: 8 }}>
    <div style={{ display: 'inline-flex', padding: '16px 16px 0 8px' }}><InfoCircle /></div>
    <div>
      <p>By completing this survey, you will be eligible for the two-week free trial.</p>
      <p>If you don't want to complete the survey, <Link to={"/my/account/start_trial_or_billing"}>skip this step to start billing immediately</Link>.</p>
    </div>
  </div>
}

class UserSurvey extends React.Component {

  componentDidMount(prevProps) {
    this.props.dispatch(validateToken())
  }

  routeTo = (route) => {
    this.props.dispatch(push(route))
  }

  handleSubmit = ({ user1Age, user1Gender, occupationIndustry, mainAwsOfInterest,
                    mainUseOfMesonet, farmSize, mainCropType, mainTwoHerbicidesUsedInSummer,
                    usualSupplierOfSummerHerbicide,
                    whereHeardAboutUs, socialMediaUse,
                  }) => {
    const { dispatch } = this.props
    window.scrollTo(0,window.innerHeight);
    dispatch(updateUserSurvey({
      user1_age: (user1Age || '').trim(),
      user1_gender: (user1Gender || '').trim(),
      occupation_industry: (occupationIndustry || '').trim(),
      main_aws_of_interest: ((mainAwsOfInterest || {})['label'] || '').trim(),
      main_use_of_mesonet: (mainUseOfMesonet || '').trim(),
      farm_size: (farmSize || '').trim(),
      main_crop_type: (mainCropType || '').trim(),
      main_two_herbicides_used_in_summer: (mainTwoHerbicidesUsedInSummer || '').trim(),
      usual_supplier_of_summer_herbicide: (usualSupplierOfSummerHerbicide || '').trim(),
      where_heard_about_us: whereHeardAboutUs,
      social_media_use: Array.from(socialMediaUse.keys()).join(","),
    }))
  }

  listToMap(list) {
    const result = new Map();
    if (list) {
      list.split(",").forEach((value) => {
        result.set(value, true);
      });
    }
    return result;
  }

  renderBody = (props,state) => {
    const { apiAuth } = props
    const { isSignedIn, user } = apiAuth
    if (!isSignedIn || !user) return <div>Not Logged In</div>
    const { company } = user
    if (!company) return null
    const surveyResponse = company.user_survey_response || {}
    return <div>
      <BackToAccountNavLink />
      <H1>Account</H1>
      <PanelContainer>
        <ContainerHeading title={`User Survey (optional)`} />
        <WhitePanel>
          <AccountSetupNextStepLink apiAuth={props.apiAuth} currentStepItemKey={'hasUserSurveyResponse'} position={'top'} />
          <InfoBox />
          <UserSurveyForm
            user1Age={surveyResponse['user1_age']}
            user1Gender={surveyResponse['user1_gender']}
            occupationIndustry={surveyResponse['occupation_industry']}
            mainAwsOfInterest={surveyResponse['main_aws_of_interest'] || { label: '', value: '' }}
            mainUseOfMesonet={surveyResponse['main_use_of_mesonet']}
            farmSize={surveyResponse['farm_size']}
            mainCropType={surveyResponse['main_crop_type']}
            mainTwoHerbicidesUsedInSummer={surveyResponse['main_two_herbicides_used_in_summer']}
            usualSupplierOfSummerHerbicide={surveyResponse['usual_supplier_of_summer_herbicide']}
            whereHeardAboutUs={surveyResponse['where_heard_about_us']}
            socialMediaUse={this.listToMap(surveyResponse['social_media_use'])}
            siteOptions={props.siteOptions}
            errors={apiAuth.errors || []}
            submitHandler={this.handleSubmit}
            updateLoading={this.updateLoading}
          />
          <AccountSetupNextStepLink apiAuth={props.apiAuth}  currentStepItemKey={'hasUserSurveyResponse'} position={'bottom'} />
        </WhitePanel>
      </PanelContainer>
    </div>
  }

  render = () => <SiteLayout apiAuth={this.props.apiAuth}>
    <HelmetTitle title={'User Survey (optional)'} />
    { this.renderBody(this.props,this.state) }
    <LoadingScreen show={this.props.apiAuth.loading} />
  </SiteLayout>

}

export default connect(
  (store, props) => {
    const alphabeticalRegexp = /^([0-9]+|BOM)[_\- ](.*)$/
    const siteObjects = store.sites.sites
      .filter(({mesonet}) => mesonet)
      .map(({name,slug}) => {return {label: name, value: slug}})
      .sort((a,b) => {
        const aNameMatch = a.label.match(alphabeticalRegexp)
        const bNameMatch = b.label.match(alphabeticalRegexp)
        if (aNameMatch[2] < bNameMatch[2]) return -1
        if (aNameMatch[2] > bNameMatch[2]) return 1
        return 0
      })
    return {
      apiAuth: store.apiAuth,
      siteOptions: [{ label: '', value: '' }].concat(siteObjects)
    }
  },
)(UserSurvey)
