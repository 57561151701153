import React from 'react'
import { Link } from 'react-router-dom'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, SubmitWrapper, FormInnerContentDiv, FormTextParagraph } from './styled-elements'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { RoundedFormButton } from '../../styles/styled-elements'
import queryString from 'query-string'

export default class CompanyOwnershipInvitationForm extends React.Component {

  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      acceptance: "accept",
      invitationAccepted: false,
      invitationDeclined: false,
      hasErrors: false,
      formErrors: null,
      errors: null
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.props!==prevProps) {
      const { apiAuth } = this.props
      const prevPropsAuth = prevProps.apiAuth
      const user = apiAuth ? apiAuth.user : null

      if (!user) return true
      const prevPropsUser = prevPropsAuth ? prevPropsAuth.user : null
      const stateHash = {}
      if (user.permissions.owner_invitation_accepted_at && !prevPropsUser.permissions.owner_invitation_accepted_at) {
        stateHash['invitationAccepted'] = true
      } else if (user.permissions.owner_invitation_declined_at && !prevPropsUser.permissions.owner_invitation_declined_at) {
        stateHash['invitationDeclined'] = true
      }
      if (Object.keys(stateHash).length) {
        this.setState(stateHash, () => {
          // console.log("XXX this.state",this.state)
        })
      }
    }
  }

  setAcceptanceValue = (newValue) => {
    this.setState({ acceptance: newValue }, () => {
      // console.log("XXX this.state",this.state)
    })
  }

  validate = (callback) => {
    callback.call(this)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { acceptance } = this.state
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const params = { acceptance }
    this.validate(() => {
      this.props.submitHandler.call(this,params)
    })
  }

  getMainContent = (props,state) => {

    const { apiAuth } = props
    const { user } = apiAuth

    const company = user ? user.company : null

    const ownerInvitationCreatedAt = user ? user.permissions.owner_invitation_created_at : null

    if (!company) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You are not logged in.</FormTextParagraph>
        <FormTextParagraph><Link to={'/login'}>Login Page</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (state.invitationAccepted) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have accepted the invitation and are now your company's account owner.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (state.invitationDeclined) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have declined the invitation.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (user.permissions.owner) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You have already accepted ownership of your company account.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    if (!ownerInvitationCreatedAt) {
      return <FormInnerContentDiv>
        <FormTextParagraph>You do not have an invitation to become the account owner.</FormTextParagraph>
        <FormTextParagraph>Please check that you are logged into the correct account, and check with the account owner that the invitation has been sent.</FormTextParagraph>
        <FormTextParagraph><Link to={'/'}>Return Home</Link></FormTextParagraph>
      </FormInnerContentDiv>
    }

    return <FormInnerContentDiv>

      <FormTextParagraph>You have been invited to become the owner of the COtL Mesonet profile for {company.name}.</FormTextParagraph>
      <FormTextParagraph>Please indicate whether you accept the invitation and click Submit.</FormTextParagraph>
      <Form onSubmit={this.handleSubmit}>
        {this.state.formErrors &&
          <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{this.state.formErrors}</FormHelperText>
        }
        <FieldsWrapper>
          <RadioGroup
            aria-labelledby="access-plan-radio-buttons-heading"
            defaultValue=""
            name="acceptance"
            onChange={(event, newValue) => {
              this.setAcceptanceValue(newValue)
            }}
            value={state.acceptance}
            style={{ padding: 10 }}
          >
            <FieldDiv>
              <FormControlLabel value="accept" control={<Radio />} label="Accept Invitation" />
            </FieldDiv>
            <FieldDiv>
              <FormControlLabel value="decline" control={<Radio />} label="Decline Invitation" />
            </FieldDiv>
          </RadioGroup>
        </FieldsWrapper>
        <SubmitWrapper>
          <RoundedFormButton enabled left>Submit</RoundedFormButton>
        </SubmitWrapper>
      </Form>

    </FormInnerContentDiv>

  }

  renderBody = (props,state) => {
    return <LoginContainer>
      <LoginInner>
        <LoginHeading>Mesonet account invitation accepted</LoginHeading>
        {this.getMainContent(props,state)}
      </LoginInner>
    </LoginContainer>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
