import React from 'react'
import { Link } from 'react-router-dom'
import { resetPassword } from '../../actions/AuthActions'
import { LoginContainer, LoginInner, LoginHeading, Form, FieldsWrapper, FieldDiv, CompletedMessageContainer, LinkContainer, SubmitWrapper } from './styled-elements'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '../../styles/styled-elements'

export default class PasswordResetForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiAuth.errors!==prevProps.apiAuth.errors)
    this.setState({
      formErrors: this.props.apiAuth.errors
    })
  }

  validate = (callback) => {
    const errors = {}
    const { email, password } = this.state
    if (!email.length) {
      errors.email = 'Email address is required'
    } else if (!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.email = 'Must be a valid email address'
    }
    if (Object.keys(errors).length) {
      this.setState({
        hasErrors: true,
        errors: errors
      })
    } else {
      callback.call(this)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      hasErrors: false,
      formErrors: null,
      errors: null,
    })
    const { email } = this.state
    this.validate(() => { this.props.dispatch(resetPassword(email)) })
  }


  renderForm = (props,state) => <Form onSubmit={this.handleSubmit}>
    {state.formErrors &&
      <FormHelperText style={{textAlign: 'center', fontSize: '1.2em' }} error>{state.formErrors}</FormHelperText>
    }
    <FieldsWrapper>
      <FieldDiv>
        <TextField
          label="Email"
          name="email"
          inputProps={{
            autoCorrect: 'off',
            autoCapitalize: 'none',
            autoComplete: 'email',
          }}
          value={state.email || ''}
          onChange={(e) => this.setState({email: e.target.value.trim()})}
          error={state.errors && state.errors.email}
          style={{ width: '100%' }}
        />
        <FormHelperText error>{state.errors && state.errors.email}</FormHelperText>
      </FieldDiv>
    </FieldsWrapper>
    <SubmitWrapper>
      <Button primary rounded>Reset</Button>
    </SubmitWrapper>
  </Form>

  renderFulfilledMessage = () => <div>
    <CompletedMessageContainer>
      <p>Your password reset request has been received.</p>
      <p>Please check your email for a password reset message.</p>
      <p>If you don't find an email, please check your spam folder, or double-check the email addresss you use for this service.</p>
    </CompletedMessageContainer>
    <LinkContainer left>
      <Link to={'/password_reset'}>Reset Password</Link>
    </LinkContainer>
    <LinkContainer left>
      <Link to={'/login'}>Login</Link>
    </LinkContainer>
    <LinkContainer left>
      <Link to={'/'}>Home</Link>
    </LinkContainer>
  </div>

  renderBody = (props,state) => <LoginContainer>
    <LoginInner>
      <LoginHeading>Reset Password</LoginHeading>
      {!props.submitFulfilled && this.renderForm(props,state)}
      {props.submitFulfilled && this.renderFulfilledMessage(props,state)}
    </LoginInner>
  </LoginContainer>

  render() {
    return this.renderBody(this.props,this.state)
  }
}
