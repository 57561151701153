import axios from 'axios'
import { dataToFormData } from '../utils/apiUtils'

export function updateUserMapCenterAndZoomLevel(userId,formValues) {
  const formData = { ...formValues }
  formData['update_type'] = 'map_center_and_zoom_level'
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: axios.put(`/members/user_settings`, formData),
    })
  }
}

export function updateUserDetails(formValues,callback){
  const formData = { ...formValues }
  formData['update_type'] = 'user_detail'
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: axios.put(`/members/user_detail`, formData).then((response) => {
        if(callback) callback(response.data);
      }),
    })
  }
}
