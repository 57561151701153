import React from 'react'
import { connect } from 'react-redux'
import { fetchPublicSites, fetchMemberSites } from '../actions/SiteActions'
import { fetchInfoAlerts } from '../actions/InfoAlertActions'

class ScheduledDataUpdater extends React.Component {

  updateData = () => {
    const { dispatch, isSignedIn } = this.props
    if (isSignedIn) {
      dispatch(fetchMemberSites())
    } else {
      dispatch(fetchPublicSites())
    }
    dispatch(fetchInfoAlerts())
  }

  componentDidMount = () => {

    setTimeout(() => {
      setInterval(() => {
        this.updateData.call(this)
      },60000)
    },60000)

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        this.updateData.call(this)
      }
    })
    this.updateData.call(this)
  }

  render = () => null

}

export default connect(
  (store, props) => {
    const isSignedIn = store.apiAuth.isSignedIn
    return ({
      isSignedIn: isSignedIn,
    })
  },
)(ScheduledDataUpdater)
