import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ContainerDiv = styled.div`
  display: flex;
  font-size: 14px;
  margin: 10px 0 30px 0;
`

export default class BackToLink extends React.Component {

  renderBody = (props,state) => {
    return <ContainerDiv>
      <Link to={props.path}>&#171; Back to {props.label}</Link>
    </ContainerDiv>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }

}
