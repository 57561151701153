import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import styledTheme from '../../styles/theme'
import { ScreenWidthCss } from '../../styles/styled-elements'
import { openMenu } from '../../actions/MenuActions'

import BarsSolid from '../icons/BarsSolid'
import { appFlipperEnabled } from "../../utils/appSettingsUtils";

const Wrapper = styled.header`
  position: relative;
  top: 0;
  height: 50px;
  background-color: #FFF;
  z-index: 1;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px #888;
  max-width: 100%;
  ${ScreenWidthCss}
`
const SiteNameDiv = styled.div`
  a {
    text-decoration: none;
    color: #990000 !important;
    font-weight: bold;
    font-size: 160%;
  }
  @media (min-width: 0px) {
    padding: 6px 10px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: 16px 16px;
  };
`
const MenuDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 100%;
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 0px) {
    padding: 6px;
    padding-right: 10px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: 10px;
    padding-right: 16px;
  };
`
const MenuIconContainer = styled.div`
  display: block;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    color: ${styledTheme.colors.action_link};
  }
`
const ProductBundleNameContainer = styled.span`
  font-size: 0.7em;
  color: #FFF;
  background-color: #999;
  padding: 3px 6px;
  border-radius: 5px;
  text-decoration: none;
`

class SiteHeader extends React.Component {

  openMenuButtonClickHandler = () => {
    this.props.dispatch(openMenu())
    ReactGA.event({
      category: 'Site Action',
      action: 'Menu Action',
      label: 'Open - From Header Button'
    })
  }

  getAccountSignupProgress = () => {
    const { apiAuth } = this.props
    const { user } = apiAuth
    const company = user ? user.company : null

    if (!company) return null

    const accountSignupProgressSteps = [{
      itemKey: 'hasCompanyDetails',
      completed: company.has_company_details,
    },{
      itemKey: 'hasBillingPlan',
      completed: company.has_billing_plan,
    },{
      itemKey: 'hasPaymentMethod',
      completed: company.has_payment_method,
    },{
      itemKey: 'hasUserSurveyResponse',
      completed: company.has_user_survey_response,
    },{
      itemKey: 'hasBillingDiscountCodeUse',
      completed: company.has_billing_discount_code_use,
    }]


    const completedStepCount = accountSignupProgressSteps.filter(({completed}) => completed===true).length
    const totalStepCount = accountSignupProgressSteps.length

    return {
      progressHash: accountSignupProgressSteps,
      totalStepCount,
      completedStepCount,
      complete: (completedStepCount===totalStepCount),
    }

  }

  getBillingPlanSymbol = () => {
    const { apiAuth } = this.props
    const { user } = apiAuth
    const { previewMode } = apiAuth

    const accountSignupProgress = this.getAccountSignupProgress() || {}

    // if (previewMode && !accountSignupProgress['complete']) return null
    if (!accountSignupProgress['complete']) return null

    const company = user ? user.company : null
    const billingItemAccessPlan = company ? company.billing_item_access_plan : null

    if (!billingItemAccessPlan) {
      return null
    } else {
      return <Link to={'/my/account/plan_and_extras'} style={{ textDecoration: 'none' }}><ProductBundleNameContainer>{billingItemAccessPlan.billing_product_name}</ProductBundleNameContainer></Link>
    }
  }

  render = () => <Wrapper>
    <SiteNameDiv>
      <Link to={'/'}>
        COtL Mesonet
      </Link>
      {
        appFlipperEnabled(this.props.appSettings, "foobarbaz") &&
        <span>foobarbaz feature is enabled!</span>
      }
    </SiteNameDiv>
    {this.getBillingPlanSymbol()}

    <MenuDiv>
      <MenuIconContainer onClick={this.openMenuButtonClickHandler.bind(this)}><BarsSolid /></MenuIconContainer>
    </MenuDiv>
  </Wrapper>
}

export default SiteHeader
