import styled from 'styled-components'

import { UserFormH1 } from '../../styles/styled-elements'

export { UserFormH1 }

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 130px;
  bottom: 60px;
`
export const LoginInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #FFF;
  padding: 20px;
  border: 1px solid #979797;
  width: 100%;
  max-width: 400px;
`
export const LoginHeading = styled.h1`
  font-size: 20px;
`
export const Form = styled.form`
  width: 100%;
  font-size: 1.125rem;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
  input {
    font-size: 1.125rem;
  }
`
export const FieldsWrapper = styled.div`
  margin: 20px 0;
`
export const FieldDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
export const SubmitWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
`
export const LinkContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  font-size: 1rem;
  text-align: center;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
  ${({left}) => left ? `text-align: left` : null}
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 130px;
  bottom: 60px;
`
export const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #FFF;
  padding: 20px;
  border: 1px solid #979797;
`
export const FormInnerContentDiv = styled.div`
  width: 100%;
  max-width: 400px;
`
export const FormTextParagraph = styled.p`
  width: 100%;
  font-size: 1em;
  color: #333;
`
export const CompletedMessageContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: left;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
`
export const RejectedMessageContainer = styled(CompletedMessageContainer)`
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.2);
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 10px;
    margin: 0 30px;
  }
`

